import { useBreakpointIndex } from '@theme-ui/match-media';
import { useEffect, useState } from 'react';

export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => setHasMounted(true), []);
  return hasMounted;
};

export function useBpIndex() {
  const index = useBreakpointIndex();
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return null;
  }

  return index;
}

export function useScreenType() {
  const bpIndex = useBpIndex();

  const isThinMobile = () => {
    return (bpIndex as number) <= 0;
  };

  const isMobile = () => {
    return (bpIndex as number) <= 1;
  };

  const isTablet = () => {
    return (bpIndex as number) == 2;
  };

  const isDesktop = () => {
    return (bpIndex as number) > 2;
  };

  const isTabletOrMobile = () => {
    return (bpIndex as number) <= 2;
  };

  const isTabletOrDesktop = () => {
    return isTablet() || isDesktop();
  };

  return { isThinMobile, isMobile, isTablet, isDesktop, isTabletOrMobile, isTabletOrDesktop };
}
