import { BoxProps } from '@theme-ui/components';
import { TippyProps } from '@tippyjs/react';
import { useState } from 'react';
import { ThemeUICSSObject } from 'theme-ui';
import Dropdown from '../ui/components/Dropdown';
import { IconType } from '../ui/theme';
import React from 'react';
import { Box } from '../ui/components';

export interface DropdownProps extends BoxProps {
  dropdownOptions: DropdownOption[];
  dropdownOffset?: [number, number];
}

export interface DropdownOption {
  icon?: IconType;
  label: string;
  subTitle?: string;
  onClick?: () => void;
  isSelected?: boolean;
  inverseColors?: boolean;
  sx?: ThemeUICSSObject;
}

const withDropdown = <T extends any>(WrappedComponent: React.FC<T>) => {
  const WithDropdown = (props: T & DropdownProps & TippyProps) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    return (
      <Dropdown {...props} visible={showDropdown} onClickOutside={() => setShowDropdown(false)}>
        <Box onClick={() => setShowDropdown(!showDropdown)}>
          <WrappedComponent clicked={showDropdown} {...props} />
        </Box>
      </Dropdown>
    );
  };

  return WithDropdown;
};

export default withDropdown;
