import React from 'react';
import { ThemeUICSSObject } from 'theme-ui';

import { Box, Text, colors, radii } from '../ui';
import { fadeIn } from '../utils';

type TooltipPosition = 'top' | 'bottom' | 'right' | 'left';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tooltipPositionStyles: Record<TooltipPosition, any> = {
  bottom: {
    bottom: '-20px',
    left: '50%',
    transform: 'translate(-50%, 100%)',
  },
  left: {
    left: 'auto',
    right: 'calc(100% + 20px)',
    top: '50%',
    transform: 'translateX(0) translateY(-50%)',
  },
  right: {
    left: 'calc(100% + 20px)',
    top: '50%',
    transform: 'translateX(0) translateY(-50%)',
  },
  top: {
    left: '50%',
    top: '-20px',
    transform: 'translate(-50%, -100%)',
  },
};

const beforePositionStyles: Record<TooltipPosition, object> = {
  bottom: {
    borderBottomColor: colors.black,
    bottom: '100%',
  },
  left: {
    borderLeftColor: colors.black,
    left: 'auto',
    right: '-12px',
    top: '50%',
    transform: 'translateX(0) translateY(-50%)',
  },
  right: {
    borderRightColor: colors.black,
    left: '-6px',
    top: '50%',
    transform: 'translateX(0) translateY(-50%)',
  },
  top: {
    borderTopColor: colors.black,
    top: '100%',
  },
};

export interface TooltipProps {
  position?: TooltipPosition;
  show: boolean;
  sx?: ThemeUICSSObject;
  text: string;
}

const Tooltip = (props: TooltipProps) => {
  const { position = 'top', sx, text, show } = props;

  if (show) {
    return (
      <Box
        sx={{
          '&::before': {
            border: 'solid transparent',
            borderWidth: '6px',
            content: '" "',
            height: 0,
            left: '50%',
            marginLeft: '-6px',
            pointerEvents: 'none',
            position: 'absolute',
            width: 0,
            ...beforePositionStyles[position],
          },
          animation: `${fadeIn} .5s backwards`,
          background: colors.black,
          borderRadius: radii.m,
          color: colors.white,
          left: '50%',
          padding: '12px',
          position: 'absolute',
          top: -30,
          transform: 'translateX(-50%)',
          width: 'max-content',
          zIndex: 10,
          ...tooltipPositionStyles[position],
          ...sx,
        }}
      >
        <Text variant='text-m-regular'>{text}</Text>
      </Box>
    );
  }

  return null;
};

export default Tooltip;
