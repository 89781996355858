import { ThemeUICSSObject } from 'theme-ui';
import { radii, colors } from '../ui/theme';

export const overlayStyles: ThemeUICSSObject = {
  position: 'relative',
  borderRadius: radii.xxxl,
  overflow: 'hidden',
  ':after': {
    content: "''",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '80%',
    height: '80%',
    borderRadius: radii.circle,
    border: `300px solid ${colors['black075']}`,
  },
};
