import React, {
  ChangeEvent,
  createContext,
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { RootState } from '../store';
import {
  photoErrors,
  PhotoErrorType,
  PhotoSource,
  PhotoValidationResult,
  Procedures,
} from '../common';
import { AnalyticsEventNames, resizeImage, trackAnalyticsEvent } from '../utils';
import { useIsBlissWidget } from '../hooks';

declare global {
  interface Window {
    initTeethEngine: () => Promise<void>;
    destroyProcedure: (procedure: string) => Promise<void>;
    runWhitening: (image: string, whiteningAmount?: number) => Promise<string>;
    runVeneers: (image: string) => Promise<string>;
    runBraces: (image: string) => Promise<string>;
    validateImage: (image: string, procedure: string) => Promise<PhotoValidationResult>;
    changeProcedure: (procedure: string) => Promise<void>;
  }
}

interface PhotoEditorContextValue {
  procedure: Procedures;
  selectedImage?: File | string;
  setSelectedImage: Dispatch<SetStateAction<File | string | undefined>>;
  croppedImage?: string;
  setCroppedImage: Dispatch<SetStateAction<string | undefined>>;
  resultImage?: string;
  setResultImage: Dispatch<SetStateAction<string | undefined>>;
  photoSource?: PhotoSource;
  setPhotoSource: Dispatch<SetStateAction<PhotoSource | undefined>>;
  photoError?: PhotoErrorType;
  setPhotoError: Dispatch<SetStateAction<PhotoErrorType | undefined>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  imageMimeType: string | undefined;
  reset: () => void;
  onChangeProcedure: (procedure: Procedures) => void;
  onCropImage: () => void;
  onPhotoUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  editorRef: MutableRefObject<AvatarEditor | null>;
  progressBarValue: number;
  setProgressBarValue: Dispatch<SetStateAction<number>>;
}

const PhotoEditorContext = createContext<PhotoEditorContextValue | null>(null);

export const PhotoEditorProvider = ({ children }: PropsWithChildren) => {
  const cookies = new Cookies();
  const { slug, widgetId } = useParams();

  const [procedure, setProcedure] = useState<Procedures>(cookies.get('procedure'));

  const editorRef = useRef<AvatarEditor | null>(null);

  const [selectedImage, setSelectedImage] = useState<File | string>();
  const [croppedImage, setCroppedImage] = useState<string>();
  const [resultImage, setResultImage] = useState<string>();
  const [mimeType, setMimeType] = useState<string>();

  const [photoSource, setPhotoSource] = useState<PhotoSource>();

  const [photoError, setPhotoError] = useState<PhotoErrorType>();
  const [isLoading, setIsLoading] = useState(false);

  const widget = useSelector((state: RootState) => state.widget.widget);

  const isBlissWidget = useIsBlissWidget();

  const [progressBarValue, setProgressBarValue] = useState(0);

  const reset = () => {
    setSelectedImage(undefined);
    setCroppedImage(undefined);
    setResultImage(undefined);
    setMimeType(undefined);
    setPhotoSource(undefined);
    setPhotoError(undefined);
    setIsLoading(false);
  };

  const onChangeProcedure = (proc: Procedures) => {
    cookies.set('procedure', proc, { path: `/${slug}/${widgetId}` });
    setProcedure(proc);
    setIsLoading(true);
    setProgressBarValue(0);
  };

  const handleValidatorError = (error: PhotoErrorType) => {
    setPhotoError(error);
    setIsLoading(false);

    if (widget) {
      trackAnalyticsEvent(AnalyticsEventNames.PhotoValidationError, {
        customerID: widget.user.uuid,
        customerName: widget.user?.clinicName,
        customerType: widget.user.type,
        errorName: error.code,
      });
    }

    return;
  };

  const runProcedure = (image: string) => {
    if (procedure === Procedures.Whitening) {
      return window.runWhitening(image, isBlissWidget ? 1.0 : 1.1);
    } else if (procedure === Procedures.Veneers) {
      return window.runVeneers(image);
    }

    return window.runBraces(image);
  };

  const initProcedure = (image: string) => {
    setIsLoading(true);

    let procedureName = 'whitening';

    if (procedure === Procedures.Veneers) procedureName = 'veneersD';
    if (procedure === Procedures.Braces) procedureName = 'bracesCeramicOffWhite';

    window
      .validateImage(image, procedureName)
      .then((smile: PhotoValidationResult) => {
        if (!smile.isUserPresent) {
          handleValidatorError(photoErrors.USER_NOT_PRESENT);
        } else if (!smile.isSmilingWithMouthOpen) {
          handleValidatorError(photoErrors.NOT_SHOWING_TEETH);
        } else if (smile.areTeethCoveredByLips) {
          handleValidatorError(photoErrors.TEETH_COVERED);
        } else if (!smile.isFrontalPose) {
          handleValidatorError(photoErrors.NOT_FRONTAL_POSE);
        }
      })
      .then(() => runProcedure(image))
      .then((res: string) => {
        const imgDataSrc = res;
        setResultImage(imgDataSrc);
        setIsLoading(false);
        setProgressBarValue(0);

        trackAnalyticsEvent(AnalyticsEventNames.SimulationPerformed, {
          customerID: widget.user.uuid,
          customerName: widget.user?.clinicName,
          customerType: widget.user.type,
          simulationName: procedure,
        });
      });
  };

  const onCropImage = () => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      setCroppedImage(croppedImg);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (progressBarValue >= 100) {
      // Delaying init so circular progress is not blocked
      timeout = setTimeout(() => {
        initProcedure(croppedImage!);
      }, 400);
    }

    return () => clearTimeout(timeout);
  }, [progressBarValue]);

  const onPhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      resizeImage({
        maxWidth: 900,
        maxHeight: 1100,
        file: e.target.files[0],
      }).then((val) => {
        setSelectedImage(new File([val], `user_image_for_${procedure}`));
        setPhotoSource(PhotoSource.UploadPhoto);
        setMimeType(e.target.files![0].type);

        return null;
      });
    }
  };

  return (
    <PhotoEditorContext.Provider
      value={{
        croppedImage,
        editorRef,
        imageMimeType: mimeType,
        isLoading,
        onChangeProcedure,
        onCropImage,
        onPhotoUpload,
        photoError,
        photoSource,
        procedure,
        progressBarValue,
        setProgressBarValue,
        reset,
        resultImage,
        selectedImage,
        setCroppedImage,
        setIsLoading,
        setPhotoError,
        setPhotoSource,
        setResultImage,
        setSelectedImage,
      }}
    >
      {children}
    </PhotoEditorContext.Provider>
  );
};

export const usePhotoEditor = () => {
  const context = useContext(PhotoEditorContext);

  if (!context) {
    throw new Error('usePhotoEditor must be used within a PhotoEditorProvider');
  }

  return context;
};
