import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { RootState } from '../store';
import { Button, FlexLayout, radii, Text, useScreenType } from '../ui';
import { AnalyticsEventNames, trackAnalyticsEvent } from '../utils';

interface CardProps {
  procedure: string;
  description: string;
  backgroundImage: string;
  isDisabled?: boolean;
}
const Card = (props: CardProps) => {
  const { procedure, description, backgroundImage, isDisabled } = props;
  const navigate = useNavigate();
  const { slug, widgetId } = useParams();
  const { isMobile } = useScreenType();
  const cookies = new Cookies();
  const widget = useSelector((state: RootState) => state.widget.widget);

  const { t } = useTranslation();

  const onTryClick = () => {
    if (isDisabled) {
      return;
    }

    if (Object.keys(widget).length > 0) {
      trackAnalyticsEvent(AnalyticsEventNames.SimulationSelected, {
        customerID: widget.user.uuid,
        customerName: widget.user?.clinicName,
        customerType: widget.user.type,
        simulationName: procedure.toUpperCase(),
      });
    }

    cookies.set('procedure', procedure.toUpperCase(), { path: `/${slug}/${widgetId}` });
    navigate(`/${slug}/${widgetId}/editor`);
  };

  return (
    <FlexLayout
      alignItems='flex-start'
      flexDirection='column'
      justifyContent='flex-end'
      p={4}
      space={5}
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        borderRadius: radii.xxxl,
        height: 'card-height',
        opacity: isDisabled && 0.3,
        width: 'card-width',
      }}
    >
      <FlexLayout flexDirection='column' justifyContent='flex-start' space={1}>
        <Text color='white' variant='text-l-bold'>
          {t(`homePage.${procedure.toLowerCase()}CardTitle`)}
        </Text>
        <Text color='white' variant={isMobile() ? 'text-s-regular' : 'text-s-medium'}>
          {description}
        </Text>
      </FlexLayout>
      <Button
        iconRight='right'
        onClick={onTryClick}
        text={t(`homePage.${procedure.toLowerCase()}CardBtnText`)}
        variant='white'
      />
    </FlexLayout>
  );
};

export default Card;
