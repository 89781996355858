import React, { useContext } from 'react';
import { AxiosResponse } from 'axios';
import { UseMutateFunction } from 'react-query';

import { Button, colors, radii, FlexLayout, Icon, Text } from '../ui';
import { AuthContext } from '../context';
import { Plan, SubscriptionType } from '../apis';
import { formatToUppercaseFirstLetter } from '../helpers';

export interface SubscriptionPlanProps {
  mutateDelete: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    {
      userId: string;
    },
    unknown
  >;
  plan: Plan;
  setSelectedPlan: React.Dispatch<React.SetStateAction<Plan | undefined>>;
  setIsCardElementOpened: React.Dispatch<React.SetStateAction<boolean>>;
  userSubscription: SubscriptionType;
}

const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({
  mutateDelete,
  plan,
  setSelectedPlan,
  setIsCardElementOpened,
  userSubscription,
}) => {
  const { user } = useContext(AuthContext);

  return (
    <FlexLayout
      alignItems={'center'}
      backgroundColor={'white'}
      justifyContent={'center'}
      py={4}
      px={6}
      space={2}
      sx={{
        minWidth: '320px',
        minHeight: '480px',
        border: `1px solid ${colors['black008']}`,
        borderRadius: radii.m,
      }}
    >
      <FlexLayout
        alignItems={'flex-start'}
        flexDirection={'column'}
        justifyContent={'center'}
        space={6}
        sx={{ width: '100%' }}
      >
        <FlexLayout
          alignItems={'center'}
          justifyContent={'flex-start'}
          space={2}
          sx={{ width: '100%' }}
        >
          <Text variant={'text-xl-medium'} color={colors['secondaryArsenic']}>
            {formatToUppercaseFirstLetter(plan.type)}
          </Text>
          <Icon icon={'done'} size='l' color={colors['irisBlue']} />
        </FlexLayout>

        <FlexLayout
          flexDirection={'row'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          space={1}
          sx={{ width: '100%' }}
        >
          <Text variant={'text-l-medium'} color={colors['secondaryArsenic']} upperCase>
            $ {plan.price}
          </Text>
          <Text variant={'text-s-regular'} color={colors['secondaryArsenic']}>
            /month
          </Text>
        </FlexLayout>
        <FlexLayout
          alignItems={'flex-start'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          space={4}
          sx={{ width: '100%' }}
        >
          {[
            'Unlimited usage',
            'Unlimited appointments',
            'Up to 5 widgets',
            'Automatic renewals, cancel anytime',
          ].map((t, index) => (
            <FlexLayout alignItems={'flex-start'} key={index} space={1}>
              <Icon icon={'check'} size='s' color={colors['irisBlue']} />
              <Text variant={'text-m-regular'} color={colors['secondaryArsenic']}>
                {t}
              </Text>
            </FlexLayout>
          ))}
        </FlexLayout>
        <Button
          text={userSubscription?.isActive ? 'Cancel' : 'Subscribe'}
          variant={userSubscription?.isActive ? 'white' : 'irisBlue'}
          size={'standard'}
          width={'100%'}
          onClick={() =>
            userSubscription && userSubscription.isActive
              ? mutateDelete({ userId: user?.uuid as string })
              : (setSelectedPlan(plan), setIsCardElementOpened(true))
          }
        />
      </FlexLayout>
    </FlexLayout>
  );
};

export default SubscriptionPlan;
