import { keyframes } from '@emotion/react';
import debounce from 'lodash/debounce';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Procedures } from '../common';
import { usePhotoEditor } from '../context';
import { useIsBlissWidget } from '../hooks';
import { Box, colors, FlexLayout, Icon, Text } from '../ui';
import WhiteningSlider from './WhiteningSlider';

const blissArrowAnimation = keyframes`
  from {
    transform: rotate(-180deg) translateX(10px);
  } to {
    transform: rotate(-180deg) translateX(0px);
  }
`;

const WhiteningProcedure = () => {
  const { procedure, setResultImage, croppedImage } = usePhotoEditor();

  const { t } = useTranslation();

  const isBlissWidget = useIsBlissWidget();

  const changeWhiteningHandler = (image: string, amount: number) => {
    window.runWhitening(image, amount).then((res: string) => {
      const imgDataSrc = res;
      setResultImage(imgDataSrc);
    });
  };

  const debounceWhiteningLevel = useMemo(() => debounce(changeWhiteningHandler, 500), []);

  if (procedure === Procedures.Whitening) {
    return (
      <FlexLayout alignItems='center' flexDirection='column' pb={4} sx={{ position: 'relative' }}>
        <WhiteningSlider onChange={(value) => debounceWhiteningLevel(croppedImage!, value)} />
        {isBlissWidget ? (
          <FlexLayout flexDirection='column' px={2} space={2} sx={{ width: '100%' }}>
            <Text sx={{ fontSize: '12px' }} variant='bliss-text'>
              Adjust the slider to see results
            </Text>
            <Box
              sx={{
                animation: `${blissArrowAnimation} 1s infinite`,
                mr: 'auto',
              }}
            >
              <Icon icon='arrowLeft' />
            </Box>
          </FlexLayout>
        ) : (
          <FlexLayout
            alignItems='center'
            flexDirection='column'
            sx={{
              bottom: 0,
              left: '50%',
              position: 'absolute',
              transform: 'translateX(-50%)',
            }}
          >
            <>
              <Box
                sx={{
                  background: colors.black,
                  height: '8px',
                  width: '1px',
                }}
              />

              <Text
                color={colors.black07}
                sx={{ textAlign: 'center', width: '90px' }}
                variant='text-xs-regular'
              >
                {t('common.recommended')}
              </Text>
            </>
          </FlexLayout>
        )}
      </FlexLayout>
    );
  }

  return null;
};

export default WhiteningProcedure;
