import toast, { DefaultToastOptions, Toaster, ToastPosition } from 'react-hot-toast';
import { FlexLayout, Icon, Text } from '..';
import { ToastType } from '../../../enums';
import React from 'react';
import { ToastProps } from '../../props';

const Toast: React.FC<ToastProps> = ({
  toastType = ToastType.INFO,
  icon,
  iconColor,
  title,
  helperText,
  onClose,
}) => {
  return (
    <FlexLayout
      alignItems='flex-start'
      flexDirection='row'
      flexGrow={2}
      px={4}
      py={4}
      space={2}
      sx={{
        width: 'fit-content',
        color: 'white',
        borderRadius: 'm',
        border: 'toast',
        boxShadow: '2px 4px 12px black-004',
        backgroundColor: toastType,
      }}
    >
      {icon && <Icon color={'white' || iconColor} icon={icon} mt='2px' size='m' />}
      <FlexLayout flexDirection='column' flexGrow={1} justifyContent='flex-start'>
        <Text color={'white'} variant={'text-m-regular'}>
          {title}
        </Text>
        {helperText && (
          <Text color={'white'} variant={'text-xs-regular'}>
            {helperText}
          </Text>
        )}
      </FlexLayout>
      <Icon color={'white'} icon='close' mt='2px' size='m' onClick={onClose} />
    </FlexLayout>
  );
};

export interface ToasterProps {
  position?: ToastPosition;
  toastOptions?: DefaultToastOptions;
  reverseOrder?: boolean;
  gutter?: number;
  containerStyle?: React.CSSProperties;
  containerClassName?: string;
  children?: (toast: any) => JSX.Element;
}

export const ToastMessageContainer: React.FC<ToasterProps> = ({ ...rest }) => (
  <Toaster
    containerStyle={{
      top: 24,
      left: 24,
      bottom: 24,
      right: 24,
    }}
    gutter={8}
    position='bottom-right'
    toastOptions={{
      duration: 4000,
      style: {
        background: 'none',
        maxWidth: 'fit-content',
        boxShadow: 'none',
        padding: 0,
      },
    }}
    {...rest}
  />
);

export const showToast = (props: ToastProps) => {
  const { toastType, helperText, icon, iconColor, title, onClose } = props;

  toast(
    (t) => (
      <Toast
        helperText={helperText}
        icon={icon}
        iconColor={iconColor}
        title={title}
        toastType={toastType}
        onClose={() => {
          onClose?.();
          toast.dismiss(t.id);
        }}
      />
    ),
    {
      className: 'toast-override',
    },
  );
};
