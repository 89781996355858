export const fonts = {
  bold: 'Euclid Bold',
  normal: 'Euclid Regular',
  semibold: 'Euclid Semi Bold',
};

export const blissFonts = {
  castledownDiscoveryBold: 'Castledown Discovery Bold',
  quicksandBold: 'Quicksand Bold',
  quicksandRegular: 'Quicksand Regular',
};
