import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Image } from 'theme-ui';
import { Link } from 'theme-ui';

import { RootState } from '../store';
import { Procedures } from '../common';
import { useIsBlissWidget } from '../hooks';
import { Box, Button, colors, FlexLayout, radii, Text, useScreenType } from '../ui';
import {
  AnalyticsEventNames,
  getHandbookData,
  getHandbookTitle,
  trackAnalyticsEvent,
} from '../utils';

interface Props {
  procedure: Procedures;
}

const Handbook = (props: Props) => {
  const { procedure } = props;

  const { t } = useTranslation();

  const widget = useSelector((state: RootState) => state.widget.widget);

  const { isMobile } = useScreenType();

  const isBlissWidget = useIsBlissWidget();

  return (
    <FlexLayout flexDirection='column' mt={8} space={5}>
      {isBlissWidget ? (
        <Text sx={{ textAlign: 'center' }} variant='bliss-heading'>
          Get Your Smile Ready With Our Revolutionary 3 Step Whitening System
        </Text>
      ) : (
        <Text color={colors.black} variant={isMobile() ? 'text-l-medium' : 'text-xl-medium'}>
          {t(getHandbookTitle(procedure))}
        </Text>
      )}
      <FlexLayout flexWrap='wrap' justifyContent='flex-start' space={6}>
        {isBlissWidget ? (
          <>
            <FlexLayout
              flexDirection='column'
              justifyContent='space-between'
              space={4}
              sx={{ minHeight: '590px', minWidth: '300px', width: 'min-content' }}
            >
              <Box sx={{ width: '100%' }}>
                <video autoPlay loop muted playsInline width='100%'>
                  <source src='/videos/BlissVideoStep1.mp4' />
                </video>
              </Box>
              <Text variant='bliss-subheading'>Prime.</Text>
              <Text variant='bliss-text'>
                It&apos;s a crime not to prime!
                <br />
                <br />
                Prep your teeth right to enhance the effect of our Whitening Strips with our
                first-of-its-kind Whitening Primer Toothpaste.
              </Text>
              <Link
                href='https://blissoralcare.com/collections/teeth-whitening-toothpastes'
                onClick={() => {
                  if (widget) {
                    trackAnalyticsEvent(AnalyticsEventNames.BlissToothpaste, {
                      customerID: widget.user.uuid,
                      customerName: widget.user?.clinicName,
                      customerType: widget.user.type,
                    });
                  }
                }}
                rel='noreferrer'
                sx={{ textDecoration: 'none' }}
                target='_blank'
              >
                <Button
                  size='s'
                  text='Shop Toothpaste'
                  textVariant='bliss-button'
                  variant='blissPrimary'
                />
              </Link>
            </FlexLayout>
            <FlexLayout
              flexDirection='column'
              justifyContent='space-between'
              space={4}
              sx={{ minHeight: '590px', minWidth: '300px', width: 'min-content' }}
            >
              <Box sx={{ width: '100%' }}>
                <video autoPlay loop muted playsInline width='100%'>
                  <source src='/videos/BlissVideoStep2.mp4' />
                </video>
              </Box>
              <Text variant='bliss-subheading'>Brush.</Text>
              <Text variant='bliss-text'>
                Ditch your manual toothbrush, its time to clean with sonic power!
                <br />
                <br />
                Guaranteed to remove more plaque than your current toothbrush, our sleek and stylish
                Sonic Whitening Toothbrush cleans like no other.
              </Text>
              <Link
                href='https://blissoralcare.com/collections/teeth-whitening-toothbrushes'
                onClick={() => {
                  if (widget) {
                    trackAnalyticsEvent(AnalyticsEventNames.BlissToothbrush, {
                      customerID: widget.user.uuid,
                      customerName: widget.user?.clinicName,
                      customerType: widget.user.type,
                    });
                  }
                }}
                rel='noreferrer'
                sx={{ textDecoration: 'none' }}
                target='_blank'
              >
                <Button
                  size='s'
                  text='Shop Toothbrush'
                  textVariant='bliss-button'
                  variant='blissPrimary'
                />
              </Link>
            </FlexLayout>
            <FlexLayout
              flexDirection='column'
              justifyContent='space-between'
              space={4}
              sx={{ minHeight: '590px', minWidth: '300px', width: 'min-content' }}
            >
              <Box sx={{ width: '100%' }}>
                <video autoPlay loop muted playsInline width='100%'>
                  <source src='/videos/BlissVideoStep3.mp4' />
                </video>
              </Box>
              <Text variant='bliss-subheading'>Whiten.</Text>
              <Text variant='bliss-text'>
                Whether it&apos;s our Dissolving Strips for on-the-go top-ups or our Leave-In Strips
                when relaxing at home, our range of professional whitening strips will remove stains
                and get your teeth back to their natural whiteness.
              </Text>
              <Link
                href='https://blissoralcare.com/collections/teeth-whitening-strips'
                onClick={() => {
                  if (widget) {
                    trackAnalyticsEvent(AnalyticsEventNames.BlissStrips, {
                      customerID: widget.user.uuid,
                      customerName: widget.user?.clinicName,
                      customerType: widget.user.type,
                    });
                  }
                }}
                rel='noreferrer'
                sx={{ textDecoration: 'none' }}
                target='_blank'
              >
                <Button
                  size='s'
                  text='Shop Whitening Strips'
                  textVariant='bliss-button'
                  variant='blissPrimary'
                />
              </Link>
            </FlexLayout>
          </>
        ) : (
          getHandbookData(procedure).map((card) => (
            <FlexLayout
              flexDirection='column'
              justifyContent='flex-start'
              key={card.mediumTitle}
              space={3}
              sx={{ width: '300px' }}
            >
              <Image
                src={card.icon}
                sx={{ borderRadius: radii.xl, height: '220px', width: '100%' }}
              />
              <FlexLayout alignItems='center' justifyContent='flex-start'>
                <Text variant='text-m-medium'>{t(card.mediumTitle)}</Text>
                <Text color={colors.black06} variant='text-m-regular'>
                  {t(card.regularTitle)}
                </Text>
              </FlexLayout>
              <Text color={colors.black06} variant='text-s-regular'>
                {t(card.partOne)}
              </Text>
              <Text color={colors.black06} variant='text-s-regular'>
                {t(card.partTwo)}
              </Text>
            </FlexLayout>
          ))
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

export default Handbook;
