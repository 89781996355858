/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from 'react';

//@ts-ignore
import data from '@banuba/webar/BanubaSDK.data';
//@ts-ignore
import wasm from '@banuba/webar/BanubaSDK.wasm';
//@ts-ignore
import simd from '@banuba/webar/BanubaSDK.simd.wasm';
//@ts-ignore
import FaceTracker from '@banuba/webar/face_tracker.zip';
//@ts-ignore
import Lips from '@banuba/webar/lips.zip';
//@ts-ignore
import TeethWhitening from './assets/effects/TeethTone_VitaShades.zip';

import { Webcam, Player, Module, Effect, Dom, ImageCapture } from '@banuba/webar';
import { Box, Button, FlexLayout, Icon, Image, Modal, Slider, colors, useScreenType } from '../ui';
import Logo from '../ui/assets/images/Logo.png';
import LogoSvg from '../ui/assets/images/yss.svg';
import PhilipsLogo from '../ui/assets/images/Philips-Zoom-Teeth-Whitening.png';
import FlaeshLogo from '../ui/assets/images/flaesh-logo.webp';
import CrestLogo from '../ui/assets/images/crest-oralb.png';
import { useLocation } from 'react-router-dom';
import { AnalyticsEventNames, trackAnalyticsEvent } from '../utils';
import { TrackingBanner } from '../pages-components/TrackingBanner';

const effect = new Effect(TeethWhitening);

const vitaShadesOrdered = [
  { key: 'natural', label: 'Your \n Shade' },
  { key: 'C1', label: 'C1' },
  { key: 'A1', label: 'A1' },
  { key: 'B1', label: 'B1' },
  { key: 'BL3', label: 'BL3' },
  { key: 'BL2', label: 'BL2' },
  { key: 'BL1', label: 'BL1' },
];
function getLogo(pathname: string) {
  if (pathname.includes('crest')) return CrestLogo;
  if (pathname.includes('flaesh')) return FlaeshLogo;
  if (pathname.includes('philips')) return PhilipsLogo;
  return false;
}

const SdkComponentTeethWhitening = () => {
  const { pathname } = useLocation();
  const { isMobile } = useScreenType();
  const isPhilips = pathname.includes('philips');

  function getLogoStyles(pathname: string) {
    if (pathname.includes('crest')) return { height: '90px' };
    if (pathname.includes('flaesh')) return { height: isMobile() ? '50px' : '90px' };
    if (pathname.includes('philips')) return { height: isMobile() ? '70px' : '90px' };
    return {};
  }

  const playerRef = useRef<Player>();
  const [imageCapture, setImageCapture] = useState<ImageCapture | null>(null);
  const [snapshot, setSnapshot] = useState<string | null>(null);
  const [showSnapshotModal, setShowSnapshotModal] = useState<boolean>(false);
  const [isPlayerLoading, setIsPlayerLoading] = useState<boolean>(true);
  const [newPlayer, setNewPlayer] = useState<Player | null>(null);

  const [noEffect, setNoEffect] = useState(true);
  const [scale, setScale] = useState(0);

  const firstFrameReceivedListener = () => {
    setIsPlayerLoading(false);
    playerRef.current?.removeEventListener('framereceived', firstFrameReceivedListener);
  };

  useEffect(() => {
    const pathnameElements = pathname.split('-');
    trackAnalyticsEvent(AnalyticsEventNames.SDK_Loaded, {
      client: pathnameElements[0].replace('/', ''),
      clinicName: pathnameElements[pathnameElements.length - 1],
    });
  }, []);

  useEffect(() => {
    async function initSdk() {
      const newPlayer = await Player.create({
        clientToken: process.env.REACT_APP_BANUBA_TOKEN as string,
        locateFile: {
          'BanubaSDK.data': data,
          'BanubaSDK.wasm': wasm,
          'BanubaSDK.simd.wasm': simd,
        },
      });
      playerRef.current = newPlayer;

      await newPlayer.addModule(new Module(Lips), new Module(FaceTracker));

      newPlayer.use(new Webcam());
      const newImageCapture = new ImageCapture(newPlayer);
      setImageCapture(newImageCapture);

      Dom.render(newPlayer, '#webar-app');
      newPlayer.addEventListener('framereceived', firstFrameReceivedListener);

      setNewPlayer(newPlayer);
    }

    initSdk();

    return (): void => {
      Dom.unmount('#webar-app');
    };
  }, []);

  useEffect(() => {
    if (!newPlayer) return;

    if (scale === vitaShadesOrdered.findIndex((item) => item.key === 'natural') && !noEffect) {
      newPlayer.clearEffect();
      setNoEffect(true);
    } else {
      if (noEffect)
        newPlayer
          .applyEffect(effect)
          .then(() => effect.evalJs(`applyTeethTone('${vitaShadesOrdered[scale]?.key}')`));
      effect.evalJs(`applyTeethTone('${vitaShadesOrdered[scale]?.key}')`);
      setNoEffect(false);
    }
  }, [scale]);

  return (
    <>
      <FlexLayout
        space={32}
        flexDirection={'column'}
        sx={{ mx: 'auto', maxWidth: '1200px', py: '16px', px: '10px' }}
      >
        <FlexLayout justifyContent={'space-between'}>
          <Image src={Logo} sx={{ objectFit: 'contain' }} />
          {getLogo(pathname) && (
            <Image
              sx={{ maxWidth: '300px', ...getLogoStyles(pathname) }}
              src={getLogo(pathname) || ''}
            />
          )}
        </FlexLayout>
        {isPlayerLoading && (
          <FlexLayout justifyContent='center'>
            <Icon icon='loading' size='xxl' color='#0FCCC3' />
          </FlexLayout>
        )}
        <Box
          id='webar-app'
          sx={{
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '24px',
            '&>canvas': {
              width: '1280px',
              height: '720px',
              objectFit: 'cover',
            },
          }}
        >
          {!getLogo(pathname) && (
            <Image
              src={LogoSvg}
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                rotate: '-30deg',
                filter: 'grayscale(1) opacity(.4)',

                bottom: '50px',
              }}
            />
          )}
        </Box>
        <FlexLayout flexDirection={'column'} justifyContent='center' alignItems='center' space={44}>
          <Slider
            max={vitaShadesOrdered.length - 1}
            min={0}
            onChangeScale={(value) => setScale(value)}
            scale={scale}
            step={1}
            sx={{ width: '85%' }}
            labels={vitaShadesOrdered.map((shade) => shade.label)}
          />
          {isPhilips && (
            <Image
              sx={{ maxWidth: '300px', ...getLogoStyles(pathname) }}
              src={getLogo(pathname) || ''}
            />
          )}
        </FlexLayout>
        {isPhilips && showSnapshotModal && (
          <Modal
            title=''
            p={4}
            width={isMobile() ? 'max' : 'l'}
            onHide={() => {
              playerRef.current?.play();
              setShowSnapshotModal(false);
            }}
            children={
              <FlexLayout flexDirection='column' alignItems='center' space={4}>
                {snapshot && (
                  <Image
                    src={snapshot}
                    sx={{
                      border: '4px solid',
                      borderColor: colors.irisBlue,
                      borderRadius: '8px',
                    }}
                  />
                )}
                <FlexLayout
                  space={2}
                  flexDirection={isMobile() ? 'column' : 'row'}
                  alignItems='center'
                >
                  <Button
                    text='Find Dental Professionals that do our Zoom!'
                    variant='irisBlue'
                    size='l'
                    onClick={() => {
                      // TODO add zoom link from philips
                    }}
                  />
                  <Button
                    text='Retake photo'
                    variant='secondary'
                    size='l'
                    onClick={() => {
                      playerRef.current?.play();
                      setShowSnapshotModal(false);
                    }}
                  />
                </FlexLayout>
              </FlexLayout>
            }
          />
        )}
      </FlexLayout>
      <TrackingBanner />
    </>
  );
};

export default SdkComponentTeethWhitening;
