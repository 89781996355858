export const sizes = {
  'button-width': '140px',
  'card-height': '430px',
  'card-width': '300px',
  'image-label-width': '70px',
  'modal-height': '520px',
  'photo-frame-height': '636px',
  'photo-frame-mobile-height': '406px',
  'photo-frame-mobile-width': '300px',
  'photo-frame-width': '470px',
  'privacy-modal-width': '380px',
  'slider-scale-size': '40px',
  'take-advice-mobile-width': '330px',
  'take-advice-width': '390px',
  'terms-modal-width': '650px',
  'title-size': '470px',
};
