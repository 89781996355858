import React from 'react';
import Tippy from '@tippyjs/react';
import { ThemeUIStyleObject } from 'theme-ui';

import * as icons from '../../assets/icons';
import { IconType } from '../../theme';
import { FlexLayout } from '..';
import { FlexLayoutProps } from '../../props';

export const sizesMap = {
  max: '180px',
  xxl: '54px',
  l: '32px',
  m: '24px',
  s: '16px',
  xl: '40px',
  xs: '10px',
};

export interface IconProps extends FlexLayoutProps {
  ariaLabel?: string;
  color?: string;
  icon: IconType;
  height?: string;
  width?: string;
  size?: keyof typeof sizesMap;
  sx?: ThemeUIStyleObject;
  onClick?: () => void;
  tooltip?: any;
}

const Icon: React.FC<IconProps> = ({
  ariaLabel,
  color,
  icon,
  size = 'm',
  height,
  width,
  sx,
  onClick,
  tooltip,
  ...rest
}) => {
  const IconComponent = icons[icon];

  const base = (
    <FlexLayout
      aria-label={ariaLabel}
      color={color}
      onClick={onClick}
      sx={{
        ...sx,
        height: height || sizesMap[size],
        width: width || sizesMap[size],
        cursor: onClick ? 'pointer' : 'inherit',
      }}
      {...rest}
    >
      <IconComponent color={color} height='100%' width='100%' />
    </FlexLayout>
  );

  return tooltip ? <Tippy content={tooltip}>{base}</Tippy> : base;
};

export default Icon;
