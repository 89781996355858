import { useMutation } from 'react-query';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { PageLayout } from '../pages-components';
import { resetPasswordRequest } from '../apis';
import { Routes } from '../routes/routes';
import { passwordRegex } from '../helpers';
import { Button, FlexLayout, Image, Input, Text, colors, useScreenType } from '../ui';
import Whitening from '../ui/assets/images/whitening-step1.jpg';

interface ResetPasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const { control, formState, handleSubmit, watch } = useForm<ResetPasswordFormValues>({
    mode: 'all',
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const navigate = useNavigate();
  const { isDesktop } = useScreenType();

  const { isSuccess, mutate } = useMutation(resetPasswordRequest, {
    onError: () => setErrorMessage('Something went wrong. Please try again.'),
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    token && setToken(token);
  }, []);

  const onSubmit = async (values: ResetPasswordFormValues) => {
    setErrorMessage('');
    mutate({ password: values.newPassword, token });
  };

  return (
    <PageLayout>
      {isSuccess ? (
        <FlexLayout
          flexDirection={'column'}
          alignItems={'center'}
          space={4}
          mt={['10px', '20px', '40px']}
          p={['20px', '20px', '40px', '80px']}
          sx={{ width: '100%', p: '80px' }}
        >
          <FlexLayout flexDirection={'column'} alignItems={'center'} space={4} sx={{ pb: '40px' }}>
            <Text variant={'text-xxl-medium'}>Reset Password</Text>
            <Text variant={'text-s-regular'}>
              Your Password was successfully reset, you can now log in with your updated password.
            </Text>
            <Button
              size='standard'
              text={'All done'}
              variant='irisBlue'
              onClick={() => navigate(Routes.Login)}
            />
          </FlexLayout>
        </FlexLayout>
      ) : (
        <FlexLayout
          flexDirection={'column'}
          alignItems={'center'}
          space={4}
          mt={['10px', '20px', '40px']}
          p={['20px', '20px', '40px', '80px']}
          sx={{ width: '100%', p: '80px' }}
        >
          <FlexLayout flexDirection={'column'} alignItems={'center'} space={4} sx={{ pb: '40px' }}>
            <Text variant={'text-xxl-medium'}>Reset Password</Text>
            <Text variant={'text-s-regular'}>Enter your new password and you’re all set.</Text>
          </FlexLayout>
          <FlexLayout
            alignItems={'flex-start'}
            justifyContent={'center'}
            flexDirection={!isDesktop() ? 'column' : 'row'}
            space={4}
            sx={{
              width: '100%',
              borderRadius: '8px',
              p: '80px',
              backgroundColor: `${colors['lightCyan']}`,
            }}
          >
            <FlexLayout
              alignItems={'center'}
              justifyContent={!isDesktop() ? 'center' : 'flex-end'}
              sx={{ width: '100%', height: '100%', maxWidth: '600px', maxHeight: '480px' }}
            >
              <Image src={Whitening} />
            </FlexLayout>
            <FlexLayout
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={!isDesktop() ? 'center' : 'flex-start'}
              space={2}
              sx={{ width: '100%', height: '100%', maxWidth: '600px' }}
              onSubmit={handleSubmit(onSubmit)}
              as={'form'}
            >
              <Input
                control={control as any}
                error={!!(formState.dirtyFields.newPassword && formState.errors.newPassword)}
                icon={showNewPassword ? 'hidePassword' : 'showPassword'}
                label={'New Password'}
                message={formState.errors.newPassword?.message}
                name='newPassword'
                rules={{
                  pattern: {
                    value: passwordRegex,
                    message:
                      'The password must be at least 8 characters long, contain at least one upper case character, one lower case character and one number.',
                  },
                  required: { value: true, message: 'Password is required.' },
                }}
                type={showNewPassword ? 'text' : 'password'}
                onIconClick={() => setShowNewPassword(!showNewPassword)}
              />
              <Input
                control={control as any}
                error={
                  !!(formState.dirtyFields.confirmPassword && formState.errors.confirmPassword) ||
                  !!errorMessage
                }
                icon={showConfirmPassword ? 'hidePassword' : 'showPassword'}
                label={'Confirm Password'}
                message={formState.errors.confirmPassword?.message || errorMessage}
                name='confirmPassword'
                rules={{
                  pattern: {
                    value: passwordRegex,
                    message:
                      'The password must be at least 8 characters long, contain at least one upper case character, one lower case character and one number.',
                  },
                  required: { value: true, message: 'Password is required.' },
                  validate: {
                    passwordsEqual: (value: string) =>
                      value === watch('newPassword') || 'Passwords Do Not Match.',
                  },
                }}
                type={showConfirmPassword ? 'text' : 'password'}
                onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
              <FlexLayout
                flexDirection='column'
                sx={{ justifySelf: 'flex-end', width: !isDesktop() ? '100%' : 'auto' }}
                py={1}
              >
                <Button
                  size='standard'
                  text={'Reset Password'}
                  variant='irisBlue'
                  type={'submit'}
                  width={!isDesktop() ? '100%' : 'auto'}
                />
                {errorMessage && (
                  <Text color='red' sx={{ textAlign: 'center' }} variant={'text-xs-regular'}>
                    {errorMessage}
                  </Text>
                )}
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      )}
    </PageLayout>
  );
};

export default ResetPassword;
