import React from 'react';
import { Box, FlexLayout, Text } from '..';
import TableRow from './TableRow';

const TableDisplay: React.FC<{
  table: any;
  hideHeaderDepth?: number;
  onItemClick?: (item: any) => void;
  onItemHover?: (item: any) => void;
}> = ({ table, onItemClick, hideHeaderDepth, onItemHover }) => {
  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } = table;
  return (
    <Box
      {...getTableProps()}
      sx={{
        borderRadius: 'm',
        height: '100%',
        overflow: 'scroll',
        width: '100%',
      }}
    >
      {headerGroups.map((headerGroup: any, index: number) => {
        const { ...headerProps } = headerGroup.getHeaderGroupProps();
        if (headerGroup.headers.some((h: any) => h.Header && h.depth === hideHeaderDepth)) return;
        return (
          <FlexLayout
            {...headerProps}
            bg={'irisBlue'}
            key={index}
            px={4}
            sx={{ width: '100%', overflow: 'hidden', minWidth: '70vw' }}
          >
            {headerGroup.headers.map(
              (column: any, index: number) =>
                !column.hideHeader && (
                  <FlexLayout
                    key={index}
                    {...(column.getHeaderProps(),
                    column.getHeaderProps(column.getSortByToggleProps()))}
                    alignItems='center'
                    bg={'irisBlue'}
                    justifyContent={index === 0 ? 'flex-start' : 'center'}
                    pr={4}
                    py={3}
                    sx={{ width: '100%', overflow: 'hidden', minWidth: '100vw' }}
                  >
                    <Text
                      color={'white'}
                      sx={index === 0 ? { textAlign: 'left' } : { textAlign: 'center' }}
                      variant={'text-s-regular'}
                    >
                      <FlexLayout alignItems={'center'} flexDirection={'row'}>
                        {column.render('Header')}
                      </FlexLayout>
                    </Text>
                  </FlexLayout>
                ),
            )}
          </FlexLayout>
        );
      })}
      <Box
        {...getTableBodyProps()}
        sx={{
          borderLeft: 'card-layout',
          borderRight: 'card-layout',
          borderRadius: '0px 0px 4px 4px',
        }}
      >
        {rows.map((row: any, index: number) => {
          prepareRow(row);
          return (
            <TableRow
              index={index}
              key={index}
              lastItem={index === rows.length - 1}
              row={row}
              onItemClick={onItemClick}
              onItemHover={onItemHover}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default TableDisplay;
