import bracesStep1 from '../ui/assets/images/braces-step1.jpg';
import bracesStep2 from '../ui/assets/images//braces-step2.jpg';
import bracesStep3 from '../ui/assets/images//braces-step3.jpg';
import bracesStep4 from '../ui/assets/images//braces-step4.jpg';
import veneersStep1 from '../ui/assets/images//veneers-step1.jpg';
import veneersStep2 from '../ui/assets/images//veneers-step2.jpg';
import veneersStep3 from '../ui/assets/images//veneers-step3.jpg';
import veneersStep4 from '../ui/assets/images//veneers-step4.jpg';
import whiteningStep1 from '../ui/assets/images//whitening-step1.jpg';
import whiteningStep2 from '../ui/assets/images//whitening-step2.jpg';
import whiteningStep3 from '../ui/assets/images//whitening-step3.jpg';
import whiteningStep4 from '../ui/assets/images//whitening-step4.jpg';
import { Procedures } from '../common';

const handbookWhiteningCards = [
  {
    icon: whiteningStep1,
    mediumTitle: 'common.step1',
    partOne: 'howItWorksWhitening.stepOne.textOne',
    partTwo: 'howItWorksWhitening.stepOne.textTwo',
    regularTitle: 'howItWorksWhitening.stepOne.title',
  },
  {
    icon: whiteningStep2,
    mediumTitle: 'common.step2',
    partOne: 'howItWorksWhitening.stepTwo.textOne',
    partTwo: 'howItWorksWhitening.stepTwo.textTwo',
    regularTitle: 'howItWorksWhitening.stepTwo.title',
  },
  {
    icon: whiteningStep3,
    mediumTitle: 'common.step3',
    partOne: 'howItWorksWhitening.stepThree.textOne',
    partTwo: 'howItWorksWhitening.stepThree.textTwo',
    regularTitle: 'howItWorksWhitening.stepThree.title',
  },
  {
    icon: whiteningStep4,
    mediumTitle: 'common.step4',
    partOne: 'howItWorksWhitening.stepFour.textOne',
    partTwo: 'howItWorksWhitening.stepFour.textTwo',
    regularTitle: 'howItWorksWhitening.stepFour.title',
  },
];

const handbookVeneersCards = [
  {
    icon: veneersStep1,
    mediumTitle: 'common.step1',
    partOne: 'howItWorksVeneers.stepOne.textOne',
    partTwo: 'howItWorksVeneers.stepOne.textTwo',
    regularTitle: 'howItWorksVeneers.stepOne.title',
  },
  {
    icon: veneersStep2,
    mediumTitle: 'common.step2',
    partOne: 'howItWorksVeneers.stepTwo.textOne',
    partTwo: 'howItWorksVeneers.stepTwo.textTwo',
    regularTitle: 'howItWorksVeneers.stepTwo.title',
  },
  {
    icon: veneersStep3,
    mediumTitle: 'common.step3',
    partOne: 'howItWorksVeneers.stepThree.textOne',
    partTwo: 'howItWorksVeneers.stepThree.textTwo',
    regularTitle: 'howItWorksVeneers.stepThree.title',
  },
  {
    icon: veneersStep4,
    mediumTitle: 'common.step4',
    partOne: 'howItWorksVeneers.stepFour.textOne',
    partTwo: 'howItWorksVeneers.stepFour.textTwo',
    regularTitle: 'howItWorksVeneers.stepFour.title',
  },
];

const handbookBracesCards = [
  {
    icon: bracesStep1,
    mediumTitle: 'common.step1',
    partOne: 'howItWorksBraces.stepOne.textOne',
    partTwo: 'howItWorksBraces.stepOne.textTwo',
    regularTitle: 'howItWorksBraces.stepOne.title',
  },
  {
    icon: bracesStep2,
    mediumTitle: 'common.step2',
    partOne: 'howItWorksBraces.stepTwo.textOne',
    partTwo: 'howItWorksBraces.stepTwo.textTwo',
    regularTitle: 'howItWorksBraces.stepTwo.title',
  },
  {
    icon: bracesStep3,
    mediumTitle: 'common.step3',
    partOne: 'howItWorksBraces.stepThree.textOne',
    partTwo: 'howItWorksBraces.stepThree.textTwo',
    regularTitle: 'howItWorksBraces.stepThree.title',
  },
  {
    icon: bracesStep4,
    mediumTitle: 'common.step4',
    partOne: 'howItWorksBraces.stepFour.textOne',
    partTwo: 'howItWorksBraces.stepFour.textTwo',
    regularTitle: 'howItWorksBraces.stepFour.title',
  },
];

export const getHandbookData = (procedure: Procedures) => {
  if (procedure === Procedures.Whitening) {
    return handbookWhiteningCards;
  } else if (procedure === Procedures.Veneers) {
    return handbookVeneersCards;
  }
  return handbookBracesCards;
};

export const getHandbookTitle = (procedure: Procedures) => {
  if (procedure === Procedures.Whitening) {
    return 'howItWorksWhitening.title';
  } else if (procedure === Procedures.Veneers) {
    return 'howItWorksVeneers.title';
  }

  return 'howItWorksBraces.title';
};
