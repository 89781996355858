import React, { useState } from 'react';
import { Button, Checkbox, colors, FlexLayout, Input, Text, useScreenType } from '../ui';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SignUpFormValues } from '../apis';
import { minLengthUtil } from '../utils';
import { widgetNameRegex } from '../helpers';

interface AccountActivationProps {
  form: UseFormReturn<SignUpFormValues>;
  onAccountActivationSubmit: () => void;
  errorSignupMessage: string;
}

const AccountActivation = ({
  form,
  onAccountActivationSubmit,
  errorSignupMessage,
}: AccountActivationProps) => {
  const { control, formState, handleSubmit, setValue } = form;
  const { isDesktop } = useScreenType();
  const [braces, setBraces] = useState(false);
  const [veneers, setVeneers] = useState(false);
  const [whitening, setWhitening] = useState(false);

  const onSubmit = async () => {
    onAccountActivationSubmit();
  };

  return (
    <FlexLayout
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      flexDirection={'column'}
      space={4}
      sx={{
        width: '100%',
        maxWidth: '1280px',
        borderRadius: '8px',
        p: ['10px', '20px', '40px', '80px'],
        backgroundColor: `${colors['lightCyan']}`,
      }}
    >
      <FlexLayout
        alignItems={'flex-start'}
        sx={{ width: '100%', height: '100%' }}
        space={4}
        onSubmit={handleSubmit(onSubmit)}
        as={'form'}
        flexDirection={'column'}
      >
        <FlexLayout alignItems={'flex-start'} flexDirection={'column'} space={1}>
          <Text variant={'text-l-medium'} color={colors['secondaryArsenic']}>
            Account Activation
          </Text>
          <Text variant={'text-s-regular'} color={colors['secondaryArsenic']}>
            Unlock your personalised account and experience our products in full.
          </Text>
        </FlexLayout>
        <FlexLayout
          flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          space={4}
          sx={{ width: '100%', height: '100%' }}
        >
          <Controller
            control={control as any}
            name='widgetName'
            rules={{
              required: { value: true, message: 'Widget name is required.' },
              pattern: {
                value: widgetNameRegex,
                message: 'Widget name must only contain characters, numbers, - and _',
              },
              minLength: minLengthUtil(2),
            }}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  rules={{
                    required: { value: true, message: 'Widget name is required.' },
                  }}
                  onChange={(e) => field.onChange(e.target.value.replace(/\s/g, '-'))}
                  error={!!(formState.dirtyFields.widgetName && formState.errors.widgetName)}
                  label={'Widget Name'}
                  labelIcon={'exclamationMark'}
                  message={formState.errors?.widgetName?.message}
                  placeholder={'Eg: Try out your virtual smile'}
                />
              );
            }}
          />
          <Input
            control={control as any}
            error={!!(formState.dirtyFields.shortDescription && formState.errors.shortDescription)}
            label={'Short Description*'}
            message={formState.errors?.shortDescription?.message}
            name='shortDescription'
            placeholder={'Enter short description'}
            rules={{
              required: { value: true, message: 'Short description is required.' },
              minLength: minLengthUtil(2),
            }}
          />
          <FlexLayout
            alignItems={'flex-start'}
            flexDirection='column'
            justifyContent='space-between'
            space={4}
            sx={{ width: '220px' }}
          >
            <Text variant={'text-m-regular'} color={colors['secondaryArsenic']}>
              Select Products*
            </Text>
            <Controller
              control={control}
              name={'braces'}
              render={() => (
                <Checkbox
                  checked={braces}
                  label={'Braces'}
                  onCheck={(isActive) => {
                    setBraces(isActive);
                    setValue('braces', isActive, { shouldValidate: true, shouldDirty: true });
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name={'veneers'}
              render={() => (
                <Checkbox
                  checked={veneers}
                  label={'Veneers'}
                  onCheck={(isActive) => {
                    setVeneers(isActive);
                    setValue('veneers', isActive, { shouldValidate: true, shouldDirty: true });
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name={'whitening'}
              render={() => (
                <Checkbox
                  checked={whitening}
                  label={'Whitening'}
                  onCheck={(isActive: boolean) => {
                    setWhitening(isActive);
                    setValue('whitening', isActive, { shouldValidate: true, shouldDirty: true });
                  }}
                />
              )}
            />
          </FlexLayout>
          <FlexLayout
            alignItems={'center'}
            justifyContent={!isDesktop ? 'center' : 'flex-start'}
            sx={{ width: '100%' }}
          >
            <Button
              size='standard'
              text={'Activate'}
              variant='irisBlue'
              isDisabled={!formState.isValid || !(whitening || braces || veneers)}
              type={'submit'}
              width={!isDesktop() ? '100%' : 'none'}
            />
          </FlexLayout>
          {errorSignupMessage && (
            <Text as='p' color='red' sx={{ textAlign: 'center' }} variant={'text-s-regular'}>
              {errorSignupMessage}
            </Text>
          )}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export default AccountActivation;
