import React from 'react';
import { Box as ThemeBox } from 'theme-ui';
import { BoxProps } from '../../props';

const Box = React.forwardRef<unknown, BoxProps>(
  ({ as, name, sx, type, value, isDisabled = false, onClick, ...rest }, ref) => {
    return (
      <ThemeBox
        as={as}
        ref={ref}
        sx={sx}
        {...{ disabled: isDisabled, name, type, value, ...rest }}
        onClick={onClick && !isDisabled ? onClick : undefined}
      />
    );
  },
);

export default Box;
