import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../store';
import { AnalyticsEventNames, trackAnalyticsEvent } from '../utils';

export const useAnalyticsOnLoad = (event: AnalyticsEventNames, params = {}) => {
  const widget = useSelector((state: RootState) => state.widget.widget);

  useEffect(() => {
    if (Object.keys(widget).length > 0) {
      trackAnalyticsEvent(event, {
        ...params,
        customerID: widget.user.uuid,
        customerName: widget.user?.clinicName,
        customerType: widget.user.type,
      });
    }
  }, [widget]);
};
