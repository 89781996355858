import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsBlissWidget } from '../../hooks';
import { Button, Checkbox, colors, FlexLayout, Text, useScreenType } from '../../ui';

interface Props {
  termsAccepted: boolean;
  onCheck: (value: boolean) => void;
  onDecline: () => void;
  onAccept: () => void;
  openTerms: () => void;
}
const PrivacyModalContent = (props: Props) => {
  const { termsAccepted, onCheck, onDecline, onAccept, openTerms } = props;
  const { isMobile } = useScreenType();

  const { t } = useTranslation();

  const isBlissWidget = useIsBlissWidget();

  return (
    <FlexLayout flexDirection='column' justifyContent='space-between' p={[5, 6]}>
      <FlexLayout flexDirection='column' space={3}>
        <Text variant='text-xl-regular'>{t('privacyModal.title')}</Text>
        <FlexLayout flexDirection='column' space={5}>
          {isBlissWidget ? (
            <Text variant='bliss-text'>
              We need to process your photo to provide a virtual try-on experience.
            </Text>
          ) : (
            <Text color={colors.black07} variant='text-m-regular'>
              {t('privacyModal.text1')}
            </Text>
          )}
          <FlexLayout flexDirection='column'>
            {isBlissWidget ? (
              <Text variant='bliss-text-bold'>We don&apos;t share or store your photo.</Text>
            ) : (
              <Text variant='text-m-medium'>{t('privacyModal.text2.bold')}</Text>
            )}
            {isBlissWidget ? null : (
              <Text color={colors.black07} variant='text-m-regular'>
                {t('privacyModal.text2.regular')}
              </Text>
            )}
          </FlexLayout>
          <Checkbox
            checked={termsAccepted}
            label={
              <FlexLayout
                alignItems='center'
                flexWrap={isMobile() ? 'wrap' : 'nowrap'}
                ml={2}
                space={1}
              >
                <Text sx={{ width: isMobile() && '80px' }} variant='text-s-regular'>
                  {t('privacyModal.terms.text')}
                </Text>
                <FlexLayout onClick={openTerms}>
                  <Text
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      width: isMobile() && '150px',
                    }}
                    variant='text-s-regular'
                  >
                    {t('privacyModal.terms.link')}
                  </Text>
                </FlexLayout>
              </FlexLayout>
            }
            onCheck={(value) => {
              onCheck(value);
            }}
          />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout
        flexDirection={isMobile() ? 'column' : 'row'}
        mt={isBlissWidget ? 6 : [6, 10]}
        space={2}
        sx={{ width: '100%' }}
      >
        <Button
          isDisabled={!termsAccepted}
          onClick={() => {
            onAccept();
          }}
          size='s'
          text={t('common.accept')}
          variant={isBlissWidget ? 'blissPrimary' : 'primary'}
          width={isMobile() ? '100%' : 'button-width'}
        />
        <Button
          onClick={onDecline}
          size='s'
          text={t('common.decline')}
          variant={isBlissWidget ? 'blissSecondary' : 'secondary'}
          width={isMobile() ? '100%' : 'button-width'}
        />
      </FlexLayout>
    </FlexLayout>
  );
};

export default PrivacyModalContent;
