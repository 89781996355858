import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { Button, FlexLayout, GridLayout, Input, showToast, Text } from '../ui';
import { passwordRegex } from '../helpers';
import { updatePassword, UpdatePasswordDto } from '../apis';
import { ToastType } from '../enums';

interface ChangePasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword = () => {
  const form = useForm<ChangePasswordFormValues>({
    mode: 'all',
  });
  const { control, formState, handleSubmit, watch } = form;
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);

  const { mutate } = useMutation(updatePassword, {
    onError: () => {
      showToast({
        title: 'Something went wrong. Please try again later.',
        toastType: ToastType.ERROR,
      });
    },
    onSuccess: () => {
      showToast({
        title: 'Changes saved successfully.',
        toastType: ToastType.INFO,
      });
    },
  });

  const onSubmit = async (values: UpdatePasswordDto) => {
    mutate({ ...values });
  };

  return (
    <FlexLayout
      alignItems={'flex-start'}
      justifyContent='space-between'
      flexDirection={'column'}
      as='form'
      space={4}
      onSubmit={handleSubmit(onSubmit)}
      sx={{ width: '100%', height: '100%' }}
    >
      <FlexLayout alignItems={'flex-start'} flexDirection={'column'} space={4}>
        <Text variant={'text-s-medium'} color={'secondaryArsenic'} sx={{ alignSelf: 'flex-start' }}>
          Check your details here!
        </Text>
        <GridLayout
          alignItems={'flex-start'}
          gap={4}
          gridTemplateColumns={'repeat(2, 1fr)'}
          sx={{ width: '100%' }}
        >
          <Input
            control={control as any}
            error={!!(formState.dirtyFields.newPassword && formState.errors.newPassword)}
            icon={showNewPassword ? 'hidePassword' : 'showPassword'}
            label={'New Password'}
            message={formState.errors.newPassword?.message}
            name='newPassword'
            rules={{
              pattern: {
                value: passwordRegex,
                message:
                  'The password must be at least 8 characters long, contain at least one upper case character, one lower case character and one number.',
              },
              required: { value: true, message: 'Password is required.' },
            }}
            type={showNewPassword ? 'text' : 'password'}
            onIconClick={() => setShowNewPassword(!showNewPassword)}
          />
          <Input
            control={control as any}
            error={!!(formState.dirtyFields.confirmPassword && formState.errors.confirmPassword)}
            icon={showConfirmPassword ? 'hidePassword' : 'showPassword'}
            label={'Confirm Password'}
            message={formState.errors.confirmPassword?.message}
            name='confirmPassword'
            rules={{
              pattern: {
                value: passwordRegex,
                message:
                  'The password must be at least 8 characters long, contain at least one upper case character, one lower case character and one number.',
              },
              required: { value: true, message: 'Password is required.' },
              validate: {
                passwordsEqual: (value: string) =>
                  value === watch('newPassword') || 'Passwords Do Not Match.',
              },
            }}
            type={showConfirmPassword ? 'text' : 'password'}
            onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        </GridLayout>
      </FlexLayout>
      <Button size='standard' text={'Save Changes'} variant='irisBlue' type={'submit'} />
    </FlexLayout>
  );
};

export default ChangePassword;
