import { IconType } from '../ui';

export type PhotoErrorType = {
  icon: IconType;
  title: string;
  description: string;
  code: string;
};

export const photoErrors = {
  NOT_FRONTAL_POSE: {
    code: 'NOT_FRONTAL_POSE',
    description: 'photoValidations.noFrontalPose.description',
    icon: 'tiltedHead' as IconType,
    title: 'photoValidations.noFrontalPose.title',
  },
  NOT_SHOWING_TEETH: {
    code: 'NOT_SHOWING_TEETH',
    description: 'photoValidations.notShowingTeeth.description',
    icon: 'noSmile' as IconType,
    title: 'photoValidations.notShowingTeeth.title',
  },
  TEETH_COVERED: {
    code: 'TEETH_COVERED',
    description: 'photoValidations.teethCovered.description',
    icon: 'noTeeth' as IconType,
    title: 'photoValidations.teethCovered.title',
  },
  USER_NOT_PRESENT: {
    code: 'USER_NOT_PRESENT',
    description: 'photoValidations.userNotPresent.description',
    icon: 'noFace' as IconType,
    title: 'photoValidations.teethCovered.title',
  },
};
