import React, { useContext, useMemo, useState } from 'react';

import { FlexLayout, Icon } from '../ui';
import { AdminPageLayout, AdminPageTabs, ChangePassword, ProfileInfo } from '../pages-components';
import { AuthContext } from '../context';

export interface PageTab {
  title: string;
}

const UserSettings = () => {
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState<PageTab>();

  const tabs = useMemo(
    () => [
      {
        title: 'Profile Info',
        component: <ProfileInfo />,
      },
      {
        title: 'Change Password',
        component: <ChangePassword />,
      },
    ],
    [],
  );

  return (
    <AdminPageLayout>
      <FlexLayout
        flexDirection={'column'}
        alignItems={'flex-start'}
        sx={{ width: '100%', height: '100%' }}
      >
        <AdminPageTabs activeTab={activeTab as PageTab} setActiveTab={setActiveTab} tabs={tabs} />
        <FlexLayout
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          py={4}
          sx={{ width: '100%', height: '100%' }}
        >
          {!user ? (
            <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
              <Icon icon='loading' size='xl' />
            </FlexLayout>
          ) : (
            <>{activeTab && tabs?.find((tab) => tab.title === activeTab?.title)?.component}</>
          )}
        </FlexLayout>
      </FlexLayout>
    </AdminPageLayout>
  );
};

export default UserSettings;
