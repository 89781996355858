import React, { useMemo } from 'react';
import { ThemeUICSSObject } from 'theme-ui';
import { PaginationControls } from '../../props';

import TableComponent from './TableComponent';

const DEFAULT_CELL_WIDTH = 150;

export interface TableProps {
  actionColumnHeader?: string;
  actionColumnWidth?: number;
  actionColumn?: any;
  columns: any[];
  data: any[];
  pagination?: PaginationControls;
  headerBg?: string;
  tabsFilter?: any;
  sx?: ThemeUICSSObject;
  onItemClick?: (item: any) => void;
  onItemHover?: (item: any) => void;
  noData?: {
    title: string;
    subtitle: string;
  };
  tableScrollable?: boolean;
  setSelectedUsers?: any;
  disableSortBy?: boolean;
  hide?: boolean;
  dropdown?: {
    show: boolean;
    content: any[];
    setIndepthCourse: React.Dispatch<React.SetStateAction<string | null>>;
    indepthCourseId: string | null;
  };
}

const Table: React.FC<TableProps> = (props) => {
  const {
    actionColumn,
    actionColumnHeader = 'Actions',
    actionColumnWidth = 80,
    columns = [],
    data = [],
    headerBg = 'irisBlue',
    tabsFilter,
    sx,
    onItemClick,
    onItemHover,
    noData,
    setSelectedUsers,
    disableSortBy,
    hide,
    dropdown = { show: false, content: [], setIndepthCourse: () => null, indepthCourseId: null },
    ...rest
  } = props;

  const tableColumns = columns.map((column) => {
    const {
      alignToCenter = false,
      label,
      value,
      id,
      padding,
      width,
      renderCustomCell,
      disableSortBy = true,
      hide = false,
    } = column;

    return {
      alignToCenter,
      id,
      accessor: value,
      Cell: ({ row, cell, hovered }: { row: any; cell: any; hovered: boolean }) =>
        renderCustomCell ? renderCustomCell(row.original, hovered) : cell.value,
      Header: label,
      width: width || DEFAULT_CELL_WIDTH,
      padding,
      disableSortBy: disableSortBy,
      hide: hide,
    };
  });

  if (actionColumn) {
    tableColumns.push({
      accessor: 'actions',
      alignToCenter: true,
      id: 'actionColumn',
      Cell: ({ row }) => actionColumn(row.original),
      Header: actionColumnHeader,
      width: actionColumnWidth,
      padding: actionColumnHeader !== 'Actions' ? -4 : null,
      disableSortBy: true,
      hide: false,
    });
  }

  const mappedColumns = useMemo(() => [...tableColumns], [tableColumns, props]);

  return (
    <TableComponent
      columns={mappedColumns}
      data={data}
      disableSortBy={disableSortBy}
      dropdown={dropdown}
      headerBg={headerBg}
      hide={hide}
      noData={noData}
      setSelectedUsers={setSelectedUsers}
      sx={sx}
      tabsFilter={tabsFilter}
      onItemClick={onItemClick}
      onItemHover={onItemHover}
      {...rest}
    />
  );
};

export default Table;
