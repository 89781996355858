import React from 'react';

import { colors, radii } from '../../theme';
import { FlexLayout, Icon, Text } from '..';
import { CheckboxProps } from '../../props';

const Checkbox = React.forwardRef<HTMLDivElement, CheckboxProps>(
  ({ checked, label, onCheck }, ref) => {
    return (
      <FlexLayout alignItems='center' space={2}>
        <FlexLayout
          onClick={() => {
            onCheck(!checked);
          }}
          ref={ref}
          sx={{
            background: colors.secondary05,
            border: `2px solid ${checked ? colors.irisBlue : colors.secondary05}`,
            borderRadius: radii.s,
            height: '28px',
            width: '28px',
          }}
        >
          {checked && (
            <Icon
              icon='check'
              color={colors['white']}
              size='m'
              sx={{ backgroundColor: 'irisBlue' }}
            />
          )}
        </FlexLayout>
        <Text variant='text-s-regular'>{label}</Text>
      </FlexLayout>
    );
  },
);

export default Checkbox;
