import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input, Label } from 'theme-ui';

import { emailPhotos } from '../../apis';
import { RootState } from '../../store';
import { ReactComponent as KiteWithLace } from '../../ui/assets/images/kite-with-lace.svg';
import { usePhotoEditor } from '../../context/photoEditor';
import { Box, colors, Button, FlexLayout, Icon, Text, radii } from '../../ui';
import { AnalyticsEventNames, trackAnalyticsEvent, base64ToBlob } from '../../utils';

interface Props {
  onClose: () => void;
}
const EmailModalContent = (props: Props) => {
  const { onClose } = props;

  const { t } = useTranslation();

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendingError, setSendingError] = useState('');

  const widget = useSelector((state: RootState) => state.widget.widget);

  const { croppedImage, resultImage, imageMimeType, procedure } = usePhotoEditor();

  const theme = useSelector((state: RootState) => state.widget.widget.user.theme);

  const onSend = () => {
    setIsLoading(true);

    const beforeImage = base64ToBlob(croppedImage!, imageMimeType);
    const afterImage = base64ToBlob(resultImage!, imageMimeType);

    const formData = new FormData();

    formData.append('email', email);
    formData.append('before', beforeImage);
    formData.append('after', afterImage);

    setSendingError('');

    emailPhotos(formData)
      .then((r: AxiosResponse) => {
        if (r.status === 201) {
          setIsLoading(false);
          setIsEmailSent(true);
        }

        if (Object.keys(widget).length > 0) {
          trackAnalyticsEvent(AnalyticsEventNames.ResultsSaved, {
            customerID: widget.user.uuid,
            customerName: widget.user?.clinicName,
            customerType: widget.user.type,
            simulationName: procedure,
          });
        }

        throw new Error(r.data.message);
      })
      .catch((e: Error) => {
        setIsLoading(false);
        setSendingError(e.message);
      });
  };

  return (
    <FlexLayout
      alignItems='center'
      flexDirection='column'
      p={[5, 6]}
      sx={{
        width: '100%',
      }}
    >
      {isEmailSent ? (
        <KiteWithLace height='70px' width='80px' />
      ) : (
        <Icon icon='kite' size='l' sx={{ marginTop: '40px' }} />
      )}
      <Text sx={{ marginTop: '24px' }} variant='text-xl-regular'>
        {isEmailSent ? t('emailPhotoModal.emailSent') : t('emailPhotoModal.emailPhoto')}
      </Text>
      {isEmailSent ? (
        <FlexLayout alignItems='center' flexDirection='column' space={2}>
          <Text color={colors.black06} sx={{ marginTop: '40px' }} variant='text-m-regular'>
            {t('emailPhotoModal.message')}
          </Text>
          <Text variant='text-m-regular'>{email}</Text>
          <Box mt={10} sx={{ width: '100%' }}>
            <Button onClick={onClose} size='s' text='OK' variant='primary' width='100%' />
          </Box>
        </FlexLayout>
      ) : (
        <>
          <FlexLayout flexDirection='column' mt={6} space={2} sx={{ width: '100%' }}>
            <Label>
              <Text color={colors.black07} variant='text-xs-regular'>
                {t('emailPhotoModal.emailInputLabel')}
              </Text>
            </Label>

            <Input
              disabled={isLoading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
                if (event.target.value === '') {
                  setError(true);
                } else {
                  setError(false);
                }
              }}
              sx={{
                background: theme.foregroundColor,
                border: error ? `2px solid ${colors.red}` : 'none',
                borderRadius: radii.m,
                height: '40px',
              }}
              value={email}
            />
          </FlexLayout>
          <FlexLayout flexDirection='column' mt={7} space={2} sx={{ width: '100%' }}>
            <Button
              isDisabled={email === '' || isLoading}
              onClick={onSend}
              size='s'
              text={isLoading ? t('common.sending') : t('common.send')}
              variant='primary'
              width='100%'
            />
            <Button
              isDisabled={isLoading}
              onClick={onClose}
              size='s'
              text={t('common.cancel')}
              variant='secondary'
              width='100%'
            />
            {sendingError && (
              <Text color='red' sx={{ textAlign: 'center' }} variant='text-m-regular'>
                {sendingError}
              </Text>
            )}
          </FlexLayout>
        </>
      )}
    </FlexLayout>
  );
};

export default EmailModalContent;
