import { RefObject, useEffect } from 'react';

export function useOnClickOutside(ref: any, onClickOutside: () => void): RefObject<HTMLDivElement> {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return ref;
}
