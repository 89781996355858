import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import { PhotoErrorType } from '../../common/photoError';
import { Box, Button, colors, FlexLayout, Icon, radii, Text, useScreenType } from '../../ui';

interface Props {
  onClose: () => void;
  error: PhotoErrorType;
}
const ErrorModalContent = (props: Props) => {
  const { isMobile } = useScreenType();
  const { onClose, error } = props;

  const theme = useSelector((state: RootState) => state.widget.widget.user.theme);

  const { t } = useTranslation();

  return (
    <FlexLayout
      alignItems='center'
      flexDirection='column'
      mx={isMobile() && 4}
      p={[5, 6]}
      sx={{
        background: theme.backgroundColor,
        borderRadius: radii.xxxl,
        height: isMobile() ? '400px' : '450px',
        width: isMobile() ? '300px' : '376px',
      }}
    >
      <Icon icon={error.icon} size='l' sx={{ marginTop: '40px' }} />
      <Text sx={{ marginTop: '24px', textAlign: 'center' }} variant='text-xl-regular'>
        {t(error.title)}
      </Text>
      <FlexLayout alignItems='center' flexDirection='column' space={2}>
        <Text
          color={colors.black06}
          sx={{ marginTop: '16px', textAlign: 'center' }}
          variant='text-m-regular'
        >
          {t(error.description)}
        </Text>
        <Box mt={9} sx={{ width: '100%' }}>
          <Button onClick={onClose} size='s' text={t('common.ok')} variant='primary' width='100%' />
        </Box>
      </FlexLayout>
    </FlexLayout>
  );
};

export default ErrorModalContent;
