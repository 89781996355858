import '../pages-components/WidgetCodeSnippet.css';

import React, { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { atomOneLight, CopyBlock } from 'react-code-blocks';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { findUserWidgetsByUserId, updateUserWidgetStatus, WidgetType } from '../apis';
import { AuthContext } from '../context';
import { ReactComponent as LogoIcon } from '../ui/assets/icons/subtract.svg';
import {
  colors,
  FlexLayout,
  Icon,
  Link,
  Text,
  Button,
  Box,
  Modal,
  showToast,
  Table,
  ThreeDotActionMenu,
} from '../ui';
import { AdminPageLayout, AdminPageTitle } from '../pages-components';
import { renderToStaticMarkup } from 'react-dom/server';
import { ToastType } from '../enums';
import { DropdownOption } from '../hocs/withDropdown';

interface YSSWidgetButtonProps {
  slug: string;
  widgetId: string;
}

interface YSSWidgetProps {
  widget: WidgetType;
  slug: string;
}

const YSSButton = (props: YSSWidgetButtonProps) => {
  const { slug, widgetId } = props;

  return (
    <a
      className='yss-virtual-try-btn'
      href={`${process.env.REACT_APP_WEB_URL}/${slug}/${widgetId}`}
      rel='noreferrer'
      target='_blank'
    >
      Virtual Try On
    </a>
  );
};

const YSSWidget = (props: YSSWidgetProps) => {
  const { slug, widget } = props;

  return (
    <div className='yss-widget--container'>
      {widget.showYSS && (
        <div className='yss-widget--logo-container'>
          <LogoIcon className='yss-widget--logo-icon' />
          <span className='yss-widget--logo-text'>Your Social Smile</span>
        </div>
      )}
      <h2 className='yss-widget--title'>Try-on your new smile — now</h2>
      {widget.showTextBlock && <p className='yss-widget--text'>{widget.text}</p>}
      <a
        className='yss-widget--link'
        href={`${process.env.REACT_APP_WEB_URL}/${slug}/${widget.uuid}`}
        rel='noreferrer'
        target='_blank'
      >
        Virtual Try On
      </a>
    </div>
  );
};

const UserWidgets = () => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showShareWidgetModal, setShowShareWidgetModal] = useState<boolean>(false);
  const [widgets, setWidgets] = useState<WidgetType[]>([]);
  const [selectedWidget, setSelectedWidget] = useState<WidgetType>();
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const navigate = useNavigate();

  function getWidgetsColumns() {
    return [
      { label: 'ID', id: 'uuid', value: 'uuid' },
      { label: 'Name', id: 'text', value: 'text' },
      {
        label: 'Customer facing URL',
        id: 'url',
        value: 'url',
        renderCustomCell: (item: WidgetType) => {
          const { text, uuid } = item;
          return (
            <FlexLayout alignItems='center' space={2}>
              <Link
                target={'_blank'}
                href={`${process.env.REACT_APP_WEB_URL}/${text}/${uuid}`}
                sx={{ textAlign: 'center', wordBreak: 'break-all' }}
              >
                <Text
                  variant={'text-s-medium'}
                  color={colors['irisBlue']}
                  sx={{ textAlign: 'center' }}
                >
                  {`${process.env.REACT_APP_WEB_URL}/${text}/${uuid}`}
                </Text>
              </Link>
            </FlexLayout>
          );
        },
      },
      { label: 'Status', id: 'active', value: 'active' },
    ];
  }

  function getWidgetsData(data: WidgetType[]) {
    return data.map((item: WidgetType) => {
      const { uuid, text, simulation, active, showTextBlock, showYSS } = item;

      return {
        uuid,
        text,
        simulation,
        active: active ? 'Active' : 'Not Active',
        showTextBlock,
        showYSS,
        url: `${process.env.REACT_APP_WEB_URL}/${text}/${uuid}`,
      };
    });
  }

  useQuery(
    ['findUserWidgetsByUserId', user, selectedWidget],
    () => findUserWidgetsByUserId(user?.uuid as string),
    {
      onSuccess: ({ data }) => {
        setWidgets(data);
        setSelectedWidget(data[0]);
      },
      onError: () => {
        showToast({
          title: 'Something went wrong. Please try again later.',
          toastType: ToastType.ERROR,
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
      enabled: !!user?.uuid,
      retry: false,
    },
  );

  const { mutate: mutateWidgetStatus } = useMutation(updateUserWidgetStatus, {
    onError: () => {
      showToast({
        title: 'Something went wrong. Please try again later.',
        toastType: ToastType.ERROR,
      });
    },
    onSuccess: () => {
      showToast({
        title: 'Changes saved successfully.',
        toastType: ToastType.INFO,
      });
    },
  });

  function renderActionMenu(item: any) {
    const dropdownOptions: DropdownOption[] = [
      {
        icon: 'share',
        label: 'Share Widget',
        onClick: () => {
          setSelectedWidget(item);
          setShowShareWidgetModal(true);
        },
      },
      {
        icon: 'edit',
        label: 'Edit Widget',
        onClick: () => {
          setSelectedWidget(item);
          navigate(`/admin/users/${user?.uuid}/widgets/${item.uuid}/edit`);
        },
      },
      {
        icon: 'settings',
        label: item.active === 'Active' ? 'Deactivate Widget' : 'Activate Widget',
        onClick: () => {
          setSelectedWidget(item);
          mutateWidgetStatus({
            userId: user?.uuid as string,
            widgetId: item.uuid,
            updateWidgetDto: {
              active: item.active === 'Active' ? false : true,
            },
          });
        },
      },
    ];

    return <ThreeDotActionMenu dropdownOptions={dropdownOptions} />;
  }

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  return (
    <AdminPageLayout>
      <FlexLayout flexDirection={'column'} alignItems={'flex-start'} sx={{ width: '100%' }}>
        <FlexLayout alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <AdminPageTitle title={'Widgets'} icon={'duplicate'} />
        </FlexLayout>
        <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
          {isLoading ? (
            <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
              <Icon icon='loading' size='xl' />
            </FlexLayout>
          ) : (
            <FlexLayout
              alignItems={'center'}
              flexDirection={'column'}
              justifyContent={'center'}
              py={4}
              space={4}
              sx={{ width: '100%' }}
            >
              <Text
                variant={'text-s-medium'}
                color={colors['secondaryArsenic']}
                sx={{ alignSelf: 'flex-start' }}
              >
                Let&apos;s check all your widgets on the same place!
              </Text>
              <Box style={{ position: 'relative', alignSelf: 'flex-start', width: '100%' }}>
                <Table
                  actionColumn={renderActionMenu}
                  columns={getWidgetsColumns()}
                  data={getWidgetsData(widgets || [])}
                  headerBg={colors['irisBlue']}
                  noData={{
                    title: 'No Widgets',
                    subtitle: 'Currently there are no widgets connected to this account.',
                  }}
                  sx={{ overflow: 'visible' }}
                />
              </Box>
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
      {showShareWidgetModal && (
        <Modal
          title={'Sharing Options'}
          titleColor={colors['irisBlue']}
          width={'m'}
          showCloseButton
          showCancelButton
          onHide={() => setShowShareWidgetModal(false)}
          children={
            <FlexLayout
              flexDirection={'column'}
              alignItems={'flex-start'}
              space={4}
              sx={{ width: '100%' }}
            >
              <FlexLayout
                flexDirection={'column'}
                alignItems={'flex-start'}
                space={2}
                sx={{ width: '100%' }}
              >
                <Text variant='text-xs-medium' upperCase color={'irisBlue'}>
                  Share URL
                </Text>
                <Text
                  variant='text-s-medium'
                  sx={{ textAlign: 'left' }}
                  color={'secondaryArsenic'}
                >{`${process.env.REACT_APP_WEB_URL}/${selectedWidget && selectedWidget.text}/${
                  selectedWidget && selectedWidget.uuid
                }`}</Text>
                <Button
                  text='Copy and share'
                  variant={'irisBlue'}
                  size={'standard'}
                  onClick={() =>
                    copyToClipboard(
                      `${process.env.REACT_APP_WEB_URL}/${selectedWidget && selectedWidget.text}/${
                        selectedWidget && selectedWidget.uuid
                      }`,
                    )
                  }
                />
              </FlexLayout>
              <FlexLayout
                flexDirection={'column'}
                alignItems={'flex-start'}
                space={2}
                sx={{ width: '100%' }}
              >
                <Text variant='text-xs-medium' upperCase color={'irisBlue'}>
                  YSS Button
                </Text>
                {isPreviewMode ? (
                  <YSSButton
                    slug={selectedWidget?.text as string}
                    widgetId={selectedWidget?.uuid!}
                  />
                ) : (
                  <FlexLayout sx={{ maxHeight: '100px', overflowY: 'scroll' }}>
                    <CopyBlock
                      language='html'
                      text={renderToStaticMarkup(
                        <YSSButton
                          slug={selectedWidget?.text as string}
                          widgetId={selectedWidget?.uuid!}
                        />,
                      )}
                      theme={atomOneLight}
                      wrapLines
                    />
                  </FlexLayout>
                )}
                <Text variant='text-xs-medium' upperCase color={'irisBlue'}>
                  YSS Widget
                </Text>
                {isPreviewMode ? (
                  <YSSWidget slug={selectedWidget?.text as string} widget={selectedWidget!} />
                ) : (
                  <FlexLayout sx={{ maxHeight: '260px', overflowY: 'scroll' }}>
                    <CopyBlock
                      language='html'
                      text={renderToStaticMarkup(
                        <YSSWidget
                          slug={selectedWidget?.text as string}
                          widget={selectedWidget!}
                        />,
                      )}
                      theme={atomOneLight}
                      wrapLines
                    />
                  </FlexLayout>
                )}
              </FlexLayout>
              <Button
                variant={'irisBlue'}
                size={'standard'}
                onClick={() => setIsPreviewMode(!isPreviewMode)}
                text={isPreviewMode ? 'Show Code' : 'Preview'}
              />
            </FlexLayout>
          }
        />
      )}
    </AdminPageLayout>
  );
};

export default UserWidgets;
