export const radii = {
  circle: '50%',
  l: '12px',
  m: '8px',
  s: '6px',
  xl: '16px',
  xs: '4px',
  xxl: '20px',
  xxxl: '24px',
  xxxxl: '40px',
  xxxxxl: '70px',
  xxxxxxl: '200px',
};
