import React from 'react';
import { Box, colors, FlexLayout, GridLayout, Icon, Link, Text, useScreenType } from '../ui';
import { CTABlock } from '.';

const Footer = () => {
  const { isDesktop, isTablet, isMobile } = useScreenType();

  const FooterLogoAndCopy = (
    <FlexLayout
      flexDirection={'column'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      space={3}
      sx={{ width: '100%', minWidth: '360px', maxWidth: '400px' }}
    >
      <Box sx={{ justifySelf: 'flex-start' }}>
        <Link href={'/'}>
          <Icon icon='yss' height={'54px'} width={'180px'} />
        </Link>
      </Box>
      <Text
        variant={'text-m-regular'}
        color={colors['secondaryArsenic']}
        sx={{
          textAlign: isDesktop() ? 'left' : 'center',
          width: '100%',
          alignSelf: 'center',
          lineHeight: '24px',
        }}
      >
        Revolutionize your dental lead generation efforts with our advanced technology driven by
        machine learning.
      </Text>
    </FlexLayout>
  );
  return (
    <FlexLayout
      alignItems={'center'}
      backgroundColor={'whiteSmoke'}
      justifyContent={'center'}
      sx={{
        background: 'white',
        bottom: 0,
        left: 0,
        p: isDesktop() ? '60px' : isMobile() ? '10px' : '80px',
        zIndex: 100,
        marginTop: 'auto',
      }}
    >
      <FlexLayout
        flexDirection={'column'}
        alignItems={'center'}
        sx={{ width: '100%', maxWidth: '1280px' }}
      >
        {isDesktop() ? (
          <GridLayout
            gridTemplateColumns={'repeat(4, 1fr)'}
            gap={4}
            sx={{
              p: isDesktop() ? '20px' : isMobile() ? '10px' : '80px',
              mb: '80px',
              width: '100%',
            }}
          >
            {FooterLogoAndCopy}
            <CTABlock />
          </GridLayout>
        ) : isTablet() ? (
          <Box>
            <GridLayout
              gridTemplateColumns={'repeat(4, 1fr)'}
              gap={4}
              sx={{ p: '20px', mb: '80px' }}
            >
              <CTABlock />
            </GridLayout>
            {FooterLogoAndCopy}
          </Box>
        ) : (
          <Box>
            <GridLayout
              gridTemplateColumns={'repeat(2, 1fr)'}
              gap={4}
              sx={{ p: '4px', mb: '80px', width: '100%' }}
            >
              <CTABlock />
            </GridLayout>
            {FooterLogoAndCopy}
          </Box>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

export default Footer;
