import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Image } from 'theme-ui';

import { ViewMode } from '../common';
import { usePhotoEditor } from '../context';
import { colors, FlexLayout, Icon, radii, useScreenType } from '../ui';
import { CompareSlider, ImageLabel, ResultLoadingIndicator } from '.';

const ResultPreview = () => {
  const { isMobile } = useScreenType();

  const { t } = useTranslation();

  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Split);

  const { isLoading, croppedImage, resultImage } = usePhotoEditor();

  return (
    <FlexLayout
      sx={{
        borderRadius: radii.xxxl,
        height: isMobile() ? 'photo-frame-mobile-height' : 'photo-frame-height',
        position: 'relative',
        width: isMobile() ? 'photo-frame-mobile-width' : 'photo-frame-width',
      }}
    >
      {isLoading && <ResultLoadingIndicator />}
      {viewMode === ViewMode.Split && !isLoading && (
        <CompareSlider imageOne={croppedImage} imageTwo={resultImage} />
      )}
      {viewMode === ViewMode.Preview && !isLoading && (
        <FlexLayout sx={{ position: 'relative' }}>
          <Image
            src={resultImage}
            sx={{
              borderRadius: radii.xxxl,
              height: 'inherit',
              objectFit: 'fill',
              width: 'inherit',
            }}
          />
          <ImageLabel
            style={{
              bottom: '32px',
              left: isMobile() ? '130px' : '200px',
            }}
            text={t('common.after')}
          />
        </FlexLayout>
      )}
      {viewMode === ViewMode.SideBySide && !isLoading && (
        <FlexLayout
          sx={{
            background: colors.black,
            borderRadius: radii.xxxl,
            height: 'inherit',
            position: 'relative',
            width: 'inherit',
          }}
        >
          <Box
            sx={{
              left: '10px',
              position: 'absolute',
              top: '10px',
              width: isMobile() ? '160px' : '260px',
            }}
          >
            <Image
              src={croppedImage}
              sx={{
                borderRadius: radii.xxl,
                height: '100%',
                objectFit: 'contain',
                width: '100%',
              }}
            />
            <ImageLabel
              style={{ bottom: '12px', left: isMobile() ? '50px' : '95px' }}
              text={t('common.before')}
            />
          </Box>
          <Box
            sx={{
              bottom: '10px',
              position: 'absolute',
              right: '10px',
              width: isMobile() ? '160px' : '260px',
            }}
          >
            <Image
              src={resultImage}
              sx={{
                borderRadius: radii.xxl,
                height: '100%',
                objectFit: 'contain',
                width: '100%',
              }}
            />
            <ImageLabel
              style={{ bottom: '12px', left: isMobile() ? '50px' : '95px' }}
              text={t('common.after')}
            />
          </Box>
        </FlexLayout>
      )}
      {!isLoading && (
        <FlexLayout
          alignItems='center'
          justifyContent='space-around'
          sx={{
            backgroundColor: colors.black06,
            borderRadius: radii.xxxxxl,
            height: '52px',
            left: '20px',
            position: 'absolute',
            top: '20px',
            width: '144px',
          }}
        >
          {Object.values(ViewMode).map((mode) => {
            return (
              <FlexLayout
                alignItems='center'
                justifyContent='center'
                key={mode}
                onClick={() => setViewMode(mode)}
                sx={{
                  backgroundColor: viewMode === mode && colors.white,
                  borderRadius: radii.circle,
                  height: '40px',
                  opacity: viewMode !== mode && 0.5,
                  width: '40px',
                }}
              >
                <Icon color={viewMode !== mode ? colors.white : colors.black} icon={mode} />
              </FlexLayout>
            );
          })}
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

export default ResultPreview;
