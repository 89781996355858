import React, { useEffect, useRef } from 'react';

import { FlexLayout, Icon, Text } from '..';
import { useOnClickOutside } from '../../../common';
import { ModalProps } from '../../props';
import { colors } from '../../theme';

export const widths = {
  s: '560px',
  m: '800px',
  l: '945px',
  max: '100%',
};

const Modal: React.FC<ModalProps> = ({
  actionButtons,
  children,
  showCancelButton = true,
  showCloseButton = false,
  title,
  width = 's',
  onHide,
  icon,
  centerItems = true,
  titleColor = 'red',
  p = 8,
}) => {
  const ref: any = useRef();
  useOnClickOutside(ref, onHide as () => void);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  return (
    <FlexLayout
      alignItems='center'
      bg={colors['black03']}
      justifyContent='center'
      sx={{
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 'modal',
      }}
    >
      <FlexLayout flexDirection='column'>
        {showCloseButton && (
          <FlexLayout
            alignItems='center'
            color={'white'}
            justifyContent='flex-end'
            mb='2'
            onClick={onHide}
            sx={{ cursor: 'pointer' }}
          >
            <Icon icon={'close'} mr='2' size='m' />
            <Text variant={'text-m-medium'}>Close</Text>
          </FlexLayout>
        )}
        <FlexLayout
          alignItems={centerItems ? 'center' : 'flex-start'}
          bg={'white'}
          flexDirection='column'
          p={p}
          ref={ref}
          sx={{
            borderRadius: 'm',
            height: 'fit-content',
            textAlign: 'center',
            width: widths[width],
          }}
        >
          <FlexLayout alignItems={'center'} space={2}>
            {icon && <Icon icon={icon} size={'l'} />}
            {title && (
              <Text color={titleColor} variant={'text-xl-medium'}>
                {title}
              </Text>
            )}
          </FlexLayout>
          {children}
          {(showCancelButton || actionButtons) && (
            <FlexLayout
              alignItems='center'
              flexDirection={'row'}
              justifyContent='space-between'
              space={6}
            >
              {actionButtons && actionButtons}
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export default Modal;
