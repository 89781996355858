import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../store';
import { Procedures } from '../common';
import { usePhotoEditor } from '../context';
import { Button, colors, FlexLayout, radii, useScreenType } from '../ui';
import { AnalyticsEventNames, trackAnalyticsEvent } from '../utils';

const HeaderProcedureTabs = () => {
  const { isMobile } = useScreenType();
  const { onChangeProcedure, procedure } = usePhotoEditor();

  const theme = useSelector((state: RootState) => state.widget.widget.user.theme);
  const availableProcedures = useSelector((state: RootState) => state.widget.widget.simulation);
  const widget = useSelector((state: RootState) => state.widget.widget);

  const { t } = useTranslation();

  const onTabClick = (proc: Procedures) => {
    onChangeProcedure(proc);

    if (Object.keys(widget).length > 0) {
      trackAnalyticsEvent(AnalyticsEventNames.SimulationSelected, {
        customerID: widget.user.uuid,
        customerName: widget.user?.clinicName,
        customerType: widget.user.type,
        simulationName: procedure,
      });
    }
  };

  return (
    <FlexLayout alignItems='center' justifyContent='center' sx={{ width: '80%' }}>
      <FlexLayout
        alignItems='center'
        px={2}
        sx={{
          backgroundColor: theme.foregroundColor,
          borderRadius: radii.xxxxxxl,
          height: isMobile() ? '44px' : '52px',
        }}
      >
        {Object.values(Procedures).map((p: Procedures, index: number) => {
          return (
            <Button
              backgroundColor={procedure === p ? colors.white : theme.foregroundColor}
              isDisabled={!availableProcedures.includes(p)}
              key={index}
              onClick={() => onTabClick(p)}
              size={isMobile() ? 'xs' : 'm'}
              text={t(`common.${p.toLowerCase()}`)}
              variant='transparent'
            />
          );
        })}
      </FlexLayout>
    </FlexLayout>
  );
};

export default HeaderProcedureTabs;
