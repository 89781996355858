import React, { useContext, useMemo } from 'react';
import { FlexLayout, Icon } from '../ui/components';
import { colors, IconType } from '../ui/theme';
import { UserInfoBox } from '.';
import { AuthContext } from '../context/auth';
import { AdminSidebarItem } from './AdminSidebarItem';

const AdminSidebar = () => {
  const { user } = useContext(AuthContext);

  const items = useMemo(
    () => [
      {
        name: 'Your Dashboard',
        link: `/admin/users/${user?.uuid}/dashboard`,
        icon: 'menu' as IconType,
      },
      {
        name: 'Widgets',
        link: `/admin/users/${user?.uuid}/widgets`,
        icon: 'duplicate' as IconType,
      },
      {
        name: 'Appointments',
        link: `/admin/users/${user?.uuid}/appointments`,
        icon: 'chair' as IconType,
      },
      {
        name: 'Settings',
        link: `/admin/users/${user?.uuid}/settings`,
        icon: 'settings' as IconType,
      },
      {
        name: 'Subscriptions',
        link: `/admin/users/${user?.uuid}/subscriptions`,
        icon: 'card' as IconType,
      },
    ],
    [user],
  );

  return (
    <FlexLayout
      alignItems={'flex-start'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      backgroundColor={colors['white']}
      sx={{
        position: 'relative',
        width: '300px',
        height: '100%',
        boxShadow: '0 0 35px 0 rgba(154,161,171,0.15)',
        borderRight: `1px solid ${colors['black008']}`,
        p: '24px',
      }}
    >
      <FlexLayout
        alignItems={'flex-start'}
        flexDirection={'column'}
        space={4}
        sx={{ width: '100%', height: '100%' }}
      >
        <FlexLayout sx={{ width: '100%' }}>
          <Icon icon='yss' height={'60px'} width={'160px'} />
        </FlexLayout>
        <FlexLayout
          sx={{
            height: '1px',
            width: '262px',
            background:
              'linear-gradient(90deg, rgb(15, 204, 195) 28.65%, rgba(74, 49, 117, 0) 100%)',
          }}
        />
        <FlexLayout
          alignItems={'center'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          space={2}
          sx={{ width: '100%' }}
        >
          {items.map((item, index) => (
            <AdminSidebarItem key={index} item={item} />
          ))}
        </FlexLayout>
        <UserInfoBox />
      </FlexLayout>
    </FlexLayout>
  );
};

export default AdminSidebar;
