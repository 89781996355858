import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { findWidgetDetail, findWidgetDetailSuccess } from '../apis';
import { RootState } from '../store';
import braces from '../ui/assets/images/braces.jpg';
import veneers from '../ui/assets/images/veneers.jpg';
import whitening from '../ui/assets/images/whitening.jpg';
import { Procedures } from '../common';
import { useAnalyticsOnLoad, useIsBlissWidget } from '../hooks';
import { Card } from '../pages-components';
import { Box, colors, Header, FlexLayout, IconType, Text, TextIcon, useScreenType } from '../ui';
import { AnalyticsEventNames } from '../utils';

const WidgetHome = () => {
  const { t } = useTranslation();

  const data: { text: string; icon: IconType }[] = [
    { icon: 'smile', text: t('homePage.info1') },
    { icon: 'settings2', text: t('homePage.info2') },
    { icon: 'forward', text: t('homePage.info3') },
  ];
  const { isMobile } = useScreenType();
  const { widgetId, slug } = useParams();
  const widget = useSelector((state: RootState) => state.widget.widget);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useAnalyticsOnLoad(AnalyticsEventNames.SimulationSelectorOpened);

  const isBlissWidget = useIsBlissWidget();

  useEffect(() => {
    if (isBlissWidget) {
      navigate(`/${slug}/${widgetId}/editor`);
    }
  }, [isBlissWidget, slug, widgetId]);

  useEffect(() => {
    if (slug && widgetId) {
      findWidgetDetail(widgetId).then((res) => {
        if (res.data.text === slug) {
          dispatch(findWidgetDetailSuccess(res.data));
        } else {
          navigate('/');
        }
      });
    } else {
      navigate('/');
    }
  }, [widgetId, slug]);

  return (
    <>
      {widget.user && (
        <Box
          sx={{
            backgroundColor: widget.user.theme.backgroundColor,
            minHeight: '100vh',
          }}
        >
          <FlexLayout flexDirection='column' px={[5, 8]} space={[0, 6]}>
            <Header icon='arrowLeft' text={t('homePage.backBtnText')} />
            <FlexLayout alignItems='center' flexDirection='column'>
              <FlexLayout flexDirection='column' space={7} sx={{ width: 'fit-content' }}>
                <FlexLayout flexWrap='wrap' justifyContent='space-between' space={6}>
                  <Box sx={{ width: !isMobile() && 'title-size' }}>
                    <Text
                      color={colors.black}
                      variant={isMobile() ? 'text-xxl-medium' : 'text-xxxl-medium'}
                    >
                      {t('homePage.title')}
                    </Text>
                  </Box>
                  <FlexLayout
                    alignItems='flex-start'
                    flexDirection='column'
                    justifyContent='center'
                    space={3}
                  >
                    {data.map((content, index) => (
                      <TextIcon
                        icon={content.icon}
                        iconSize='m'
                        key={index}
                        text={content.text}
                        textVariant='text-s-regular'
                      />
                    ))}
                  </FlexLayout>
                </FlexLayout>
                <FlexLayout
                  alignItems='center'
                  flexDirection={['column', 'row']}
                  flexWrap={['nowrap', 'wrap']}
                  justifyContent={['center', 'flex-start']}
                  space={5}
                  sx={{ width: 'fill-available' }}
                >
                  <Card
                    backgroundImage={whitening}
                    description={t('homePage.whiteningCardDesc')}
                    isDisabled={!widget.simulation.includes(Procedures.Whitening)}
                    procedure='Whitening'
                  />
                  <Card
                    backgroundImage={veneers}
                    description={t('homePage.veneersCardDesc')}
                    isDisabled={!widget.simulation.includes(Procedures.Veneers)}
                    procedure='Veneers'
                  />
                  <Card
                    backgroundImage={braces}
                    description={t('homePage.bracesCardDesc')}
                    isDisabled={!widget.simulation.includes(Procedures.Braces)}
                    procedure='Braces'
                  />
                </FlexLayout>
                {widget.showYSS && (
                  <TextIcon
                    color={colors.black07}
                    icon='subtract'
                    iconSize='m'
                    justifyContent={isMobile() ? 'center' : 'flex-start'}
                    mb={6}
                    space={2}
                    text='Your Social Smile'
                    textVariant='text-s-medium'
                  />
                )}
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </Box>
      )}
    </>
  );
};

export default WidgetHome;
