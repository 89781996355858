import React from 'react';

import { Box, colors, FlexLayout, radii } from '../ui';

interface Props {
  color: string;
  isSelected: boolean;
  onClick: () => void;
}

const BracesColorButton = (props: Props) => {
  const { color, onClick, isSelected } = props;

  return (
    <FlexLayout
      alignItems='center'
      justifyContent='center'
      onClick={onClick}
      sx={{
        border: '1px solid',
        borderColor: isSelected ? 'black' : 'transparent',
        borderRadius: radii.xxxxxxl,
        cursor: 'pointer',
        height: '40px',
        width: '40px',
      }}
    >
      <Box
        sx={{
          backgroundColor: color,
          border: '1px solid',
          borderColor: colors.black03,
          borderRadius: radii.xxxxxxl,
          height: '32px',
          width: '32px',
        }}
      />
    </FlexLayout>
  );
};

export default BracesColorButton;
