import React, { useContext } from 'react';
import { useNavigate } from 'react-router';

import { AuthContext } from '../context';
import { Routes } from '../routes/routes';
import { colors, FlexLayout, Icon, radii, Text } from '../ui';

const UserInfoBox: React.FC = () => {
  const { user, signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleOnSignOut = () => {
    signOut();
    navigate(Routes.Login);
  };

  return (
    <FlexLayout
      flexDirection={'column'}
      alignItems={'flex-start'}
      justifyContent={'flex-end'}
      sx={{ width: '100%', height: '100%' }}
    >
      <FlexLayout
        alignItems={'flex-start'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        sx={{ justifySelf: 'flex-end', width: '100%' }}
      >
        <FlexLayout
          alignItems={'center'}
          flexDirection={'row'}
          space={2}
          sx={{
            width: '100%',
            background: 'linear-gradient(90deg, transparent 0%, rgba(15, 204, 195, 0.1) 100%)',
            borderRadius: radii.m,
            p: '8px 12px',
          }}
        >
          <Icon icon='smileAvatar' color={'black04'} size='l' />
          <FlexLayout alignItems={'flex-start'} flexDirection={'column'}>
            <Text
              variant={'text-m-bold'}
              color={colors['black04']}
              sx={{ wordWrap: 'break-word', maxWidth: '100%' }}
            >
              {user?.clinicName}
            </Text>
            <Text
              variant={'text-s-regular'}
              color={colors['black04']}
              sx={{ wordWrap: 'break-word', maxWidth: '100%' }}
            >
              {user?.email}
            </Text>
            <FlexLayout
              alignItems={'center'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              onClick={() => handleOnSignOut()}
              sx={{ cursor: 'pointer', width: '100%' }}
            >
              <Text variant={'text-s-bold'} color={colors['irisBlue']}>
                Log out
              </Text>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export default UserInfoBox;
