export const widgetNameRegex = new RegExp(/^[a-zA-Z0-9\-_]+$/); // only characters, numbers, - and _
export const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const passwordRegex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/);
export const phoneNumberRegex = new RegExp(/^[0-9\s+]+$/);
export const HttpsURLRegex = new RegExp(
  `https?://(?:www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^s]{2,}`,
);
