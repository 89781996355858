import React from 'react';
import { Box, Button, FlexLayout, Text } from '../ui/components';
import { radii } from '../ui/theme';
import { CardElement } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#00000099',
      fontFamily: '16px',
      fontWeight: 'Euclid Regular',
      lineHeight: '20px',
      fontSmoothing: 'red',
      ':-webkit-autofill': {
        color: '#00000099',
      },
      '::placeholder': {
        color: '#00000099',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#00000099',
    },
  },
};

export interface SubscriptionCardProps {
  setIsCardElementOpened: React.Dispatch<React.SetStateAction<boolean>>;
  stripe: Stripe;
  handleCreateSubscription: () => Promise<void>;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  setIsCardElementOpened,
  stripe,
  handleCreateSubscription,
}) => {
  return (
    <Box
      bg={'white'}
      py={4}
      px={4}
      sx={{
        width: '600px',
        borderRadius: radii.m,
        alignSelf: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      }}
    >
      <FlexLayout alignItems={'center'} justifyContent={'flex-start'} space={2}>
        <Text variant={'text-m-regular'} color={'black06'}>
          Enter your card details
        </Text>
      </FlexLayout>
      <CardElement
        options={{
          ...CARD_OPTIONS,
          disableLink: true,
          hidePostalCode: true,
        }}
      />
      <FlexLayout
        alignItems={'flex-start'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        space={3}
      >
        <FlexLayout alignItems={'center'} justifyContent={'flex-start'} space={2}>
          <Button
            isDisabled={!(stripe as Stripe)}
            onClick={handleCreateSubscription}
            text={'Subscribe'}
            variant={'irisBlue'}
          />
          <Button onClick={() => setIsCardElementOpened(false)} text={'Back'} variant={'white'} />
        </FlexLayout>
        <Text variant={'text-xs-regular'} color={'black06'}>
          It is very simple, once card details are filled and click Subscribe button, a popup modal
          would be opened confirming your subscription to Your Social Smile.
        </Text>
      </FlexLayout>
    </Box>
  );
};

export default SubscriptionCard;
