import React from 'react';

import { LanguageSelect } from '../../../pages-components';
import { useScreenType } from '../../dom';
import { HeaderProps } from '../../props';
import { FlexLayout, Icon, Text } from '..';

export const Header = (props: HeaderProps) => {
  const { isMobile } = useScreenType();

  const { text, icon, onBackAction, children } = props;

  return (
    <FlexLayout alignItems='center' justifyContent='space-between'>
      <FlexLayout justifyContent='flex-start' py={3}>
        <FlexLayout
          alignItems='center'
          justifyContent='flex-start'
          onClick={onBackAction}
          sx={{ cursor: 'pointer' }}
        >
          <Icon icon={icon} size='xl' />
          <Text variant='text-m-regular'>{isMobile() ? '' : text}</Text>
        </FlexLayout>
      </FlexLayout>
      {children}
      <LanguageSelect />
    </FlexLayout>
  );
};

export default Header;
