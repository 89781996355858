import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';
import Webcam from 'react-webcam';
import { Box, Input, Label } from 'theme-ui';

import { useIsBlissWidget } from '../hooks';
import { Button, colors, FlexLayout, radii, Icon, Text, useScreenType } from '../ui';
import { overlayStyles } from './photo-editor.styles';

interface Props {
  setSelectedImage: (image: File | string) => void;
  onTryAgain: () => void;
  onPhotoUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const WebCamera = (props: Props) => {
  const { setSelectedImage, onTryAgain, onPhotoUpload } = props;

  const { t } = useTranslation();

  const isBlissWidget = useIsBlissWidget();

  const { isMobile } = useScreenType();
  const webcamRef = React.useRef<Webcam>(null);
  const [noCamera, setNoCamera] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setSelectedImage(imageSrc!);
  }, [webcamRef]);

  const loadWebCamera = () => {
    if (noCamera) {
      return (
        <FlexLayout
          alignItems='center'
          flexDirection='column'
          justifyContent='center'
          px={9}
          py={11}
          sx={{
            backgroundColor: colors.black,
            borderRadius: radii.xxxl,
            height: '100%',
            width: '100%',
          }}
        >
          <FlexLayout alignItems='center' flexDirection='column' justifyContent='center' space={2}>
            <Icon icon='noCamera' />
            <Text color={colors.white} variant='text-l-regular'>
              {t('photoEditor.noCameraTitle')}
            </Text>
            <Text color={colors.white07} sx={{ textAlign: 'center' }} variant='text-m-regular'>
              {t('photoEditor.noCameraMessage')}
            </Text>
            <FlexLayout alignItems='center' flexDirection='column' mt={8} space={4}>
              <Box sx={{ width: '270px' }}>
                <Button
                  iconLeft='camera'
                  onClick={onTryAgain}
                  size='l'
                  text={t('common.tryAgain')}
                  variant='secondary'
                  width='100%'
                />
              </Box>

              <Label sx={{ width: 'fit-content' }}>
                <Input
                  id='file-input'
                  name='ImageStyle'
                  onChange={onPhotoUpload}
                  sx={{ display: 'none' }}
                  type='file'
                />
                <Text color={colors.white} sx={{ cursor: 'pointer' }} variant='text-m-regular'>
                  {t('photoEditor.uploadPhotoBtnText')}
                </Text>
              </Label>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      );
    } else {
      return (
        <FlexLayout
          sx={{
            height: 'inherit',
            position: 'relative',
            width: 'inherit',
          }}
        >
          {isLoading && (
            <FlexLayout
              alignItems='center'
              justifyContent='center'
              sx={{
                background: isBlissWidget ? colors.blissBlack : colors.black04,
                borderRadius: radii.xxxl,
                height: '128px',
                left: '170px',
                position: 'absolute',
                top: '170px',
                width: '128px',
              }}
            >
              <TailSpin
                ariaLabel='tail-spin-loading'
                color={isBlissWidget ? colors.blissCyan : colors.white}
                height='80'
                radius='1'
                visible={isLoading}
                width='80'
                wrapperClass=''
                wrapperStyle={{}}
              />
            </FlexLayout>
          )}

          <Webcam
            audio={false}
            forceScreenshotSourceSize
            frameBorder={50}
            mirrored={true}
            onLoadedData={() => setIsLoading(false)}
            onUserMediaError={() => {
              setNoCamera(true);
              setIsLoading(false);
            }}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            style={{
              height: '636px',
              objectFit: 'cover',
              overflow: 'hidden',
            }}
            videoConstraints={{
              height: 1200,
            }}
          />
          {!isLoading && (
            <>
              <FlexLayout
                justifyContent='center'
                sx={{
                  bottom: isMobile() ? 16 : 28,
                  left: 0,
                  position: 'absolute',
                  width: '100%',
                  zIndex: '2',
                }}
              >
                <FlexLayout
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    background: 'transparent',
                    border: `2px solid ${colors.white}`,
                    borderRadius: radii.circle,
                    height: '70px',
                    width: '70px',
                  }}
                >
                  <FlexLayout
                    onClick={capture}
                    sx={{
                      background: colors.white,
                      borderRadius: radii.circle,
                      height: '60px',
                      width: '60px',
                    }}
                  />
                </FlexLayout>
              </FlexLayout>
            </>
          )}
        </FlexLayout>
      );
    }
  };

  return (
    <Box
      sx={{
        height: 'photo-frame-height',
        width: 'photo-frame-width',
        ...overlayStyles,
      }}
    >
      {loadWebCamera()}
    </Box>
  );
};

export default WebCamera;
