import React from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

import { defaultMaxFileSize } from '../helpers';
import { withFormController } from '../hocs/wihFormController';
import { Button, colors, FlexLayout, Text } from '../ui';
import { ButtonVariantStyle } from '../ui/components/Button/Button';

interface Props extends ButtonVariantStyle {
  title: string;
  subTitle?: string;
  handleDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  noDrag?: boolean;
  maxFiles?: number;
  maxSize?: number;
}

const ImageUploadButton = ({
  title,
  handleDrop,
  noDrag,
  maxFiles,
  maxSize,
  subTitle,
  ...rest
}: Props) => {
  const maxFilesValue = () => maxFiles || 1;
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    maxSize: maxSize || defaultMaxFileSize,
    maxFiles: maxFilesValue(),
    multiple: maxFilesValue() === 1 ? false : true,
    onDrop: handleDrop,
    noDrag,
  });

  return (
    <FlexLayout {...getRootProps({ className: 'dropzone' })} flexDirection='column'>
      <input {...getInputProps()} />
      <Button
        type='button'
        size={'standard'}
        backgroundColor={colors['irisBlue']}
        width={'100%'}
        {...rest}
        text={title}
      />
      {subTitle && <Text variant={'text-xs-medium'}>{subTitle}</Text>}
    </FlexLayout>
  );
};

export default withFormController(ImageUploadButton);
