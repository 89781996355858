import {
  ConfirmSignup,
  EditAppointment,
  EditWidget,
  ForgotPassword,
  WidgetHome,
  Home,
  NotFound,
  PhotoEditor,
  ResetPassword,
  Login,
  SignUp,
  UserAppointments,
  UserDashboard,
  UserSettings,
  UserSubscriptions,
  UserWidgets,
  ServerError,
} from '../pages';
import SdkComponentTeethWhitening from '../sdkDemo/SdkComponentTeethWhitening';
import SdkComponentTeethWhiteningVita from '../sdkDemo/SdkComponentTeethWhiteningVita';
import SdkComponentBotox from '../sdkDemo/SdkComponentBotox';

export type RouteType = {
  path: string;
  component: React.FC;
  enabled?: boolean;
};

export type HeaderType = {
  name: string;
  link: string;
};

export const headerItems: HeaderType[] = [
  { name: 'Home', link: 'https://www.yoursocialsmile.com/' },
  { name: 'About', link: 'https://www.yoursocialsmile.com/about' },
  { name: 'Products', link: 'https://www.yoursocialsmile.com/products' },
];

export const Routes = {
  Appointments: '/admin/users/:userId/appointments',
  ConfirmSignup: '/confirm-signup',
  Dashboard: '/admin/users/:userId/dashboard',
  EditAppointment: '/admin/users/:userId/appointments/:appointmentId/edit',
  EditWidget: '/admin/users/:userId/widgets/:widgetId/edit',
  ForgotPassword: '/forgot-password',
  Login: '/login',
  PhotoEditor: '/:slug/:widgetId/editor',
  ResetPassword: '/reset-password',
  Settings: '/admin/users/:userId/settings',
  SignUp: 'sign-up',
  Subscriptions: '/admin/users/:userId/subscriptions',
  WidgetHome: '/:slug/:widgetId',
  Widgets: '/admin/users/:userId/widgets',
  NotFound: '*',
  ServerError: '/500',
  Home: '/',
};

const defaultRoute: Partial<RouteType> = {
  enabled: true,
};

export const componentRoutes: RouteType[] = [
  {
    component: SdkComponentTeethWhitening,
    path: '/crest-whitening',
    ...defaultRoute,
  },
  {
    component: SdkComponentTeethWhiteningVita,
    path: '/philips-whitening-demo',
    ...defaultRoute,
  },
  {
    component: SdkComponentTeethWhitening,
    path: '/philips-whitening',
    ...defaultRoute,
  },
  {
    component: SdkComponentTeethWhitening,
    path: '/flaesh-whitening',
    ...defaultRoute,
  },
  {
    component: SdkComponentBotox,
    path: '/lip-filler-demo',
    ...defaultRoute,
  },
  {
    component: SdkComponentTeethWhitening,
    path: '/whitening-demo',
    ...defaultRoute,
  },
  {
    component: WidgetHome,
    path: Routes.WidgetHome,
    ...defaultRoute,
  },
  {
    component: Home,
    path: Routes.Home,
    enabled: process.env.REACT_APP_ENABLE_HOME_HELPER_PAGE === 'true',
  },
  {
    component: PhotoEditor,
    path: Routes.PhotoEditor,
    ...defaultRoute,
  },
  {
    component: ConfirmSignup,
    path: Routes.ConfirmSignup,
    ...defaultRoute,
  },
  {
    component: Login,
    path: Routes.Login,
    ...defaultRoute,
  },
  {
    component: SignUp,
    path: Routes.SignUp,
    ...defaultRoute,
  },
  {
    component: ForgotPassword,
    path: Routes.ForgotPassword,
    ...defaultRoute,
  },
  {
    component: ResetPassword,
    path: Routes.ResetPassword,
    ...defaultRoute,
  },
  {
    component: NotFound,
    path: Routes.NotFound,
    ...defaultRoute,
  },
  {
    component: ServerError,
    path: Routes.ServerError,
    ...defaultRoute,
  },
  { component: UserAppointments, path: Routes.Appointments, ...defaultRoute },
  { component: EditAppointment, path: Routes.EditAppointment, ...defaultRoute },
  { component: UserDashboard, path: Routes.Dashboard, ...defaultRoute },
  { component: UserSettings, path: Routes.Settings, ...defaultRoute },
  { component: UserWidgets, path: Routes.Widgets, ...defaultRoute },
  { component: EditWidget, path: Routes.EditWidget, ...defaultRoute },
  { component: UserSubscriptions, path: Routes.Subscriptions, ...defaultRoute },
];
