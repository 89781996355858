import '../pages-components/WidgetCodeSnippet.css';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';

import { WidgetType, editWidget, findWidgetDetail } from '../apis';
import { AuthContext } from '../context';
import { colors, FlexLayout, Icon, Input, Text, Button, Checkbox, showToast } from '../ui';
import { AdminPageLayout, AdminPageTitle } from '../pages-components';
import { minLengthUtil } from '../utils';
import { Procedures } from '../common';
import { ToastType } from '../enums';
import { widgetNameRegex } from '../helpers';

interface EditUserWidgetValues {
  widgetName: string;
  braces: boolean;
  veneers: boolean;
  whitening: boolean;
}

const EditWidget = () => {
  const { user } = useContext(AuthContext);
  const { control, formState, handleSubmit, setValue } = useForm<EditUserWidgetValues>({
    mode: 'all',
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [widget, setWidget] = useState<WidgetType>();
  const [braces, setBraces] = useState<boolean>(false);
  const [veneers, setVeneers] = useState<boolean>(false);
  const [whitening, setWhitening] = useState<boolean>(false);
  const { widgetId } = useParams();
  const navigate = useNavigate();

  useQuery(['findWidgetDetail', widgetId], () => findWidgetDetail(widgetId as string), {
    onSuccess: ({ data }) => {
      setWidget(data);
    },
    onError: () => {
      showToast({
        title: 'Something went wrong. Please try again later.',
        toastType: ToastType.ERROR,
      });
    },
    onSettled: () => {
      setIsLoading(false);
    },
    enabled: !!widgetId,
    retry: false,
  });

  useEffect(() => {
    if (widget?.simulation) {
      setValue(
        'whitening',
        widget &&
          widget?.simulation &&
          (widget?.simulation.some((s: string) => s === 'WHITENING') as boolean),
      );
      setValue(
        'braces',
        widget &&
          widget?.simulation &&
          (widget?.simulation.some((s: string) => s === 'BRACES') as boolean),
      );
      setValue(
        'veneers',
        widget &&
          widget?.simulation &&
          (widget?.simulation.some((s: string) => s === 'VENEERS') as boolean),
      );
      setWhitening(widget?.simulation.some((s) => s === 'WHITENING'));
      setBraces(widget?.simulation.some((s) => s === 'BRACES'));
      setVeneers(widget?.simulation.some((s) => s === 'VENEERS'));
    }
  }, [widget]);

  const { mutate } = useMutation(editWidget, {
    onError: () => {
      showToast({ title: 'Something went wrong. Please try again.', toastType: ToastType.ERROR });
    },
    onSuccess: () => {
      showToast({ title: 'Changes saved successfully.', toastType: ToastType.INFO });
    },
    onSettled: () => {
      navigate(-1);
    },
  });

  const onSubmit = async (values: EditUserWidgetValues) => {
    const simulation = [];
    if (values.braces === true) simulation.push(Procedures.Braces);
    if (values.veneers === true) simulation.push(Procedures.Veneers);
    if (values.whitening === true) simulation.push(Procedures.Whitening);
    mutate({
      widgetId: widget?.uuid as string,
      widget: {
        simulation,
        userUuid: user?.uuid as string,
        active: true,
        text: values.widgetName,
        showTextBlock: widget?.showTextBlock as boolean,
        showYSS: widget?.showYSS as boolean,
      },
    });
  };

  return (
    <AdminPageLayout>
      <FlexLayout
        flexDirection={'column'}
        alignItems={'flex-start'}
        sx={{ width: '100%', height: '100%' }}
      >
        <AdminPageTitle backIcon title={'Manage Widget'} icon={'duplicate'} />
        <FlexLayout alignItems={'center'} sx={{ width: '100%', height: '100%' }}>
          {isLoading ? (
            <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
              <Icon icon='loading' size='xl' />
            </FlexLayout>
          ) : (
            <FlexLayout
              as='form'
              alignItems={'flex-start'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              space={4}
              py={4}
              sx={{ width: '100%', height: '100%' }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FlexLayout alignItems={'flex-start'} flexDirection={'column'} space={4}>
                <Controller
                  control={control as any}
                  defaultValue={widget?.text}
                  name='widgetName'
                  rules={{
                    pattern: {
                      value: widgetNameRegex,
                      message: 'Widget name must only contain characters, numbers, - and _',
                    },
                    minLength: minLengthUtil(2),
                  }}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        onChange={(e) => field.onChange(e.target.value.replace(/\s/g, '-'))}
                        error={!!(formState.dirtyFields.widgetName && formState.errors.widgetName)}
                        label={'Widget Name'}
                        labelIcon={'exclamationMark'}
                        message={formState.errors?.widgetName?.message}
                        placeholder={'Eg: Try out your virtual smile'}
                      />
                    );
                  }}
                />
                <FlexLayout
                  alignItems={'flex-start'}
                  flexDirection='column'
                  justifyContent='space-between'
                  space={4}
                  sx={{ width: '220px' }}
                >
                  <Text variant={'text-m-regular'} color={colors['secondaryArsenic']}>
                    Select Products*
                  </Text>
                  <Controller
                    control={control}
                    name={'braces'}
                    render={() => (
                      <Checkbox
                        checked={braces}
                        label={'Braces'}
                        onCheck={(isActive: boolean) => {
                          setBraces(isActive);
                          setValue('braces', isActive, { shouldValidate: true, shouldDirty: true });
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={'veneers'}
                    render={() => (
                      <Checkbox
                        checked={veneers}
                        label={'Veneers'}
                        onCheck={(isActive: boolean) => {
                          setVeneers(isActive);
                          setValue('veneers', isActive, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={'whitening'}
                    render={() => (
                      <Checkbox
                        checked={whitening}
                        label={'Whitening'}
                        onCheck={(isActive: boolean) => {
                          setWhitening(isActive);
                          setValue('whitening', isActive, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }}
                      />
                    )}
                  />
                </FlexLayout>
              </FlexLayout>
              <Button
                size='standard'
                text={'Save Changes'}
                variant='irisBlue'
                isDisabled={!formState.isValid || !(whitening || braces || veneers)}
                type={'submit'}
              />
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </AdminPageLayout>
  );
};

export default EditWidget;
