import React from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { useTranslation } from 'react-i18next';

import { Box, colors, FlexLayout, radii, useScreenType } from '../ui';
import { ImageLabel } from '.';

interface Props {
  imageOne?: string;
  imageTwo?: string;
}

const CompareSlider = (props: Props) => {
  const { isMobile } = useScreenType();

  const { t } = useTranslation();

  const { imageOne, imageTwo } = props;
  return (
    <ReactCompareSlider
      boundsPadding={0}
      handle={
        <div
          style={{
            background: colors.white,
            display: 'grid',
            height: '100%',
            placeContent: 'center',
            width: '4px',
          }}
        >
          <Box
            style={{
              backgroundColor: colors.white,
              borderRadius: '50%',
              cursor: 'pointer',
              height: '16px',
              width: '16px',
            }}
          />
        </div>
      }
      itemOne={
        <FlexLayout sx={{ position: 'relative' }}>
          <ReactCompareSliderImage
            alt='Image one'
            src={imageOne}
            style={{
              height: isMobile() ? 'photo-frame-mobile-height' : 'photo-frame-height',
            }}
          />
          <ImageLabel style={{ bottom: '32px', left: '10%' }} text={t('common.before')} />
        </FlexLayout>
      }
      itemTwo={
        <FlexLayout sx={{ position: 'relative' }}>
          <ReactCompareSliderImage
            alt='Image two'
            src={imageTwo}
            style={{
              height: isMobile() ? 'photo-frame-mobile-height' : 'photo-frame-mobile-height',
            }}
          />
          <ImageLabel style={{ bottom: '32px', right: '10%' }} text={t('common.after')} />
        </FlexLayout>
      }
      position={50}
      style={{
        borderRadius: radii.xxxl,
        height: 'photo-frame-mobile-height',
        width: '100%',
      }}
    />
  );
};

export default CompareSlider;
