import React from 'react';
import { Image as ThemeImage } from 'theme-ui';
import { ImageProps } from '../../props';

const Image: React.FC<ImageProps> = ({ src, alt, sx, id, width, height, onError }) => {
  return (
    <ThemeImage
      alt={alt}
      height={height}
      id={id}
      onError={onError}
      src={src}
      sx={sx}
      width={width}
    />
  );
};
export default Image;
