import React from 'react';
import { Box } from '..';
import { FlexLayoutProps } from '../../props';

const FlexLayout = React.forwardRef<HTMLDivElement, FlexLayoutProps>(
  (
    {
      sx = {},
      children,
      alignItems,
      flexBasis,
      flexDirection = 'row',
      flexGrow,
      flexShrink,
      flexWrap,
      flex,
      justifyContent,
      space,
      ...rest
    },
    ref,
  ) => {
    return (
      <Box
        as='div'
        ref={ref}
        sx={{
          ...sx,
          alignItems,
          display: 'flex',
          flex,
          flexBasis,
          flexDirection,
          flexGrow,
          flexShrink,
          flexWrap,
          gap: space,
          justifyContent,
        }}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);

export default FlexLayout;
