import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericModal } from '..';

import { useIsBlissWidget } from '../../hooks/bliss';
import { Button, FlexLayout, Text } from '../../ui';

const DisclaimerModal = () => {
  const isBlissWidget = useIsBlissWidget();

  const [isOpen, setIsOpen] = useState(!sessionStorage.getItem('disclaimer-read'));

  const { t } = useTranslation();

  const onClose = () => {
    setIsOpen(false);
    sessionStorage.setItem('disclaimer-read', 'true');
  };

  if (isOpen) {
    return (
      <GenericModal
        content={
          <FlexLayout
            alignItems='center'
            flexDirection='column'
            p={[5, 6]}
            space={4}
            sx={{
              width: '100%',
            }}
          >
            <Text
              sx={{ textAlign: 'center' }}
              variant={isBlissWidget ? 'bliss-subheading' : 'text-xl-regular'}
            >
              {t('disclaimerModal.title')}
            </Text>
            {isBlissWidget ? (
              <>
                <Text variant='bliss-text'>
                  This website&apos;s teeth whitening strip simulation is for informational purposes
                  only and results may vary based on individual differences and lifestyle habits.
                </Text>
                <Text variant='bliss-text'>
                  The simulation is not a substitute for professional advice, diagnosis, or
                  treatment.
                </Text>
                <Text variant='bliss-button'>
                  By using this website, you acknowledge that you have read and understood this
                  disclaimer.
                </Text>
              </>
            ) : (
              <>
                <Text variant='text-m-regular'>{t('disclaimerModal.text.one')}</Text>
                <Text variant='text-m-regular'>{t('disclaimerModal.text.two')}</Text>
                <Text variant='text-m-bold'>{t('disclaimerModal.text.three')}</Text>
              </>
            )}

            <Button
              onClick={onClose}
              text={t('common.ok')}
              textVariant={isBlissWidget ? 'bliss-button' : undefined}
              variant={isBlissWidget ? 'blissSecondary' : 'primary'}
            />
          </FlexLayout>
        }
        positionX='center'
        positionY='center'
        showModal={isOpen}
      />
    );
  }

  return null;
};

export default DisclaimerModal;
