import React from 'react';
import { Box, colors, FlexLayout, Icon, useScreenType } from '../ui';

export interface StepsDisplayProps {
  activeStep: number;
  totalSteps: number;
  setStepsDisplay: ({ activeStep, totalSteps }: { activeStep: number; totalSteps: number }) => void;
}

const StepsDisplay = ({ activeStep, totalSteps, setStepsDisplay }: StepsDisplayProps) => {
  const { isDesktop, isTablet, isMobile } = useScreenType();

  const getLineWidth = () => {
    if (isDesktop()) return '240px';
    else if (isTablet()) return '160px';
    else if (isMobile()) return '80px';
  };

  return (
    <FlexLayout alignItems={'center'} justifyContent={'center'}>
      {[...Array(totalSteps)].map((x, i) => {
        return (
          <FlexLayout key={i} alignItems={'center'}>
            {i + 1 < activeStep && (
              <FlexLayout
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ position: 'relative' }}
              >
                <FlexLayout
                  alignItems={'center'}
                  bg={colors['irisBlue']}
                  sx={{ borderRadius: '50%', height: '52px', width: '52px', opacity: '0.5' }}
                />
                <Icon
                  color={colors['white']}
                  icon={'check'}
                  size='l'
                  sx={{ position: 'absolute', left: '10px', top: '12px' }}
                  onClick={() => setStepsDisplay({ totalSteps, activeStep: i + 1 })}
                />
              </FlexLayout>
            )}
            {i + 1 === activeStep && (
              <FlexLayout
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ position: 'relative', borderRadius: '50%', height: '52px', width: '52px' }}
              >
                <Icon
                  color={colors['white']}
                  icon={'union'}
                  size='xxl'
                  sx={{ position: 'absolute', left: '0px', top: '0px' }}
                />
              </FlexLayout>
            )}
            {i + 1 > activeStep && (
              <FlexLayout
                alignItems={'center'}
                bg={'white'}
                justifyContent={'center'}
                sx={{
                  borderRadius: '50%',
                  height: '52px',
                  width: '52px',
                  border: `2px solid ${colors['neutral']}`,
                }}
              />
            )}

            {i < totalSteps - 1 && i >= activeStep - 1 && (
              <Box bg={colors['neutral']} sx={{ height: '2px', width: `${getLineWidth()}` }} />
            )}
            {i < totalSteps - 1 && i < activeStep - 1 && (
              <Box bg={colors['irisBlue']} sx={{ height: '2px', width: `${getLineWidth()}` }} />
            )}
          </FlexLayout>
        );
      })}
    </FlexLayout>
  );
};

export default StepsDisplay;
