import { TippyProps } from '@tippyjs/react';
import { DropdownProps } from '../../../hocs/withDropdown';
import React from 'react';

import { Tooltip } from '..';
import Dropdown from './Dropdown';

const DropDownComponent: React.FC<DropdownProps & TippyProps> = ({
  dropdownOptions,
  dropdownOffset,
  children,
  sx,
  visible = false,
  onClickOutside,
  ...rest
}) => {
  return (
    <Tooltip
      content={<Dropdown dropdownOptions={dropdownOptions} sx={{ ...sx }} {...rest} />}
      interactive={true}
      offset={dropdownOffset}
      placement='bottom-end'
      visible={visible}
      onClickOutside={onClickOutside}
    >
      {children}
    </Tooltip>
  );
};

export default DropDownComponent;
