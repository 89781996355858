import React from 'react';
import { Box, FlexLayout, IconType, Icon, radii, Text } from '../ui';

interface InfoBoxProps {
  content: string;
  icon?: IconType;
  bg?: string;
  color?: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({
  content,
  icon = 'exclamationMark',
  bg = 'irisBlue',
  color = 'white',
}) => {
  return (
    <Box>
      <FlexLayout
        alignItems={'center'}
        bg={bg}
        color={color}
        px={3}
        py={2}
        sx={{
          borderRadius: radii.m,
          width: '100%',
        }}
      >
        <Icon color={color} icon={icon} mr={1} />
        <Text variant='text-xs-regular'>{content}</Text>
      </FlexLayout>
    </Box>
  );
};

export default InfoBox;
