import React from 'react';
import { DropdownProps } from '../../../hocs/withDropdown';
import { DropdownBox } from '..';

import DropdownItem from './DropdownItem';

const Dropdown: React.FC<DropdownProps> = ({ dropdownOptions, sx, ...rest }) => {
  return (
    <DropdownBox
      sx={{
        width: 'fit-content',
        minWidth: '160px',
        alignItems: 'flex-start',
        borderRadius: '4px',
        justifyContent: 'flex-start',
        ...sx,
      }}
      {...rest}
    >
      {dropdownOptions.map(
        ({ label, subTitle, inverseColors, icon, onClick, sx, isSelected }, index, { length }) => (
          <DropdownItem
            icon={icon}
            inverseColors={inverseColors}
            isSelected={isSelected}
            key={label}
            label={label}
            subTitle={subTitle}
            sx={{
              ...sx,
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              borderBottom: index !== length - 1 ? '1px solid white' : 'none',
            }}
            onClick={onClick}
          />
        ),
      )}
    </DropdownBox>
  );
};

export default Dropdown;
