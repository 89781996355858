import Tippy from '@tippyjs/react';
import React, { useEffect, useState } from 'react';
import { Input as InputContainer, useThemeUI } from 'theme-ui';
import { FlexLayout, Icon, Text } from '..';
import { withFormController } from '../../../hocs/wihFormController';
import { ThemeInputProps } from '../../props';
import { colors, radii } from '../../theme';

const Input: React.FC<ThemeInputProps> = ({
  disabled,
  error = false,
  icon,
  label,
  message = '',
  sx,
  onIconClick,
  backgroundColor,
  tooltip,
  upperCase = false,
  ...props
}) => {
  const {
    theme: { rawColors },
  } = useThemeUI();

  const [labelColor, setLabelColor] = useState<string>(error ? 'red' : colors.black04);
  const [borderColor, setBorderColor] = useState<string>(error ? 'red' : colors.black04);

  useEffect(() => {
    setLabelColor(error ? 'red' : colors['black04']);
    setBorderColor(error ? 'red' : colors['black04']);
  }, [error]);

  const base = (
    <FlexLayout flexDirection='column' space={2} sx={{ width: '100%' }}>
      <FlexLayout flexDirection='row' justifyContent='space-between'>
        {label && (
          <FlexLayout>
            <Text color={labelColor} variant={'text-m-regular'} upperCase={upperCase}>
              {label}
            </Text>
            {/* {labelIcon && (
              <Icon
                icon={labelIcon as IconType}
                size={'s'}
                color={labelIconColor}
                tooltip={
                  <TippyContainer
                    content={
                      <FlexLayout
                        flexDirection={'row'}
                        alignItems={'center'}
                        backgroundColor={'white'}
                        space={4}
                        sx={{
                          width: '100%',
                          borderRadius: radii.m,
                          position: 'absolute',
                          left: '150px',
                          top: '-75px',
                          boxShadow: '0px 2px 17.5px 0px rgba(0, 0, 0, 0.25)',
                        }}
                      >
                        <FlexLayout
                          backgroundColor={labelIconColor}
                          p={3}
                          space={2}
                          sx={{ borderRadius: '8px 0px 0px 8px' }}
                        >
                          <Icon icon={labelIcon as IconType} size={'xl'} color={'white'} />
                        </FlexLayout>
                        <FlexLayout>
                          <Text variant={'text-xs-regular'} color={'secondaryArsenic'}>
                            A brief description or explanation of the widgets purpose or
                            functionality that appears when the user hovers over or clicks on the
                            widget.
                          </Text>
                        </FlexLayout>
                      </FlexLayout>
                    }
                  />
                } 
              />
            )}*/}
          </FlexLayout>
        )}
      </FlexLayout>
      <FlexLayout
        alignItems='center'
        justifyContent='flex-start'
        sx={{
          bg: !backgroundColor ? (disabled ? 'neutral' : 'transparent') : backgroundColor,
          borderRadius: 'm',
          width: '100%',
          maxWidth: '600px',
          position: 'relative',
        }}
      >
        <InputContainer
          disabled={disabled}
          onBlur={() => {
            setLabelColor(error ? 'red' : colors['black04']);
            setBorderColor(error ? 'red' : colors['black04']);
          }}
          onFocus={() => {
            setLabelColor(!error ? colors['irisBlue'] : 'inherit');
            setBorderColor(!error ? colors['irisBlue'] : 'inherit');
          }}
          sx={{
            padding: '20px',
            width: '600px',
            height: '64px',
            backgroundColor: 'white',
            border: error
              ? `1px solid ${colors.red}`
              : `1px solid ${borderColor || colors['black04']}`,
            borderRadius: radii.m,
            outline: 'none',
            color: `${colors['secondaryArsenic']} !important}`,
            ...sx,
          }}
          {...props}
        />
        {icon && (
          <Icon
            color={rawColors?.primary as string}
            icon={icon}
            mr={4}
            size='l'
            onClick={onIconClick}
            sx={{ position: 'absolute', right: 0 }}
          />
        )}
      </FlexLayout>
      {message && (
        <Text
          as='span'
          color={error ? 'red' : (rawColors?.secondary as string)}
          variant={'text-xs-regular'}
        >
          {message}
        </Text>
      )}
    </FlexLayout>
  );

  return tooltip ? <Tippy content={tooltip}>{base}</Tippy> : base;
};

export default withFormController(Input);
