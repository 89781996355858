import React, { useRef } from 'react';
import { BoxProps } from 'theme-ui';
import { FlexLayout, Icon, Text } from '../..';
import { DropdownOption } from '../../../../hocs/withDropdown';
import { colors, IconType } from '../../../theme';

const DropdownItem: React.FC<DropdownOption & BoxProps> = ({
  icon,
  label,
  subTitle,
  inverseColors,
  sx,
  onClick,
  isSelected,
}) => {
  const ref: any = useRef(null);

  const getTextColor = () => {
    if (inverseColors) return 'black';
    else return 'white';
  };

  return (
    <FlexLayout
      alignItems='flex-end'
      backgroundColor={colors['irisBlue']}
      flexDirection={'column'}
      justifyContent='flex-start'
      key={label}
      p={3}
      ref={ref}
      sx={{ ...sx, borderRadius: '4px' }}
      onClick={onClick}
    >
      <FlexLayout alignItems='center' justifyContent='flex-start' space={2} sx={{ width: '100%' }}>
        {icon && <Icon color={getTextColor()} icon={icon as IconType} size='s' />}
        <Text color={getTextColor()} variant={isSelected ? 'text-s-medium' : 'text-s-regular'}>
          {label}
        </Text>
      </FlexLayout>
      {subTitle && (
        <Text color={getTextColor()} variant={'text-xs-regular'}>
          {subTitle}
        </Text>
      )}
    </FlexLayout>
  );
};
export default DropdownItem;
