import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { colors, FlexLayout, Icon, IconType, radii, Text } from '../ui';

export interface AdminSidebarItemProps {
  item: { icon: IconType; name: string; link: string; disabled?: boolean };
}

export const AdminSidebarItem: React.FC<AdminSidebarItemProps> = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <FlexLayout
      alignItems={'flex-start'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      space={4}
      sx={{ width: '100%' }}
    >
      <FlexLayout
        alignItems={'flex-start'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        space={2}
        sx={{ width: '100%' }}
      >
        {!item.disabled && (
          <FlexLayout
            alignItems={'center'}
            justifyContent={'space-between'}
            space={2}
            sx={{
              width: '100%',
              cursor: 'pointer',
              background: 'linear-gradient(90deg, transparent 0%, rgba(15, 204, 195, 0.1) 100%)',
              borderRadius: radii.m,
              p: '12px 16px',
            }}
            onClick={() => navigate(item.link)}
          >
            <FlexLayout alignItems={'center'} justifyContent={'flex-start'} space={2}>
              <Icon
                icon={item.icon}
                size={'m'}
                color={location.pathname.startsWith(item.link) ? 'irisBlue' : 'black04'}
              />
              <Text
                variant={'text-s-regular'}
                color={location.pathname.startsWith(item.link) ? 'irisBlue' : colors['black04']}
                sx={{
                  textShadow: location.pathname.startsWith(item.link)
                    ? 'rgba(15, 204, 195, 0.75) -1px 4px 16px'
                    : 'none',
                }}
              >
                {item.name}
              </Text>
            </FlexLayout>
          </FlexLayout>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};
