import React from 'react';
import {
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { Box, FlexLayout } from '..';
import PaginationControl from '../PaginationControl/PaginationControl';
import NoTableData from './NoTableData';
import { TableProps } from './Table';
import TableDisplay from './TableDisplay';

const TableComponent: React.FC<TableProps> = ({
  columns,
  data,
  onItemClick,
  onItemHover,
  noData,
  setSelectedUsers,
  pagination,
}) => {
  const table = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.filter((col) => col.hide !== false).map((col) => col.accessor),
      },
    },
    useFlexLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    useRowSelect,
    (hooks) => {
      if (!setSelectedUsers || pagination) return;
      return hooks.visibleColumns.push((columns) => [
        {
          width: 40,
          disableSortBy: true,
          id: 'selection',
        },
        ...columns,
      ]);
    },
  );

  return (
    <FlexLayout
      flexDirection='column'
      space={2}
      sx={{ height: '100%', width: '100%', minWidth: '70vw' }}
    >
      {data.length > 0 && (
        <TableDisplay table={table} onItemClick={onItemClick} onItemHover={onItemHover} />
      )}
      {data.length === 0 && <NoTableData subtitle={noData?.subtitle} title={noData?.title} />}
      {pagination && data.length !== 0 && (
        <Box>
          <PaginationControl {...pagination} />
        </Box>
      )}
    </FlexLayout>
  );
};

export default TableComponent;
