import React from 'react';
import { Text as ThemeText } from 'theme-ui';
import { TextProps } from '../../props';

const Text = React.forwardRef<HTMLDivElement, TextProps>(
  ({ variant = 'text-m', color, as = 'span', upperCase = false, onClick, sx, ...rest }, ref) => {
    return (
      <ThemeText
        as={as}
        color={color}
        ref={ref}
        onClick={onClick}
        sx={{
          WebkitFontSmoothing: 'auto',
          variant: `text.${variant}`,
          textTransform: upperCase ? 'uppercase' : null,
          ...sx,
        }}
        {...rest}
      />
    );
  },
);

export default Text;
