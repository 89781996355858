import Cookies from 'universal-cookie';
import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { CookieType } from '../enums';
import { currentUserRequest, User } from '../apis';

interface AuthContext {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
  setUser: Dispatch<SetStateAction<any>>;
  signOut: () => void;
}

export const AuthContext = createContext<AuthContext>({
  isAuthenticated: false,
  isLoading: false,
  setUser: () => {},
  signOut: () => {},
});

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const cookies = new Cookies();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(!!cookies.get(CookieType.ACCESS_TOKEN));
  const [user, setUser] = useState<User>();

  const signOut = () => {
    cookies.remove(CookieType.ACCESS_TOKEN, {
      path: '/',
    });
    setUser(undefined);
  };

  useEffect(() => {
    if (!user && cookies.get(CookieType.ACCESS_TOKEN)) {
      setIsLoading(true);
      currentUserRequest()
        .then((res: AxiosResponse) => {
          setUser(res.data);
          setIsAuthenticated(!!(res.data && cookies.get(CookieType.ACCESS_TOKEN)));
        })
        .catch(() => {
          signOut();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, user, setUser, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
