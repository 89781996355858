import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import { Button, FlexLayout, Icon } from '..';
import { colors } from '../..';
import { canNextPage, goToPage, PAGINATION_DEFAULT } from '../../../helpers';
import { PaginationControls } from '../../props';

const PaginationControl: React.FC<PaginationControls> = ({
  request = PAGINATION_DEFAULT,
  response,
  setRequest,
}) => {
  const canPreviousPage = () => {
    return request.page !== 1;
  };

  useEffect(() => {
    goToPage(1, setRequest, request);
  }, [response.totalPages]);

  function getButton(nextPage: boolean) {
    return nextPage ? (
      <Button
        backgroundColor={!canNextPage(request, response) ? colors['black03'] : colors['irisBlue']}
        isDisabled={!canNextPage(request, response)}
        mr={4}
        size={'equalSize'}
        onClick={() => {
          goToPage(request.page + 1, setRequest, request);
        }}
      >
        <Icon color={'white'} icon={'chevronRight'} size={'xs'} />
      </Button>
    ) : (
      <Button
        bg={!canPreviousPage() ? colors['black03'] : 'irisBlue'}
        isDisabled={!canPreviousPage()}
        size={'equalSize'}
        onClick={() => {
          goToPage(request.page - 1, setRequest, request);
        }}
        text=''
      >
        <Icon color={'white'} icon={'chevronLeft'} size={'xs'} />
      </Button>
    );
  }

  return (
    <FlexLayout alignItems={'center'} justifyContent={'flex-end'} mt={4}>
      <ReactPaginate
        activeClassName={'active'}
        breakClassName={'page'}
        breakLabel={<>···</>}
        className={'pagination-list'}
        forcePage={request.page - 1} //the lib starts indexing at 0
        marginPagesDisplayed={1}
        nextLabel={getButton(true)}
        pageClassName={'page'}
        pageCount={response?.totalPages}
        pageRangeDisplayed={2}
        previousLabel={getButton(false)}
        onPageChange={({ selected }) => goToPage(selected + 1, setRequest, request)}
      />
    </FlexLayout>
  );
};

export default PaginationControl;
