import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../store';
import { colors, FlexLayout, radii, Text, useScreenType } from '../ui';

interface Props {
  text: string;
  isSelected: boolean;
  onClick: () => void;
}

const BracesTypeButton = (props: Props) => {
  const { isMobile } = useScreenType();

  const { text, onClick, isSelected } = props;

  const theme = useSelector((state: RootState) => state.widget.widget.user.theme);

  return (
    <FlexLayout
      alignItems='center'
      flex={1}
      justifyContent='center'
      onClick={onClick}
      sx={{
        backgroundColor: isSelected ? colors.white : theme.foregroundColor,
        borderRadius: radii.xxxxxxl,
        boxShadow: isSelected ? '0px 2px 4px rgba(0, 0, 0, 0.08)' : 'none',
        boxSizing: 'border-box',
        cursor: 'pointer',
        height: '40px',
        px: isMobile() ? '16px' : '32px',
      }}
    >
      <Text color={isSelected ? colors.black : colors.black06} variant='text-m-regular'>
        {text}
      </Text>
    </FlexLayout>
  );
};

export default BracesTypeButton;
