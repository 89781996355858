import React from 'react';
import { FlexLayout, Icon, Text } from '../ui/components';
import { IconType } from '../ui/theme';
import { useNavigate } from 'react-router';

export interface AdminPageTitleProps {
  title: string;
  icon: IconType;
  backIcon?: boolean;
}

const AdminPageTitle: React.FC<AdminPageTitleProps> = ({ title, backIcon = false }) => {
  const navigate = useNavigate();
  return (
    <FlexLayout
      alignItems={'center'}
      flexDirection={'row'}
      justifyContent={'flex-start'}
      mb={2}
      space={2}
      sx={{ width: '100%' }}
    >
      {backIcon && (
        <Icon
          icon={'arrowLeft'}
          size={'l'}
          color={'secondaryArsenic'}
          onClick={() => navigate(-1)}
        />
      )}
      <FlexLayout alignItems={'center'} justifyContent={'center'} space={2}>
        <Text variant={'text-xl-medium'} color={'secondaryArsenic'}>
          {title}
        </Text>
      </FlexLayout>
    </FlexLayout>
  );
};

export default AdminPageTitle;
