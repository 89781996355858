import { blissFonts, fonts } from './fonts';
import { fontWeights } from './fontWeights';

const regular = {
  'text-l-regular': {
    fontFamily: fonts.normal,
    fontSize: '20px',
    fontWeight: fontWeights.normal,
    lineHeight: '25px',
  },
  'text-m-regular': {
    fontFamily: fonts.normal,
    fontSize: '16px',
    fontWeight: fontWeights.normal,
    lineHeight: '20px',
  },
  'text-s-regular': {
    fontFamily: fonts.normal,
    fontSize: '14px',
    fontWeight: fontWeights.normal,
    lineHeight: '18px',
  },
  'text-xl-regular': {
    fontFamily: fonts.normal,
    fontSize: '28px',
    fontWeight: fontWeights.normal,
    lineHeight: '34px',
  },
  'text-xs-regular': {
    fontFamily: fonts.normal,
    fontSize: '12px',
    fontWeight: fontWeights.normal,
    lineHeight: '16px',
  },
  'text-xxl-regular': {
    fontFamily: fonts.normal,
    fontSize: '40px',
    fontWeight: fontWeights.normal,
    lineHeight: '72px',
  },
  'text-xxxl-regular': {
    fontFamily: fonts.normal,
    fontSize: '56px',
    fontWeight: fontWeights.normal,
    lineHeight: '72px',
  },
};

const semibold = {
  'text-l-medium': {
    fontFamily: fonts.semibold,
    fontSize: '20px',
    fontWeight: fontWeights.semibold,
    lineHeight: '25px',
  },
  'text-m-medium': {
    fontFamily: fonts.semibold,
    fontSize: '16px',
    fontWeight: fontWeights.semibold,
    lineHeight: '20px',
  },
  'text-s-medium': {
    fontFamily: fonts.semibold,
    fontSize: '14px',
    fontWeight: fontWeights.semibold,
    lineHeight: '18px',
  },
  'text-xl-medium': {
    fontFamily: fonts.semibold,
    fontSize: '28px',
    fontWeight: fontWeights.semibold,
    lineHeight: '34px',
  },
  'text-xs-medium': {
    fontFamily: fonts.semibold,
    fontSize: '12px',
    fontWeight: fontWeights.semibold,
    lineHeight: '16px',
  },
  'text-xxl-medium': {
    fontFamily: fonts.semibold,
    fontSize: '40px',
    fontWeight: fontWeights.semibold,
    lineHeight: '51px',
  },
  'text-xxxl-medium': {
    fontFamily: fonts.semibold,
    fontSize: '56px',
    fontWeight: fontWeights.semibold,
    lineHeight: '72px',
  },
};

const bold = {
  'text-l-bold': {
    fontFamily: fonts.bold,
    fontSize: '20px',
    fontWeight: fontWeights.bold,
    lineHeight: '25px',
  },
  'text-m-bold': {
    fontFamily: fonts.bold,
    fontSize: '16px',
    fontWeight: fontWeights.bold,
    lineHeight: '20px',
  },
  'text-s-bold': {
    fontFamily: fonts.bold,
    fontSize: '14px',
    fontWeight: fontWeights.bold,
    lineHeight: '18px',
  },
  'text-xl-bold': {
    fontFamily: fonts.bold,
    fontSize: '28px',
    fontWeight: fontWeights.bold,
    lineHeight: '34px',
  },
  'text-xs-bold': {
    fontFamily: fonts.bold,
    fontSize: '12px',
    fontWeight: fontWeights.bold,
    lineHeight: '16px',
  },
  'text-xxl-bold': {
    fontFamily: fonts.bold,
    fontSize: '40px',
    fontWeight: fontWeights.bold,
    lineHeight: '51px',
  },
  'text-xxxl-bold': {
    fontFamily: fonts.bold,
    fontSize: '56px',
    fontWeight: fontWeights.bold,
    lineHeight: '72px',
  },
};

export const blissVariants = {
  'bliss-button': {
    fontFamily: blissFonts.quicksandBold,
    fontSize: '16px',
    fontWeight: fontWeights.bold,
    lineHeight: '20px',
  },
  'bliss-heading': {
    fontFamily: blissFonts.quicksandBold,
    fontSize: '32px',
    fontWeight: fontWeights.bold,
    lineHeight: '40px',
  },
  'bliss-subheading': {
    fontFamily: blissFonts.quicksandBold,
    fontSize: '28px',
    fontWeight: fontWeights.bold,
    lineHeight: '34px',
  },
  'bliss-text': {
    fontFamily: blissFonts.quicksandRegular,
    fontSize: '16px',
    fontWeight: fontWeights.normal,
    lineHeight: '20px',
  },
  'bliss-text-bold': {
    fontFamily: blissFonts.quicksandBold,
    fontSize: '16px',
    fontWeight: fontWeights.bold,
    lineHeight: '20px',
  },
};

export const variants = {
  ...semibold,
  ...regular,
  ...bold,
  ...blissVariants,
};
