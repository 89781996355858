import React from 'react';

import Select, {
  components,
  ControlProps,
  MenuPlacement,
  OptionProps,
  OptionsOrGroups,
  PlaceholderProps,
  PropsValue,
} from 'react-select';
import { Box } from '..';
import { colors, radii } from '../../theme';

export interface Option {
  label: string;
  value: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface SingleSelectProps<T> {
  disabled?: boolean;
  options?: OptionsOrGroups<Option, any>;
  placeholder?: string;
  value?: PropsValue<Option>;
  defaultValue?: PropsValue<any>;
  onChange: (value: PropsValue<any>) => void;
  styles?: any;
  isSearchable?: boolean;
  menuPlacement?: MenuPlacement;
  menuIsOpen?: boolean;
}

const controlStyles = {
  background: 'white', // Replace with the desired background color
  width: '300px',
};

const ControlComponent = (props: ControlProps<Option, false>) => (
  <Box sx={controlStyles}>
    <components.Control {...props} />
  </Box>
);

const OptionComponent = (props: OptionProps<Option>) => {
  return (
    <div style={{ width: '300px' }}>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};

const Placeholder = (props: PlaceholderProps<Option>) => {
  return <components.Placeholder {...props} />;
};

export const SingleSelect = ({
  options,
  placeholder,
  menuIsOpen,
  menuPlacement,
  disabled,
  value,
  defaultValue,
  onChange,
}: SingleSelectProps<OptionProps>) => (
  <Select
    defaultValue={defaultValue}
    placeholder={placeholder}
    isClearable
    components={{
      Control: ControlComponent,
      Option: OptionComponent,
      Placeholder,
    }}
    isSearchable
    value={options && options.find((option) => option.value === value)}
    options={options}
    menuIsOpen={menuIsOpen}
    menuPlacement={menuPlacement}
    isDisabled={disabled}
    styles={{
      placeholder: (base) => ({
        ...base,
        fontSize: '1em',
        fontWeight: 500,
      }),
      singleValue: (provided) => ({
        ...provided,
        color: colors['secondaryArsenic'],
        fontFamily: 'Euclid Regular',
      }),
      control: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        border: `1px solid ${colors['irisBlue']}`,
        borderRadius: radii.s,
        height: '53px',
        zIndex: 1,
        width: '300px',
        '&:focus': { border: `1px solid ${colors['irisBlue']}` },
        fontFamily: 'Euclid Regular',
      }),
      container: (provided) => ({
        ...provided,
        fontSize: '16px',
        color: colors['secondaryArsenic'],
        width: '300px',
        fontFamily: 'Euclid Regular',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        display: 'none',
      }),
    }}
    onChange={(option) => onChange(option?.value)}
  />
);

SingleSelect.displayName = 'SingleSelect';
