import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'theme-ui';

import { RootState } from '../store';
import { colors, FlexLayout, Icon, IconType, radii, Text } from '../ui';

interface Props {
  text: string;
  icon: IconType;
  selected: boolean;
  onClick: () => void;
}

export const VeneerTypeButton = (props: Props) => {
  const { text, icon, onClick, selected } = props;

  const theme = useSelector((state: RootState) => state.widget.widget.user.theme);

  return (
    <Button onClick={onClick} sx={{ padding: 0 }}>
      <FlexLayout
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
        sx={{
          backgroundColor: selected ? colors.white : theme.foregroundColor,
          border: selected && `4px solid ${theme.foregroundColor}`,
          borderRadius: radii.m,
          height: '70px',
          width: '120px',
        }}
      >
        <Icon height='20px' icon={icon} width='72px' />
        <Text color={colors.black06} variant='text-xs-regular'>
          {text}
        </Text>
      </FlexLayout>
    </Button>
  );
};

export default VeneerTypeButton;
