import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';

import {
  AppointmentData,
  deleteAppointment,
  findAppointmentsByWidgetId,
  WidgetType,
} from '../apis';
import { Box, colors, FlexLayout, Icon, Table, Text } from '../ui';
import {
  IPaginationRequest,
  IPaginationResponse,
  PAGINATION_DEFAULT,
  formatToUppercaseFirstLetter,
} from '../helpers';
import { ThreeDotActionMenu, TextWithLabel, showToast } from '../ui';
import { DropdownOption } from '../hocs/withDropdown';
import { AuthContext } from '../context';
import { Procedures } from '../common';
import { AppointmentStatus, ToastType } from '../enums';

export interface WidgetTableProps {
  widget: WidgetType;
}

const WidgetTable: React.FC<WidgetTableProps> = ({ widget }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentData>();
  const { user } = useContext(AuthContext);
  const [paginationHeader, setPaginationHeader] = useState<IPaginationRequest>({
    ...PAGINATION_DEFAULT,
  });
  const [paginationResponse, setPaginationResponse] =
    useState<IPaginationResponse<AppointmentData>>();
  const navigate = useNavigate();

  function getAppointmentsColumns() {
    return [
      { label: 'Name', id: 'name', value: 'name' },

      { label: 'Phone', id: 'phone', value: 'phone' },
      { label: 'CreatedOn', id: 'createdAt', value: 'createdAt' },
      { label: 'Product', id: 'simulationType', value: 'simulationType' },
      { label: 'Status', id: 'status', value: 'status' },
    ];
  }

  function getAppointmentsData(data: AppointmentData[]) {
    return data.map((item: AppointmentData) => {
      const { uuid, createdAt, name, phone, visitType, simulationType, status } = item;

      return {
        uuid,
        name,
        createdAt: format(new Date(createdAt as Date), 'dd MMM yyyy'),
        phone,
        visitType,
        simulationType: formatToUppercaseFirstLetter(simulationType as Procedures),
        status: formatToUppercaseFirstLetter(status as AppointmentStatus),
      };
    });
  }

  function renderActionMenu(item: AppointmentData) {
    const dropdownOptions: DropdownOption[] = [
      {
        icon: 'edit',
        label: 'Edit Appointment',
        onClick: () => {
          setSelectedAppointment(item);
          navigate(`/admin/users/${user?.uuid}/appointments/${item.uuid}/edit`);
        },
      },
      {
        icon: 'trash',
        label: 'Delete Appointment',
        onClick: () => {
          setSelectedAppointment(item);
          mutateDelete(item.uuid as string);
          setIsLoading(true);
        },
      },
    ];

    return <ThreeDotActionMenu dropdownOptions={dropdownOptions} />;
  }

  useQuery(
    [
      'findUserAppointmentsByUserId',
      [widget, selectedAppointment, isLoading],
      {
        page: paginationHeader.page,
        take: paginationHeader.take,
      },
    ],
    () => findAppointmentsByWidgetId({ ...paginationHeader }, widget.uuid as string),
    {
      onSuccess: ({ data }) => {
        setPaginationResponse({
          ...paginationResponse,
          totalItems: data.header.totalItems,
          totalPages: data.header.totalPages,
          result: data.result,
        });
        setIsLoading(false);
      },
      retry: false,
      enabled: !!widget,
    },
  );

  const { mutate: mutateDelete } = useMutation(deleteAppointment, {
    onError: () => {
      showToast({ title: 'Something went wrong. Please try again.', toastType: ToastType.ERROR });
    },
    onSuccess: () => {
      showToast({ title: 'Changes saved successfully.', toastType: ToastType.INFO });
    },
  });

  return (
    <>
      {isLoading ? (
        <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
          <Icon icon='loading' size='xl' />
        </FlexLayout>
      ) : (
        <Box sx={{ position: 'relative', alignSelf: 'flex-start', width: '100%' }}>
          <FlexLayout
            alignItems={'flex-start'}
            flexDirection={'column'}
            space={1}
            pb={3}
            sx={{ width: '100%' }}
          >
            <TextWithLabel label='Widget Name'>
              <Text variant={'text-m-medium'} color={colors['secondaryArsenic']}>
                {widget.text}
              </Text>
            </TextWithLabel>
          </FlexLayout>
          <Table
            actionColumn={renderActionMenu}
            columns={getAppointmentsColumns()}
            data={getAppointmentsData(paginationResponse?.result || [])}
            headerBg={colors['irisBlue']}
            noData={{
              title: 'No Appointments',
              subtitle: 'Currently there are no appointments connected to this widget.',
            }}
            pagination={{
              request: paginationHeader,
              response: paginationResponse as IPaginationResponse<any>,
              setRequest: setPaginationHeader,
            }}
            sx={{ width: '100%', overflow: 'hidden' }}
          />
        </Box>
      )}
    </>
  );
};

export default WidgetTable;
