const space = {
  0: '0px',
  1: '4px',
  2: '8px',
  3: '16px',
  4: '24px',
  5: '32px',
  6: '40px',
  7: '48px',
  8: '64px',
  9: '80px',
  10: '120px',
  11: '170px',
};

export default Object.values(space);
