import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import { Box, colors, FlexLayout, Icon, radii, Text, useScreenType } from '../../ui';

interface Props {
  onClose: () => void;
}
const TermsModalContent = (props: Props) => {
  const { onClose } = props;
  const { isMobile } = useScreenType();

  const theme = useSelector((state: RootState) => state.widget.widget.user.theme);

  const { t } = useTranslation();

  return (
    <FlexLayout
      flexDirection='column'
      p={[5, 6]}
      sx={{
        background: theme.backgroundColor,
        borderTopLeftRadius: isMobile() && radii.xxl,
        borderTopRightRadius: isMobile() && radii.xxl,
        height: isMobile() ? '90vh' : '100vh',
        overflow: 'scroll',
        width: isMobile() ? '100vw' : '650px',
      }}
    >
      <FlexLayout justifyContent='flex-end'>
        <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
          <Icon icon='close' size='xl' />
        </Box>
      </FlexLayout>
      <FlexLayout flexDirection='column' space={6}>
        <Text variant='text-xl-regular'>{t('termsModal.title')}</Text>
        <FlexLayout flexDirection='column' space={3}>
          <Text variant='text-m-regular'>{t('termsModal.introSubtitle')}</Text>
          <Text color={colors.black07} variant='text-m-regular'>
            {t('termsModal.introText.one')}
          </Text>
        </FlexLayout>
        <Text color={colors.black07} variant='text-m-regular'>
          {t('termsModal.introText.two')}
        </Text>
        <Text color={colors.black07} variant='text-m-regular'>
          {t('termsModal.introText.three')}
        </Text>
        <FlexLayout flexDirection='column' space={3}>
          <Text variant='text-m-regular'>{t('termsModal.cookiesSubtitle')}</Text>

          <Text color={colors.black07} variant='text-m-regular'>
            {t('termsModal.cookiesText')}
          </Text>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export default TermsModalContent;
