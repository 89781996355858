import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { colors, FlexLayout, Slider, Text } from '../ui';
import { useIsBlissWidget } from '../hooks';

interface Props {
  defaultScale?: number;
  onChange: (value: number) => void;
}

const WhiteningSlider = (props: Props) => {
  const isBlissWidget = useIsBlissWidget();

  const { defaultScale = isBlissWidget ? 1.0 : 1.1, onChange } = props;

  const sliderRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const [scale, setScale] = useState<number>(defaultScale);

  const calculateBlissSliderValue = () => {
    const blissMin = 1;
    const blissMax = 14;

    const output = Math.round(((scale - 1) / 0.2) * (blissMax - blissMin) + blissMin);

    if (output <= 13 && output >= 2) {
      return output;
    }

    return '';
  };

  const calculateCurrentLabelPosition = () => {
    if (sliderRef.current) {
      return ((scale - 1) / (1.2 - 1)) * (sliderRef.current.clientWidth - 12 - 12) + 12;
    }
  };

  return (
    <>
      <FlexLayout justifyContent='space-between' mt={3} sx={{ width: '100%' }}>
        {isBlissWidget ? (
          <FlexLayout flexDirection='column' space={2} sx={{ position: 'relative', width: '100%' }}>
            <Text variant='bliss-subheading'>Whitening Strips Applications</Text>
            <FlexLayout justifyContent='space-between' px={2}>
              <Text variant='bliss-text'>1</Text>
              <Text
                sx={{
                  left: `${calculateCurrentLabelPosition()}px`,
                  position: 'absolute',
                  textAlign: 'center',
                  width: 24,
                }}
                variant='bliss-button'
              >
                {calculateBlissSliderValue()}
              </Text>
              <Text variant='bliss-text'>14</Text>
            </FlexLayout>
          </FlexLayout>
        ) : (
          <>
            <Text variant='text-m-regular'>{t('whiteningProcedure.chooseLevelText')}</Text>
            <Text color={colors.black07} variant='text-xs-regular'>
              {Math.trunc(((scale - 1) / 0.2) * 100)}%
            </Text>
          </>
        )}
      </FlexLayout>
      <FlexLayout mt={2} sx={{ position: 'relative', width: '100%' }}>
        <Slider
          inputClassName={isBlissWidget ? 'yss-bliss-slider' : ''}
          max={1.2}
          min={1}
          onChangeScale={(value: number) => {
            setScale(value);
            onChange(value);
          }}
          ref={sliderRef}
          scale={scale}
          size='l'
          step={0.01}
          sx={{ width: '100%' }}
        />
      </FlexLayout>
    </>
  );
};

export default WhiteningSlider;
