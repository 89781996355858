import React, { useContext, useEffect } from 'react';
import { BoxProps, Box } from 'theme-ui';
import { FlexLayout, GridLayout } from '../ui/components';
import { AdminSidebar } from '.';
import { AuthContext } from '../context/auth';
import { useNavigate } from 'react-router';
import { Routes } from '../routes/routes';
import { ToastMessageContainer } from '../ui/components/Toast';

const AdminPageLayout: React.FC<BoxProps> = ({ children }) => {
  const { user, isLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !user) navigate(Routes.Login);
  }, [isLoading, user]);

  return (
    <Box
      backgroundColor={'#f5f7fb'}
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      <GridLayout gridTemplateColumns={'1fr 9fr'} sx={{ position: 'relative', height: '100vh' }}>
        <AdminSidebar />
        <FlexLayout
          sx={{
            padding: '48px',
            width: '100%',
            overflowY: 'scroll',
          }}
        >
          {children}
        </FlexLayout>
      </GridLayout>
      <ToastMessageContainer />
    </Box>
  );
};

export default AdminPageLayout;
