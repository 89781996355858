import { FlexLayout, Icon, Text, Button, colors } from '../ui';
import React, { useState } from 'react';

export function TrackingBanner() {
  const [isClosed, setIsClosed] = useState(
    () => localStorage.getItem('analytics_allowed') !== null,
  );

  const handleClose = (response: string) => {
    localStorage.setItem('analytics_allowed', response);
    setIsClosed(true);
  };

  if (isClosed) {
    return null;
  }

  return (
    <FlexLayout
      space={2}
      alignItems='flex-end'
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '100%',
        backgroundColor: colors.black03,
      }}
    >
      <FlexLayout
        flexDirection='column'
        space={3}
        sx={{
          p: 16,
          backgroundColor: 'lightcyan',
          height: 'fit-content',
          width: '100%',
        }}
      >
        <FlexLayout justifyContent='space-between' alignItems={'center'}>
          <FlexLayout space={2} alignItems={'center'}>
            <FlexLayout
              justifyContent='center'
              alignItems='center'
              sx={{
                borderRadius: '50%',
                width: 20,
                height: 20,
                backgroundColor: 'darkblue',
              }}
            >
              <Text variant='text-m-regular' color='white'>
                i
              </Text>
            </FlexLayout>
            <Text variant='text-l-medium'>Privacy policy & Cookie Consent</Text>
          </FlexLayout>
          <Icon icon='close' onClick={() => handleClose('true')} />
        </FlexLayout>
        <FlexLayout space={3} sx={{ pl: 30 }} flexDirection='column'>
          <Text variant='text-m-regular'>
            We use cookies and similar technologies to enhance your browsing experience and analyze
            site traffic. By clicking &quot;Accept&quot;, you consent to the use of cookies for
            these purposes. You can opt-out of analytics cookies by clicking &quot;Deny&quot;, For
            more information, please read our{' '}
            <a href={'https://www.yoursocialsmile.com/privacy-policy'}>[Privacy policy]</a>.
          </Text>
          <FlexLayout space={3}>
            <Button onClick={() => handleClose('true')}>
              <Text variant='text-m-regular'>Accept</Text>
            </Button>
            <Button onClick={() => handleClose('false')}>
              <Text variant='text-m-regular'>Decline</Text>
            </Button>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
}
