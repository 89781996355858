import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { GenericModal } from '..';

import PrivacyModalContent from './PrivacyModalContent';
import TermsModalContent from './TermsModalContent';

const PrivacyAndTermsModals = () => {
  const cookies = new Cookies();
  const { slug, widgetId } = useParams();

  const navigate = useNavigate();
  const [showPrivacyModal, setShowPrivacyModal] = useState(true);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  return (
    <>
      <GenericModal
        content={
          <PrivacyModalContent
            onAccept={() => {
              cookies.set('termsAccepted', true);
              setShowPrivacyModal(false);
            }}
            onCheck={(value: boolean) => {
              setTermsAccepted(value);
            }}
            onDecline={() => {
              setShowPrivacyModal(false);
              navigate(`/${slug}/${widgetId}`);
            }}
            openTerms={() => {
              setShowTermsModal(true);
            }}
            termsAccepted={termsAccepted}
          />
        }
        positionX='center'
        positionY='center'
        showModal={!cookies.get('termsAccepted') && showPrivacyModal}
      />
      <GenericModal
        content={
          <TermsModalContent
            onClose={() => {
              setShowTermsModal(false);
            }}
          />
        }
        notInCentre
        positionX='flex-end'
        positionY='flex-end'
        showModal={showTermsModal}
      />
    </>
  );
};

export default PrivacyAndTermsModals;
