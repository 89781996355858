import './App.css';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import './utils/react-tippy-overrides.css';
import './utils/react-phone-number-input-overrides.css';
import './utils/react-toastify-overrides.css';
import './utils/react-paginate-overrides.css';

import * as amplitude from '@amplitude/analytics-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'theme-ui';
import { loadStripe } from '@stripe/stripe-js';

import { componentRoutes, RouteType } from './routes/routes';
import store from './store';
import { theme } from './ui/theme';
import { AuthProvider } from './context/auth';
import { Elements } from '@stripe/react-stripe-js';

const queryClient = new QueryClient();

if (process.env.NODE_ENV !== 'development') {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY!, undefined, {
    defaultTracking: {
      fileDownloads: true,
      formInteractions: true,
      pageViews: true,
      sessions: true,
    },
  });
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

function App() {
  const renderComponents = (route: RouteType) => {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <route.component />
        </ThemeProvider>
      </Provider>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Elements stripe={stripePromise}>
        <AuthProvider>
          <div className='App'>
            <Router basename='/'>
              <Routes>
                {componentRoutes.map((route: RouteType, index: number) => {
                  if (route.enabled)
                    return (
                      <Route element={renderComponents(route)} key={index} path={route.path} />
                    );
                })}
              </Routes>
            </Router>
          </div>
        </AuthProvider>
      </Elements>
      <ToastContainer autoClose={2000} position='top-right' />
    </QueryClientProvider>
  );
}

export default App;
