import React from 'react';
import { Box } from 'theme-ui';
import { GridLayoutProps } from '../../props';

const GridLayout: React.FC<GridLayoutProps> = ({
  alignItems,
  columnGap,
  gap,
  gridTemplateColumns,
  gridTemplateRows,
  justifyItems,
  rowGap,
  sx = {},
  ...rest
}) => {
  return (
    <Box
      sx={{
        ...sx,
        alignItems,
        columnGap,
        display: 'grid',
        gap,
        gridTemplateColumns,
        gridTemplateRows,
        justifyItems,
        rowGap,
      }}
      {...rest}
    />
  );
};

export default GridLayout;
