import React, { useState } from 'react';
import { headerItems, Routes } from '../routes/routes';
import { Box, colors, FlexLayout, GridLayout, Icon, Image, Link, Text, useScreenType } from '../ui';
import Logo from '../ui/assets/images/Logo.png';
import { MobileHeader } from '.';

interface CustomerHeaderProps {
  headerItemLink?: React.ReactNode;
}

const CustomerHeader = (props: CustomerHeaderProps) => {
  const [showMobile, setShowMobile] = useState<boolean>(false);
  const { isDesktop } = useScreenType();
  const {
    headerItemLink = (
      <Link href={Routes.Login} color={colors['blissCyan']} sx={{ textDecoration: 'none' }}>
        <Text variant={'text-m-medium'}>Login</Text>
      </Link>
    ),
  } = props;

  return (
    <FlexLayout
      as='header'
      alignItems='center'
      justifyContent='center'
      sx={{
        height: '100px',
        px: '52px',
        py: '52px',
        background: 'white',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 100,
        borderBottom: `1px solid ${colors['neutral']}`,
      }}
    >
      {isDesktop() ? (
        <GridLayout gridTemplateColumns={'repeat(3, 1fr)'} gap={8} sx={{ width: '100%' }}>
          <Box>
            <Icon icon='yss' size='max' />
          </Box>
          <FlexLayout
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            space={6}
            sx={{ width: '100%' }}
          >
            {headerItems.map((item, index) => (
              <Link
                key={index}
                href={item.link}
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                <Text
                  variant={'text-m-medium'}
                  color={'secondaryArsenic'}
                  sx={{
                    '&:hover': {
                      color: colors['irisBlue'],
                    },
                  }}
                >
                  {item.name}
                </Text>
              </Link>
            ))}
          </FlexLayout>
          <FlexLayout justifyContent={'flex-end'} alignItems={'center'} sx={{ width: '100%' }}>
            {headerItemLink}
          </FlexLayout>
        </GridLayout>
      ) : showMobile ? (
        <MobileHeader
          onCloseItemClick={() => setShowMobile(false)}
          headerItemLink={headerItemLink}
        />
      ) : (
        <FlexLayout
          flexDirection='column'
          justifyContent='space-between'
          sx={{
            left: 0,
            right: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <FlexLayout flexDirection='column' sx={{ minHeight: '0px' }}>
            <FlexLayout justifyContent='space-between' alignItems={'center'} py={3} px={[6, 6, 8]}>
              <Box>
                <Link href={'/'}>
                  <Image src={Logo} />
                </Link>
              </Box>
              <Icon
                color={colors['black07']}
                icon='menu'
                size='l'
                onClick={() => setShowMobile(true)}
              />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

export default CustomerHeader;
