import React from 'react';
import { FlexLayout, Link, Text, useScreenType } from '../ui';

const CTABlock = () => {
  const { isDesktop } = useScreenType();
  return (
    <>
      <FlexLayout flexDirection={'column'} alignItems={'center'} space={4} mb={isDesktop() ? 0 : 4}>
        <Text variant={'text-m-medium'}>Company</Text>
        {[
          { name: 'About', link: 'https://www.yoursocialsmile.com/about' },
          { name: 'Contact', link: 'https://www.yoursocialsmile.com/contact' },
        ].map((item, index) => (
          <Link key={index} href={item.link} sx={{ textDecoration: 'none', textAlign: 'center' }}>
            <Text variant={'text-m-regular'} color={'secondaryArsenic'}>
              {item.name}
            </Text>
          </Link>
        ))}
      </FlexLayout>
      <FlexLayout flexDirection={'column'} alignItems={'center'} space={4} mb={isDesktop() ? 0 : 4}>
        <Text variant={'text-m-medium'}>Support</Text>
        {[
          { name: 'Products', link: 'https://www.yoursocialsmile.com/products' },
          { name: 'FAQ', link: 'https://www.yoursocialsmile.com/faq' },
        ].map((item, index) => (
          <Link key={index} href={item.link} sx={{ textDecoration: 'none', textAlign: 'center' }}>
            <Text variant={'text-m-regular'} color={'secondaryArsenic'}>
              {item.name}
            </Text>
          </Link>
        ))}
      </FlexLayout>
      <FlexLayout flexDirection={'column'} alignItems={'center'} space={4} mb={isDesktop() ? 0 : 4}>
        <Text variant={'text-m-medium'}>Legal</Text>
        {[
          { name: 'Privacy Policy', link: 'https://www.yoursocialsmile.com/privacy-policy' },
          {
            name: 'Terms & Conditions',
            link: 'https://www.yoursocialsmile.com//terms-conditions',
          },
        ].map((item, index) => (
          <Link key={index} href={item.link} sx={{ textDecoration: 'none', textAlign: 'center' }}>
            <Text variant={'text-m-regular'} color={'secondaryArsenic'}>
              {item.name}
            </Text>
          </Link>
        ))}
      </FlexLayout>
    </>
  );
};

export default CTABlock;
