import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';

import { CookieType } from '../enums';

import { Routes } from '../routes/routes';

export default function createService(configuration: AxiosRequestConfig) {
  const instance = axios.create({
    baseURL: configuration.url,
    data: configuration.data,
    withCredentials: true,
  });

  instance.interceptors.request.use((config: any) => {
    const cookies = new Cookies();

    config.headers.Authorization = `Bearer ${cookies.get(CookieType.ACCESS_TOKEN)}` as string;

    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const { response } = error;
      const cookies = new Cookies();

      const accessToken = cookies.get(CookieType.ACCESS_TOKEN);

      if (response && response.status === 401) {
        if (accessToken) {
          cookies.remove(CookieType.ACCESS_TOKEN);
        }
        if (window.location.pathname !== Routes.Login) {
          window.location.pathname = Routes.Dashboard;
        }

        return Promise.reject(error.response);
      }

      return Promise.reject(error);
    },
  );

  return {
    delete: instance.delete,
    get: instance.get,
    patch: instance.patch,
    post: instance.post,
  };
}
