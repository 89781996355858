import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'theme-ui';

import { RootState } from '../store';
import { usePhotoEditor } from '../context/photoEditor';
import { useIsBlissWidget } from '../hooks/bliss';
import { Box, Button, FlexLayout, Text, useScreenType } from '../ui';
import { AnalyticsEventNames, trackAnalyticsEvent } from '../utils';
import { BracesProcedure, Handbook, ResultPreview, VeneersProcedure, WhiteningProcedure } from './';

interface Props {
  onEmailPhoto: () => void;
  onScheduleAppointment: () => void;
}

const Procedure = (props: Props) => {
  const { isMobile } = useScreenType();

  const { t } = useTranslation();

  const widget = useSelector((state: RootState) => state.widget.widget);

  const isBlissWidget = useIsBlissWidget();

  const { onEmailPhoto, onScheduleAppointment } = props;

  const { procedure, croppedImage, reset } = usePhotoEditor();

  if (isBlissWidget && croppedImage) {
    return (
      <FlexLayout alignItems={isMobile() ? '' : 'center'} flexDirection='column'>
        <FlexLayout flexWrap='wrap' justifyContent='center' mt={6} space={[6, 10]}>
          <Box
            sx={{
              columnGap: 10,
              display: 'grid',
              gridTemplateAreas: [
                `"procedure"
                "preview"
                "info"`,
                `"procedure"
                "preview"
                "info"`,
                `"preview procedure"
                "preview info"`,
              ],
              maxWidth: '1000px',
              rowGap: [6, 6, 0],
            }}
          >
            <FlexLayout
              alignItems='center'
              flexDirection='column'
              space={4}
              sx={{ gridArea: 'preview', gridRow: ['0', '0', '1/3'] }}
            >
              <ResultPreview />
              <Button
                onClick={reset}
                text={t('photoEditor.tryNewPhotoBtnText')}
                textVariant='bliss-button'
                variant='blissSecondary'
              />
            </FlexLayout>
            <FlexLayout
              sx={{
                alignSelf: 'end',
                gridArea: 'procedure',
                justifySelf: ['center', 'center', 'stretch'],
              }}
            >
              <WhiteningProcedure />
              <VeneersProcedure />
              <BracesProcedure />
            </FlexLayout>
            <FlexLayout
              flexDirection='column'
              mt={2}
              space={3}
              sx={{
                gridArea: 'info',
                justifySelf: 'center',
              }}
            >
              <Text variant='bliss-subheading'>Love the new look? Let&apos;s make it happen!</Text>
              <Text variant='bliss-text'>
                Our Ultimate Whitening Kit has everything you need to whiten your smile and keep it
                looking it&apos;s best.
              </Text>
              <Link
                href='https://blissoralcare.com/collections/teeth-whitening-kits/products/bliss-ultimate-whitening-kit'
                rel='noreferred'
                sx={{ textDecoration: 'none' }}
                target='_blank'
              >
                <Button
                  onClick={() => {
                    if (widget) {
                      trackAnalyticsEvent(AnalyticsEventNames.BlissWhiteningKit, {
                        customerID: widget.user.uuid,
                        customerName: widget.user?.clinicName,
                        customerType: widget.user.type,
                      });
                    }
                  }}
                  size='s'
                  text=' Shop Ultimate Whitening Kit'
                  textVariant='bliss-button'
                  variant='blissPrimary'
                />
              </Link>
            </FlexLayout>
          </Box>
        </FlexLayout>
        <FlexLayout flexWrap='wrap' mb={6} mt={8}>
          <Handbook procedure={procedure} />
        </FlexLayout>
      </FlexLayout>
    );
  }

  if (croppedImage) {
    return (
      <FlexLayout alignItems={isMobile() ? '' : 'center'} flexDirection='column'>
        <FlexLayout flexWrap='wrap' justifyContent='center' mt={6} space={[6, 10]}>
          <FlexLayout flexWrap='wrap' justifyContent='center' space={[6, 10]}>
            <FlexLayout alignItems='center' flexDirection='column' space={4}>
              <ResultPreview />
              <Button
                onClick={reset}
                text={t('photoEditor.tryNewPhotoBtnText')}
                variant='blissSecondary'
              />
            </FlexLayout>
            <FlexLayout
              flexDirection='column'
              justifyContent='center'
              sx={{
                width: !isMobile() && 'take-advice-width',
              }}
            >
              <WhiteningProcedure />
              <VeneersProcedure />
              <BracesProcedure />
              <FlexLayout flexDirection='column' mt={7} space={3}>
                <Button
                  iconRight='right'
                  onClick={onScheduleAppointment}
                  size='l'
                  text={t('photoEditor.appointmentBtnText')}
                  variant='primary'
                  width='100%'
                />
                <FlexLayout space={3}>
                  <Button
                    onClick={onEmailPhoto}
                    size='l'
                    text={t('photoEditor.emailPhotoBtnText')}
                    variant='secondary'
                    width='100%'
                  />
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout flexWrap='wrap' mb={6}>
          <Handbook procedure={procedure} />
        </FlexLayout>
      </FlexLayout>
    );
  }

  return null;
};

export default Procedure;
