import React from 'react';
import { GenericModal } from '..';
import { usePhotoEditor } from '../../context/photoEditor';

import ErrorModalContent from './ErrorModalContent';

const PhotoErrorModal = () => {
  const { photoError, reset } = usePhotoEditor();

  if (photoError) {
    return (
      <GenericModal
        content={<ErrorModalContent error={photoError} onClose={reset} />}
        positionX='center'
        positionY='center'
        showModal={photoError !== undefined}
      />
    );
  }

  return null;
};

export default PhotoErrorModal;
