import '../pages-components/WidgetCodeSnippet.css';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';

import { AppointmentData, findAppointment, updateAppointment, UpdateAppointmentDto } from '../apis';
import { Box, Button, FlexLayout, GridLayout, Icon, Text, showToast } from '../ui';
import { AdminPageLayout, AdminPageTitle, AppointmentCard } from '../pages-components';
import { AppointmentStatus, ToastType } from '../enums';
import { VisitType } from '../common';
import { appointmentStatuses, visitTypes } from '../helpers';
import { SingleSelectForm } from '../ui/components/SingleSelect/SingleSelectForm';

const EditAppointment = () => {
  const { control, formState, handleSubmit, setValue } = useForm<UpdateAppointmentDto>({
    mode: 'all',
    defaultValues: {
      status: AppointmentStatus.NEW,
      type: VisitType.VIDEO,
    },
  });
  const [appointment, setAppointment] = useState<AppointmentData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  useQuery(['findAppointment', appointmentId], () => findAppointment(appointmentId as string), {
    onSuccess: ({ data }) => {
      setAppointment(data);
    },
    onError: () => {
      showToast({
        title: 'Something went wrong. Please try again later.',
        toastType: ToastType.ERROR,
      });
    },
    onSettled: () => {
      setIsLoading(false);
    },
    enabled: !!appointmentId,
    retry: false,
  });

  const { mutate } = useMutation(updateAppointment, {
    onError: () => {
      showToast({ title: 'Something went wrong. Please try again.', toastType: ToastType.ERROR });
    },
    onSuccess: () => {
      showToast({ title: 'Changes saved successfully.', toastType: ToastType.INFO });
      navigate(-1);
    },
  });

  const onSubmit = async (values: UpdateAppointmentDto) => {
    mutate({
      appointmentId: appointment?.uuid as string,
      updateAppointmentDto: {
        status: values.status,
        type: values.type,
      },
    });
  };

  return (
    <AdminPageLayout>
      <FlexLayout
        as='form'
        flexDirection={'column'}
        alignItems={'flex-start'}
        sx={{ width: '100%', height: '100%' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <AdminPageTitle backIcon title={'Manage Appointment'} icon={'chair'} />
        <FlexLayout alignItems={'center'} sx={{ width: '100%', height: '100%' }}>
          {isLoading ? (
            <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
              <Icon icon='loading' size='xl' />
            </FlexLayout>
          ) : (
            <FlexLayout
              alignItems={'flex-start'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              space={4}
              py={4}
              sx={{ height: '100%' }}
            >
              <FlexLayout alignItems={'flex-start'} flexDirection={'column'} space={4}>
                <AppointmentCard appointment={appointment as AppointmentData} />
                <GridLayout
                  alignItems={'flex-start'}
                  gap={4}
                  gridTemplateColumns={'repeat(2, 1fr)'}
                >
                  <Box>
                    <FlexLayout flexDirection='column' space={2}>
                      <Text color={'irisBlue'} variant={'text-xs-medium'} upperCase>
                        Appointment Status
                      </Text>
                      <SingleSelectForm
                        control={control as any}
                        defaultValue={appointmentStatuses.find(
                          (a) => a.value === appointment?.status,
                        )}
                        name='status'
                        options={appointmentStatuses.map((a) => {
                          return {
                            label: a.label,
                            value: a.value,
                          };
                        })}
                        placeholder={'Select Status'}
                        onChange={(value: AppointmentStatus) => {
                          setValue('status', value, { shouldValidate: true, shouldDirty: true });
                        }}
                        rules={{
                          required: { value: true, message: 'Appointment status is required.' },
                        }}
                      />
                    </FlexLayout>
                  </Box>
                  <Box>
                    <FlexLayout flexDirection='column' space={2}>
                      <Text color={'irisBlue'} variant={'text-xs-medium'} upperCase>
                        Visit Type
                      </Text>
                      <SingleSelectForm
                        control={control as any}
                        defaultValue={visitTypes.find((v) => v.value === appointment?.type)}
                        name='type'
                        options={visitTypes.map((v) => {
                          return {
                            label: v.label,
                            value: v.value,
                          };
                        })}
                        placeholder={'Select Type'}
                        onChange={(value: VisitType) => {
                          setValue('type', value, { shouldValidate: true, shouldDirty: true });
                        }}
                        rules={{
                          required: { value: true, message: 'Appointment type is required.' },
                        }}
                      />
                    </FlexLayout>
                  </Box>
                </GridLayout>
              </FlexLayout>
              <Button
                size='standard'
                isDisabled={!formState.isValid}
                text={'Save Changes'}
                variant='irisBlue'
                type={'submit'}
              />
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </AdminPageLayout>
  );
};

export default EditAppointment;
