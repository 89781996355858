import React from 'react';

import { PhotoSource } from '../common';
import { usePhotoEditor } from '../context';
import { FlexLayout, useScreenType } from '../ui';
import { ImagePicker, ImageResizer, PhotoAdvice, WebCamera } from './';

const PhotoUploadFlow = () => {
  const { isMobile } = useScreenType();

  const {
    selectedImage,
    onCropImage,
    reset,
    onPhotoUpload,
    photoSource,
    setSelectedImage,
    setPhotoSource,
    croppedImage,
  } = usePhotoEditor();

  if (!croppedImage) {
    return (
      <FlexLayout
        alignItems='center'
        flexWrap={selectedImage ? 'wrap' : 'wrap-reverse'}
        justifyContent='center'
        space={[3, 6, 10]}
      >
        {selectedImage && <ImageResizer />}
        {!selectedImage && !photoSource && <ImagePicker />}
        {!selectedImage && photoSource === PhotoSource.WebCamera && (
          <WebCamera
            onPhotoUpload={(e) => onPhotoUpload(e)}
            onTryAgain={() => {
              setPhotoSource(PhotoSource.WebCamera);
            }}
            setSelectedImage={(value) => setSelectedImage(value)}
          />
        )}
        <FlexLayout
          flexDirection='column'
          justifyContent='flex-start'
          sx={{
            width: isMobile() ? '100%' : 'take-advice-width',
          }}
        >
          <PhotoAdvice
            onContinue={onCropImage}
            onRetakePhoto={reset}
            selectedImage={selectedImage}
          />
        </FlexLayout>
      </FlexLayout>
    );
  }

  return null;
};

export default PhotoUploadFlow;
