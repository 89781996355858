import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { UseFormReturn } from 'react-hook-form';

import { SignUpFormValues } from '../apis';
import Whitening from '../ui/assets/images/whitening-step1.jpg';
import { emailRegex, passwordRegex } from '../helpers';
import { maxLengthUtil, minLengthUtil } from '../utils';
import { Button, colors, FlexLayout, Image, Input, Text, useScreenType } from '../ui';

interface UserDetailsProps {
  form: UseFormReturn<SignUpFormValues>;
  onUserDetailsSubmit: () => void;
}

const UserDetails = ({ form, onUserDetailsSubmit }: UserDetailsProps) => {
  const { control, formState, handleSubmit } = form;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { isDesktop } = useScreenType();

  const onSubmit = async () => {
    setErrorMessage('');
    onUserDetailsSubmit();
  };

  return (
    <FlexLayout
      alignItems={'flex-start'}
      justifyContent={'center'}
      flexDirection={isDesktop() ? 'row' : 'column'}
      space={4}
      sx={{
        width: '100%',
        maxWidth: '1280px',
        borderRadius: '8px',
        p: '80px',
        backgroundColor: `${colors['lightCyan']}`,
      }}
    >
      <FlexLayout
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ width: '100%', height: '100%' }}
      >
        <Image src={Whitening} />
      </FlexLayout>
      <FlexLayout
        alignItems={'flex-start'}
        sx={{ width: '100%', height: '100%' }}
        onSubmit={handleSubmit(onSubmit)}
        as={'form'}
      >
        <FlexLayout
          flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          space={4}
          sx={{ height: '100%' }}
        >
          <FlexLayout alignItems={'flex-start'} flexDirection={'column'} space={1}>
            <Text variant={'text-xl-medium'} color={colors['secondaryArsenic']}>
              Your Clinic Details
            </Text>
            <Text variant={'text-s-regular'} color={colors['secondaryArsenic']}>
              Tell us more about yourself.
            </Text>
          </FlexLayout>
          <Input
            control={control as any}
            error={!!(formState.dirtyFields.clinicName && formState.errors.clinicName)}
            label={'Clinic Name*'}
            message={formState.errors?.clinicName?.message}
            placeholder={'Enter your name'}
            name='clinicName'
            rules={{
              required: true,
              minLength: minLengthUtil(2),
              maxLength: maxLengthUtil(30),
            }}
          />
          <Input
            control={control as any}
            error={
              !!(formState.dirtyFields.email && formState.errors.email?.type === 'pattern') ||
              !!errorMessage
            }
            label={'Email Address*'}
            message={formState.errors?.email?.message}
            placeholder={'Enter your work mail'}
            name='email'
            rules={{
              pattern: {
                message: 'Invalid email address.',
                value: emailRegex,
              },
              required: true,
            }}
          />
          <Input
            control={control as any}
            error={!!(formState.dirtyFields.password && formState.errors.password)}
            message={formState.errors?.password?.message}
            icon={showPassword ? 'hidePassword' : 'showPassword'}
            label={'Password*'}
            name='password'
            placeholder={'Enter your password'}
            rules={{
              pattern: {
                value: passwordRegex,
                message:
                  'The password must be at least 8 characters long, contain at least one upper case character, one lower case character and one number.',
              },
              required: true,
            }}
            type={showPassword ? 'text' : 'password'}
            onIconClick={() => setShowPassword(!showPassword)}
          />
          <FlexLayout flexDirection='column' space={2} sx={{ width: '100%' }}>
            <FlexLayout flexDirection='column' space={2} justifyContent='space-between'>
              <Text color={colors.black07} variant={'text-m-regular'}>
                {'Phone Number*'}
              </Text>
              <PhoneInputWithCountry
                international
                countryCallingCodeEditable={false}
                name='phoneNumber'
                control={control}
                rules={{
                  required: { value: true, message: 'Phone number is required.' },
                }}
              />
            </FlexLayout>
          </FlexLayout>
          <FlexLayout
            alignItems={'center'}
            justifyContent={!isDesktop ? 'center' : 'flex-start'}
            sx={{ width: '100%' }}
          >
            <Button
              size='standard'
              text={'Continue'}
              variant='irisBlue'
              isDisabled={!formState.isValid}
              type={'submit'}
              width={!isDesktop() ? '100%' : 'none'}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export default UserDetails;
