import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../store';
import { colors, FlexLayout, radii, useScreenType } from '../ui';

interface Props {
  content: React.ReactNode;
  showModal: boolean;
  positionX: string;
  positionY: string;
  notInCentre?: boolean;
}
const GenericModal = (props: Props) => {
  const { showModal, content, positionX, positionY, notInCentre } = props;
  const { isMobile } = useScreenType();

  const theme = useSelector((state: RootState) => state.widget.widget.user.theme);

  const countWidth = () => {
    if (isMobile()) {
      return '100%';
    } else if (notInCentre) {
      return 'fit-content';
    }
    return 'privacy-modal-width';
  };
  const countHeight = () => {
    if (!notInCentre) {
      return 'fit-content';
    } else if (isMobile()) {
      return '90vh';
    }
    return '100vh';
  };
  return (
    <div>
      {showModal && (
        <FlexLayout
          alignItems={positionY}
          justifyContent={positionX}
          sx={{
            background: colors.black06,
            height: '100vh',
            position: 'fixed',
            width: '100vw',
            zIndex: 100,
          }}
        >
          <FlexLayout
            alignItems={notInCentre ? 'flex-end' : 'center'}
            flexDirection='column'
            mx={isMobile() && !notInCentre && 5}
            sx={{
              background: theme.backgroundColor,
              borderRadius: !notInCentre && radii.xxxl,
              borderTopLeftRadius: notInCentre && isMobile() && radii.xxl,
              borderTopRightRadius: notInCentre && isMobile() && radii.xxl,
              height: countHeight(),
              overflow: notInCentre && 'scroll',
              width: countWidth(),
            }}
          >
            {content}
          </FlexLayout>
        </FlexLayout>
      )}
    </div>
  );
};

export default GenericModal;
