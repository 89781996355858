import 'tippy.js/dist/tippy.css';

import Tippy, { TippyProps } from '@tippyjs/react';
import { Box } from '..';
import React from 'react';

const Tooltip: React.FC<TippyProps> = ({
  content,
  children,
  placement = 'bottom-end',
  visible,
  offset = [0, 0],
  onClickOutside,
  ...rest
}) => {
  return (
    <Box sx={{ flexShrink: 0 }}>
      <Tippy
        animation={false}
        arrow={false}
        className='tippy-box-dropdown'
        content={content}
        interactive={true}
        offset={offset}
        placement={placement}
        visible={visible}
        zIndex={99}
        onClickOutside={onClickOutside}
        {...rest}
      >
        {children}
      </Tippy>
    </Box>
  );
};

export default Tooltip;
