import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import { usePhotoEditor } from '../context/photoEditor';

import { useIsBlissWidget } from '../hooks';
import { Box, colors, FlexLayout, Text, useScreenType } from '../ui';
import { overlayStyles } from './photo-editor.styles';
import { SliderContainer } from './';

const ImageResizer = () => {
  const { isMobile } = useScreenType();

  const { t } = useTranslation();

  const isBlissWidget = useIsBlissWidget();

  const [scale, setScale] = useState(1.0);

  const { selectedImage, editorRef } = usePhotoEditor();

  return (
    <FlexLayout
      alignItems='center'
      flexDirection='column'
      space={4}
      sx={{ width: isMobile() ? '100%' : 470 }}
    >
      <Box
        sx={{
          height: isMobile() ? 406 : 636,
          position: 'relative',
          width: isMobile() ? 300 : '100%',
          overflow: 'hidden',
          ...overlayStyles,
        }}
      >
        <AvatarEditor
          border={0}
          color={[0, 0, 0, 0]}
          height={1200}
          image={selectedImage!}
          ref={editorRef}
          rotate={0}
          scale={scale}
          style={{
            height: isMobile() ? '406px' : '636px',
            width: isMobile() ? '300px' : '470px',
          }}
          width={887}
        />
      </Box>
      <SliderContainer
        buttons
        onChangeScale={(value) => {
          setScale(value);
        }}
        scale={scale}
      />
      <FlexLayout alignItems='center' flexDirection='column'>
        <Text variant={isBlissWidget ? 'bliss-text-bold' : 'text-m-bold'}>
          {t('photoEditor.photoHint.bold')}
        </Text>
        <Text
          color={colors.black06}
          sx={{ textAlign: 'center' }}
          variant={isBlissWidget ? 'bliss-text' : 'text-m-regular'}
        >
          {t('photoEditor.photoHint.regular')}
        </Text>
      </FlexLayout>
    </FlexLayout>
  );
};

export default ImageResizer;
