import { VisitType } from '../common';
import { AppointmentStatus } from '../enums';

export const appointmentStatuses = [
  {
    value: AppointmentStatus.DONE,
    label: 'Done',
    id: 0,
  },
  {
    value: AppointmentStatus.NEW,
    label: 'New',
    id: 1,
  },
  {
    value: AppointmentStatus.SCHEDULED,
    label: 'Scheduled',
    id: 2,
  },
  {
    value: AppointmentStatus.SPAM,
    label: 'Spam',
    id: 3,
  },
  {
    value: AppointmentStatus.TEST,
    label: 'Test',
    id: 4,
  },
];

export const visitTypes = [
  {
    value: VisitType.IN_PERSON,
    label: 'In Person',
    id: 0,
  },
  {
    value: VisitType.VIDEO,
    label: 'Video',
    id: 1,
  },
];
