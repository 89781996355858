import React from 'react';
import { forwardRef } from 'react';
import { Link as ThemeLink } from 'theme-ui';
import type { LinkProps as ThemeLinkProps } from 'theme-ui';

export interface LinkProps extends ThemeLinkProps {}

const Link = forwardRef<any, LinkProps>((props: LinkProps, ref) => {
  const { href, target, sx, children, ...rest } = props;

  return (
    <ThemeLink
      href={href}
      ref={ref}
      target={target}
      sx={{
        color: 'inherit',
        textDecoration: 'none',
        width: 'fit-content',
        cursor: 'pointer',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </ThemeLink>
  );
});
export default Link;
