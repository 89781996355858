import * as amplitude from '@amplitude/analytics-browser';

export enum AnalyticsEventNames {
  WidgetLoaded = 'Widget loaded',
  WidgetClicked = 'Widget clicked',
  PhotoSourceUpload = 'Photo source upload',
  PhotoSourceCamera = 'Photo source camera',
  SimulationSelectorOpened = 'Simulation Selector Opened',
  SimulationSelected = 'Simulation Selected',
  SimulationPerformed = 'Simulation Performed',
  ResultsSaved = 'Results Saved',
  ClinicContacted = 'ClinicCotacted',
  PhotoValidationError = 'Photo validation error',
  BlissWhiteningKit = 'Bliss Whitening kit',
  BlissToothbrush = 'Bliss Toothbrush',
  BlissStrips = 'Bliss Strips',
  BlissToothpaste = 'Bliss Toothpaste',
  SDK_Loaded = 'SDK Loaded',
}

export const trackAnalyticsEvent = (tag: string, params = {}) => {
  if (
    process.env.NODE_ENV !== 'development' &&
    localStorage.getItem('analytics_allowed') === 'true'
  ) {
    amplitude.track(tag, params);
  }
};
