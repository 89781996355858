import { Link, Text as ThemeText } from 'theme-ui';
import React from 'react';

import { FlexLayout, Text } from '..';
import { HttpsURLRegex } from '../../../helpers';
import { TextWithLabelProps } from '../../props';

const TextWithLabel: React.FC<TextWithLabelProps> = ({
  as = 'span',
  children,
  label,
  labelColor = 'irisBlue',
  sx,
  upperCase = false,
  link = false,
  variant = 'text-m-regular',
  color = 'secondaryArsenic',
  ...props
}) => {
  const isSocialMediaURLOrTag = (socialMediaInput: string) => {
    if (HttpsURLRegex.test(socialMediaInput))
      return (
        <Link href={socialMediaInput} target='_blank'>
          {socialMediaInput}
        </Link>
      );
    else return <Text variant='text-s-regular'>{socialMediaInput}</Text>;
  };

  const createContent = (children: any, link: boolean) => {
    return link ? isSocialMediaURLOrTag(children) : children;
  };

  return (
    <FlexLayout flexDirection='column' space={2} sx={sx}>
      {label && (
        <Text color={labelColor} variant='text-xs-medium' upperCase>
          {label}
        </Text>
      )}
      <ThemeText
        as={as}
        sx={{
          color,
          textTransform: upperCase ? 'uppercase' : null,
          fontStyle: !children && 'italic',
          variant: `text.${variant}`,
        }}
        {...props}
      >
        {children ? createContent(children, link) : 'No Data'}
      </ThemeText>
    </FlexLayout>
  );
};

export default TextWithLabel;
