export const defaultMaxFileSize = 5242880; //5 MB

export function convertKeyValueToFormData(data: any) {
  const formData = new FormData();

  for (const key in data) {
    formData.append(key, data[key]);
  }
  return formData;
}
