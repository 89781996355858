import React from 'react';
import { PageLayout } from '../pages-components';
import { colors, Button, FlexLayout, Image, Link, Text } from '../ui';
import Error500 from '../ui/assets/images/500.jpeg';

const ServerError = () => {
  return (
    <PageLayout>
      <FlexLayout
        alignItems='center'
        flexDirection='column'
        space={4}
        p={['0px', '20px', '40px', '80px']}
      >
        <Image src={Error500} height='390px' width='250px' />
        <Text variant={'text-xxxl-bold'} color={colors['irisBlue']}>
          500 Error Page
        </Text>
        <Text variant={'text-xl-regular'} color={colors['secondaryArsenic']}>
          We encountered a smile hiccup on our end.
        </Text>
        <Text variant={'text-s-regular'} color={colors['secondaryArsenic']}>
          We appreciate your understanding and apologize for any frowns caused. We&apos;re committed
          to resolving this glitch and ensuring your smile experience is nothing short of fantastic.
        </Text>
        <Link
          href={'https://www.yoursocialsmile.com'}
          sx={{ textDecoration: 'none', textAlign: 'center' }}
        >
          <Button size='standard' text={'Back to Home Page'} variant='irisBlue' />
        </Link>
      </FlexLayout>
    </PageLayout>
  );
};

export default ServerError;
