import { UpdateAppointmentDto } from '.';
import { api } from '../services';

export const createAppointment = async (data: FormData) => {
  return api.post('/appointment', data);
};

export const findWidgetAppointments = async (widgetId: string) => {
  return api.get(`/appointment/${widgetId}/widget`);
};

export const findAppointment = async (appointmentId: string) => {
  return api.get(`/appointment/${appointmentId}`);
};

export const deleteAppointment = async (appointmentId: string) => {
  return api.delete(`/appointment/${appointmentId}`);
};

export const updateAppointment = async ({
  appointmentId,
  updateAppointmentDto,
}: {
  appointmentId: string;
  updateAppointmentDto: UpdateAppointmentDto;
}) => {
  return api.patch(`/appointment/${appointmentId}`, updateAppointmentDto);
};
