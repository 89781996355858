import React, { PropsWithChildren, useState } from 'react';

import { Box } from '../ui';
import Tooltip from './Tooltip';

const ComingSoon = (props: PropsWithChildren) => {
  const { children } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Box
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      sx={{ position: 'relative' }}
    >
      <Tooltip show={showTooltip} text='Coming Soon!' />
      <Box sx={{ opacity: 0.2, pointerEvents: 'none' }}>{children}</Box>
    </Box>
  );
};

export default ComingSoon;
