import React, { Dispatch, SetStateAction } from 'react';
import _ from 'lodash';

import { Theme } from '../apis';
import { colors, FlexLayout, Icon, radii, Text } from '../ui';

const ThemeButton = ({
  theme,
  active,
  setSelectedTheme,
}: {
  theme: Theme;
  active: boolean;
  setSelectedTheme: Dispatch<SetStateAction<Theme | undefined>>;
}) => (
  <FlexLayout
    space={1}
    bg={theme.backgroundColor}
    p='8px'
    alignItems={'center'}
    sx={{ borderRadius: radii.m, cursor: 'pointer' }}
    onClick={() => setSelectedTheme(theme)}
  >
    {_.startCase(theme.name)}
    <Icon icon='check' color={active ? 'black' : theme.backgroundColor} />
  </FlexLayout>
);

const ThemesPicker = ({
  setSelectedTheme,
  selectedTheme,
  themes,
  error,
  isLoading,
}: {
  selectedTheme: Theme;
  setSelectedTheme: Dispatch<SetStateAction<Theme | undefined>>;
  themes: Theme[];
  error: boolean;
  isLoading: boolean;
}) => {
  return (
    <>
      {!error && (
        <FlexLayout flexDirection={'column'} space={2}>
          <Text color={colors.black04} variant={'text-m-regular'} upperCase={false}>
            Theme*
          </Text>

          {isLoading ? (
            <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
              <Icon icon='loading' size='xl' />
            </FlexLayout>
          ) : (
            <FlexLayout
              bg='white'
              p='14px'
              sx={{
                border: `1px solid ${colors['black04']}`,
                borderRadius: radii.m,
                fontFamily: 'Euclid Regular',
                alignItems: 'center',
                width: '600px',
              }}
              space={12}
            >
              {themes &&
                themes.map((theme) => (
                  <ThemeButton
                    key={theme.uuid}
                    theme={theme}
                    active={selectedTheme?.uuid === theme.uuid}
                    setSelectedTheme={setSelectedTheme}
                  />
                ))}
            </FlexLayout>
          )}
        </FlexLayout>
      )}
    </>
  );
};

export default ThemesPicker;
