import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import Cookies from 'universal-cookie';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { LoginFormValues, loginRequest } from '../apis';
import { PageLayout } from '../pages-components';
import {
  FlexLayout,
  colors,
  GridLayout,
  Image,
  Input,
  Text,
  Link,
  Button,
  useScreenType,
} from '../ui';
import Whitening from '../ui/assets/images/whitening-step1.jpg';

import { HttpStatusCode, CookieType } from '../enums';
import { AuthContext } from '../context';

const Login = () => {
  const { control, formState, handleSubmit } = useForm<LoginFormValues>({
    mode: 'all',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const cookies = new Cookies();
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isDesktop } = useScreenType();

  const { mutate } = useMutation(loginRequest, {
    onError: () => {
      setErrorMessage('Something went wrong. Please try again later.');
    },
    onSuccess: (response) => {
      if (response.data.statusCode === HttpStatusCode.UNAUTHORIZED)
        setErrorMessage("Email Address and Password don't match.");
      else if (response.data.statusCode === HttpStatusCode.FORBIDDEN)
        setErrorMessage('Account is not confirmed.');
      else {
        const { authToken, ...user } = response.data;

        cookies.set(CookieType.ACCESS_TOKEN, authToken, {
          maxAge: 30 * 24 * 60 * 60,
        });
        setUser(user);

        navigate(`/admin/users/${user.uuid}/dashboard`);
      }
    },
  });

  const onSubmit = async (values: LoginFormValues) => {
    setErrorMessage('');
    mutate(values);
  };

  return (
    <PageLayout
      headerItemLink={
        <FlexLayout flexDirection={'row'} alignItems={'center'} space={2}>
          <Text variant={'text-m-regular'} color={colors['black07']}>
            Don&apos;t have an account?
          </Text>
          <Link href={'/sign-up'} color={colors['blissCyan']} sx={{ textDecoration: 'none' }}>
            <Text variant={'text-m-medium'}>Sign Up</Text>
          </Link>
        </FlexLayout>
      }
    >
      <FlexLayout
        flexDirection={'column'}
        alignItems={'center'}
        space={4}
        p={['0px', '20px', '40px', '80px']}
        sx={{ width: '100%' }}
      >
        <FlexLayout flexDirection={'column'} alignItems={'center'} space={4} sx={{ pb: '40px' }}>
          <Text variant={'text-xxl-medium'}>Login</Text>
          <Text variant={'text-s-regular'}>Welcome back to Your Social Smile!</Text>
        </FlexLayout>
        <GridLayout
          alignItems={'center'}
          justifyItems={'center'}
          gridTemplateColumns={isDesktop() ? 'repeat(2,1fr)' : 'repeat(1, 1fr)'}
          gap={4}
          sx={{
            width: '100%',
            borderRadius: '8px',
            p: '80px',
            backgroundColor: `${colors['lightCyan']}`,
          }}
        >
          <FlexLayout
            alignItems={'center'}
            justifyContent={!isDesktop() ? 'center' : 'flex-end'}
            sx={{ width: '100%', height: '100%' }}
          >
            <Image src={Whitening} />
          </FlexLayout>
          <FlexLayout
            alignItems={'flex-start'}
            justifyContent={!isDesktop() ? 'center' : 'flex-start'}
            sx={{ width: '100%', height: '100%' }}
            onSubmit={handleSubmit(onSubmit)}
            as={'form'}
          >
            <FlexLayout
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              space={4}
              sx={{ height: '100%' }}
            >
              <Input
                control={control as any}
                error={
                  !!(formState.dirtyFields.email && formState.errors.email?.type === 'pattern') ||
                  !!errorMessage
                }
                label={'Email Address*'}
                message={formState.errors?.email?.message}
                name='email'
                rules={{
                  required: true,
                }}
              />
              <Input
                control={control as any}
                error={
                  !!(formState.dirtyFields.password && formState.errors.password) || !!errorMessage
                }
                icon={showPassword ? 'hidePassword' : 'showPassword'}
                label={'Password*'}
                name='password'
                rules={{ required: true }}
                type={showPassword ? 'text' : 'password'}
                onIconClick={() => setShowPassword(!showPassword)}
              />
              <FlexLayout
                alignItems={'center'}
                justifyContent={'space-between'}
                flexDirection={'row-reverse'}
                sx={{ width: '100%' }}
              >
                <Link href='/forgot-password' color={colors['irisBlue']}>
                  <Text variant={'text-m-medium'}>Forgot Password?</Text>
                </Link>
                {errorMessage && (
                  <Text color={errorMessage ? 'red' : colors['black07']} variant={'text-xs-medium'}>
                    {errorMessage}
                  </Text>
                )}
              </FlexLayout>

              <FlexLayout
                alignItems={'center'}
                justifyContent={!isDesktop ? 'center' : 'flex-start'}
                sx={{ width: '100%' }}
              >
                <Button
                  size='standard'
                  text={'Log In'}
                  variant='irisBlue'
                  isDisabled={!formState.isValid}
                  type={'submit'}
                  width={!isDesktop() ? '100%' : 'none'}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </GridLayout>
      </FlexLayout>
    </PageLayout>
  );
};

export default Login;
