import { createSlice } from '@reduxjs/toolkit';
import { WidgetDetailType } from '.';

const initialState = {
  widget: {} as WidgetDetailType,
};

const widgetSlice = createSlice({
  initialState: initialState,
  name: 'widget',
  reducers: {
    findWidgetDetailSuccess: (state, action) => {
      state.widget = action.payload;
    },
  },
});

export const { findWidgetDetailSuccess } = widgetSlice.actions;
export default widgetSlice.reducer;
