import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledSelect = styled(Select).attrs({
  classes: { icon: 'icon' },
})`
  .icon {
    fill: white !important;
  }
  height: 24px;
  color: white !important;
  &:before {
    border: none !important;
  }
`;

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState('en');

  return (
    <StyledSelect
      onChange={(event: SelectChangeEvent<unknown>) => {
        i18n.changeLanguage(event.target.value as string);
        setLanguage(event.target.value as string);
      }}
      value={language}
      variant='standard'
    >
      <MenuItem value='en'>EN</MenuItem>
      <MenuItem value='ar'>AR</MenuItem>
    </StyledSelect>
  );
};

export default LanguageSelector;
