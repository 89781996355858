import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { useQuery } from '@tanstack/react-query';

import {
  createUserSubscription,
  deleteUserSubscription,
  getUserSubscription,
  SubscriptionType,
  deleteTransaction,
  findAllPlans,
  Plan,
} from '../apis';
import { AuthContext } from '../context/auth';
import { FlexLayout, Icon, Text, showToast } from '../ui';
import { UserType, ToastType, PlanType } from '../enums';
import {
  AdminPageLayout,
  AdminPageTitle,
  SubscriptionCard,
  SubscriptionPlan,
  InfoBox,
} from '../pages-components';

const UserSubscriptions = () => {
  const [isCardElementOpened, setIsCardElementOpened] = useState<boolean>(false);
  const [userSubscription, setUserSubscription] = useState<SubscriptionType>();
  const { user, setUser } = useContext(AuthContext);
  const stripe = useStripe();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan>();

  const elements = useElements();

  useEffect(() => {
    if (user)
      getUserSubscription({ userId: user?.uuid as string }).then((res) => {
        setUserSubscription(res.data);
      });
  }, [user]);

  const { isLoading } = useQuery(['findAllPlans'], () => findAllPlans(), {
    onSuccess: ({ data }) => {
      setPlans(data);
    },
    onError: () => {
      showToast({
        title: 'Something went wrong. Please try again later.',
        toastType: ToastType.ERROR,
      });
    },
    retry: false,
  });

  const { mutate: mutateCreate } = useMutation(createUserSubscription, {
    onError: () => {
      showToast({
        title: 'Something went wrong. Please try again later.',
        toastType: ToastType.ERROR,
      });
    },
    onSuccess: async (res) => {
      const confirmPayment = await stripe?.confirmCardPayment(res.data.clientSecret);
      if (confirmPayment?.error) {
        showToast({
          title: 'Something went wrong. Please try again later.',
          toastType: ToastType.ERROR,
        });
        mutateDelete({ userId: user?.uuid as string });
        mutateDeleteTransaction(res.data.transactionId);
      } else {
        setUser({ ...user, type: UserType.Paying });
        showToast({
          title: 'Changes saved successfully.',
          toastType: ToastType.INFO,
        });
      }
      setIsCardElementOpened(false);
    },
  });

  const { mutate: mutateDelete } = useMutation(deleteUserSubscription, {
    onError: () => {
      showToast({
        title: 'Something went wrong. Please try again later.',
        toastType: ToastType.ERROR,
      });
    },
    onSuccess: () => {
      showToast({
        title: 'Changes saved successfully.',
        toastType: ToastType.INFO,
      });
      setUser({ ...user, type: UserType.Free });
      setIsCardElementOpened(false);
    },
  });

  const { mutate: mutateDeleteTransaction } = useMutation(deleteTransaction, {
    onError: () => {
      showToast({
        title: 'Something went wrong. Please try again later.',
        toastType: ToastType.ERROR,
      });
    },
    onSuccess: () => {
      showToast({
        title: 'Changes saved successfully.',
        toastType: ToastType.INFO,
      });
    },
  });

  const handleCreateSubscription = async () => {
    try {
      const paymentMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: elements?.getElement(CardElement)!,
      });

      mutateCreate({
        userId: user?.uuid as string,
        data: {
          amount: selectedPlan?.price as number,
          currency: 'USD',
          paymentMethodId: paymentMethod?.paymentMethod?.id as string,
          type: selectedPlan?.type as PlanType,
        },
      });
    } catch (err) {
      showToast({
        title: 'Something went wrong. Please try again later.',
        toastType: ToastType.ERROR,
      });
    }
  };

  return (
    <AdminPageLayout>
      <FlexLayout flexDirection={'column'} alignItems={'flex-start'} sx={{ width: '100%' }}>
        <AdminPageTitle title={'Subscriptions'} icon={'card'} />
        <FlexLayout
          alignItems={'center'}
          flexDirection={'column'}
          space={4}
          sx={{ width: '100%', alignSelf: 'center' }}
        >
          {!user || isLoading ? (
            <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
              <Icon icon='loading' size='xl' />
            </FlexLayout>
          ) : (
            <FlexLayout
              alignItems={'flex-start'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              py={4}
              space={3}
              sx={{ width: '100%' }}
            >
              <Text
                variant={'text-s-medium'}
                color={'secondaryArsenic'}
                sx={{ alignSelf: 'flex-start' }}
              >
                Search through the subsbription tiers here and choose what suits your clinic the
                most!
              </Text>
              <InfoBox
                bg={user?.type === UserType.Free ? 'secondaryArsenic' : 'irisBlue'}
                content={
                  user?.type === UserType.Free
                    ? `You still don't have a subscription on Your Social Smile.`
                    : 'You are successfully subscribed on Your Social Smile.'
                }
                icon={'exclamationMark'}
              />
              {!isCardElementOpened ? (
                <FlexLayout
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  flexDirection={'column'}
                  sx={{ width: '100%' }}
                >
                  <FlexLayout
                    alignItems={'flex-start'}
                    justifyContent={'flex-start'}
                    space={4}
                    sx={{ width: '100%' }}
                  >
                    {plans &&
                      plans.map((plan, index) => (
                        <SubscriptionPlan
                          key={index}
                          mutateDelete={mutateDelete}
                          plan={plan}
                          setIsCardElementOpened={setIsCardElementOpened}
                          setSelectedPlan={setSelectedPlan}
                          userSubscription={userSubscription as SubscriptionType}
                        />
                      ))}
                  </FlexLayout>
                </FlexLayout>
              ) : (
                <SubscriptionCard
                  stripe={stripe as Stripe}
                  setIsCardElementOpened={setIsCardElementOpened}
                  handleCreateSubscription={handleCreateSubscription}
                />
              )}
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </AdminPageLayout>
  );
};

export default UserSubscriptions;
