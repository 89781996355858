import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { findWidgetDetail, findWidgetDetailSuccess } from '../apis';
import { RootState } from '../store';
import { Procedures } from '../common';
import { PhotoEditorProvider, usePhotoEditor } from '../context';
import { useAnalyticsOnLoad, useIsBlissWidget } from '../hooks';
import GenericModal from '../pages-components/GenericModal';
import {
  HeaderProcedureTabs,
  PhotoUploadFlow,
  Procedure,
  AppointmentModalContent,
  DisclaimerModal,
  EmailModalContent,
  PhotoErrorModal,
  PrivacyAndTermsModals,
} from '../pages-components';
import { Box, FlexLayout, Header, Icon, Text, TextIcon } from '../ui';
import { AnalyticsEventNames } from '../utils/analytics';
import { AxiosResponse } from 'axios';

const PhotoEditor = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { slug, widgetId } = useParams();
  const dispatch = useDispatch();

  const widget = useSelector((state: RootState) => state.widget.widget);

  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [isEmailPhotoModalOpen, setIsEmailPhotoModalOpen] = useState(false);

  const { onChangeProcedure } = usePhotoEditor();

  useAnalyticsOnLoad(AnalyticsEventNames.WidgetLoaded, { widgetID: widget.uuid });

  const isBlissWidget = useIsBlissWidget();

  const { t } = useTranslation();

  useEffect(() => {
    if (slug && widgetId) {
      findWidgetDetail(widgetId).then((res: AxiosResponse) => {
        if (res.data.text === slug) {
          dispatch(findWidgetDetailSuccess(res.data));
        } else {
          navigate('/');
        }
      });

      if (isBlissWidget) {
        onChangeProcedure(Procedures.Whitening);
      }

      if (cookies.get('procedure') === undefined) {
        navigate(`/${slug}/${widgetId}`);
      }
    }
  }, [slug, widgetId]);

  return (
    <>
      {widget.user && (
        <Box
          sx={{
            backgroundColor: widget.user.theme.backgroundColor,
            minHeight: '100vh',
          }}
        >
          <GenericModal
            content={
              <EmailModalContent
                onClose={() => {
                  setIsEmailPhotoModalOpen(false);
                }}
              />
            }
            positionX='center'
            positionY='center'
            showModal={isEmailPhotoModalOpen}
          />
          <GenericModal
            content={
              <AppointmentModalContent
                onClose={() => {
                  setIsAppointmentModalOpen(false);
                }}
                onReturnToWeb={() => {
                  setIsAppointmentModalOpen(false);
                  navigate('/');
                }}
                onTryAgain={() => {
                  setIsAppointmentModalOpen(false);
                  navigate(`/${slug}/${widgetId}`);
                }}
              />
            }
            notInCentre
            positionX='flex-end'
            positionY='flex-end'
            showModal={isAppointmentModalOpen}
          />
          <PrivacyAndTermsModals />
          <PhotoErrorModal />
          <DisclaimerModal />
          <FlexLayout flexDirection='column' px={[5, 9]} space={[0, 6]}>
            {isBlissWidget ? (
              <FlexLayout alignItems={'flex-start'} flexDirection={'column'} space={4}>
                <Box pt={4}>
                  <TextIcon
                    color='blissBlack'
                    icon='subtract'
                    iconSize='m'
                    space={2}
                    text='Your Social Smile'
                    textVariant='text-s-medium'
                  />
                </Box>
                <FlexLayout
                  alignItems={'center'}
                  onClick={() => window.open('https://blissoralcare.com.au/', '_blank')}
                  space={2}
                  sx={{ cursor: 'pointer' }}
                >
                  <Icon
                    color={'blissBlack'}
                    icon={'arrowLeft'}
                    size={'m'}
                    sx={{ cursor: 'pointer' }}
                  />
                  <Text color={'blissBlack'} variant='text-s-medium'>
                    Continue Shopping
                  </Text>
                </FlexLayout>
              </FlexLayout>
            ) : (
              <Header
                icon='close'
                onBackAction={() => navigate(`/${slug}/${widgetId}`)}
                text={t('common.virtualTryTitle')}
              >
                <HeaderProcedureTabs />
              </Header>
            )}
            <PhotoUploadFlow />
            <Procedure
              onEmailPhoto={() => setIsEmailPhotoModalOpen(true)}
              onScheduleAppointment={() => setIsAppointmentModalOpen(true)}
            />
          </FlexLayout>
        </Box>
      )}
    </>
  );
};

const PhotoEditorWithContext = () => (
  <PhotoEditorProvider>
    <PhotoEditor />
  </PhotoEditorProvider>
);

export default PhotoEditorWithContext;
