import store from '../../store';
import * as icons from '../assets/icons';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { radii } from './radii';
import { sizes } from './sizes';
import space from './space';
import { typography, variants as text } from './typography';

export const theme = {
  breakpoints,
  colors: {
    primary:
      store.getState().widget.widget.user &&
      store.getState().widget.widget.user.theme.backgroundColor,
    secondary:
      store.getState().widget.widget.user &&
      store.getState().widget.widget.user.theme.foregroundColor,
    ...colors,
  },
  forms: {
    radio: {
      color: `${colors.black} !important`,
      height: '18px',
      margin: 0,
      width: '18px',
    },
  },
  radii,
  sizes,
  space,
  text,
  ...typography,
};

export * from './colors';
export * from './radii';

export type ExactTheme = typeof theme;
export type TextVariant = keyof typeof text;
export type IconType = keyof typeof icons;
export type Radius = keyof typeof radii;
