import { FlexLayout, Icon } from '..';
import withDropdown from '../../../hocs/withDropdown';
import React from 'react';

interface Props {
  clicked?: boolean;
}

const ThreeDotActionMenu: React.FC<Props> = ({ clicked }) => {
  return (
    <FlexLayout alignItems='center' justifyContent='center' py={4} sx={{ cursor: 'pointer' }}>
      <Icon color={clicked ? 'irisBlue' : 'secondaryArsenic'} icon='threeDot' size='l' />
    </FlexLayout>
  );
};

export default withDropdown(ThreeDotActionMenu);
