import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Procedures } from '../common';
import { usePhotoEditor } from '../context';
import { FlexLayout, IconType, Text, useScreenType } from '../ui';
import { ComingSoon } from '.';
import VeneerTypeButton from './VeneerTypeButton';

interface VeneerTypeItem {
  icon: IconType;
  name: string;
  procedureJsonName: string;
}

const VENEER_TYPES: VeneerTypeItem[] = [
  { icon: 'veneerWarm' as IconType, name: 'Veneers 1', procedureJsonName: 'veneersD' },
  { icon: 'veneerFresh' as IconType, name: 'Veneers 2', procedureJsonName: 'veneersF' },
];

const VeneersProcedure = () => {
  const { isMobile } = useScreenType();
  const { t } = useTranslation();
  const { procedure, setResultImage, croppedImage, setIsLoading } = usePhotoEditor();

  const changeVeneersHandler = (image: string, selectedProcedure: string) => {
    window.changeProcedure(selectedProcedure).then(() => {
      window
        .runVeneers(image)
        .then((res: string) => {
          setResultImage(res);
          return res;
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const debounceVeneersChange = useMemo(() => debounce(changeVeneersHandler, 500), []);

  const [selectedVeneerType, setSelectedVeneerType] = useState<string>(VENEER_TYPES[0].name);

  if (procedure === Procedures.Veneers) {
    return (
      <FlexLayout alignItems='center' flexDirection='column' pb={4} sx={{ position: 'relative' }}>
        <FlexLayout flexDirection='column' sx={{ width: '100%' }}>
          <FlexLayout justifyContent='space-between' sx={{ overflowX: 'scroll' }}>
            <Text variant='text-m-regular'>{t('veneersProcedure.styleLabel')}</Text>
          </FlexLayout>
          <FlexLayout
            sx={{
              overflow: 'scroll',
              width: '100%',
            }}
          >
            <FlexLayout
              flexDirection='row'
              flexWrap={isMobile() ? 'nowrap' : 'wrap'}
              mt={2}
              space={2}
              sx={{
                height: isMobile() && '80px',
                minWidth: isMobile() && 'fit-content',
              }}
            >
              {VENEER_TYPES.map((veneer) => {
                if (!veneer.procedureJsonName) {
                  return (
                    <ComingSoon key={veneer.name}>
                      <VeneerTypeButton
                        icon={veneer.icon}
                        onClick={() => {
                          setSelectedVeneerType(veneer.name);
                        }}
                        selected={selectedVeneerType === veneer.name}
                        text={t(veneer.name)}
                      />
                    </ComingSoon>
                  );
                }

                return (
                  <VeneerTypeButton
                    icon={veneer.icon}
                    key={veneer.name}
                    onClick={() => {
                      setSelectedVeneerType(veneer.name);
                      debounceVeneersChange(croppedImage!, veneer.procedureJsonName);
                    }}
                    selected={selectedVeneerType === veneer.name}
                    text={t(veneer.name)}
                  />
                );
              })}
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    );
  }

  return null;
};

export default VeneersProcedure;
