import 'react-circular-progressbar/dist/styles.css';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgressbar } from 'react-circular-progressbar';

import { colors, FlexLayout, radii, Text } from '../ui';
import { usePhotoEditor } from '../context';
import { useIsBlissWidget } from '../hooks';

const ResultLoadingIndicator = () => {
  const isBlissWidget = useIsBlissWidget();

  const { t } = useTranslation();

  const { procedure, progressBarValue, setProgressBarValue } = usePhotoEditor();

  useEffect(() => {
    const interval = setInterval(() => {
      if (progressBarValue < 100) {
        setProgressBarValue((prevValue) => prevValue + 1);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [progressBarValue, procedure]);

  return (
    <FlexLayout
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      space={4}
      sx={{
        width: '100%',
      }}
    >
      <FlexLayout
        alignItems='center'
        justifyContent='center'
        sx={{
          background: isBlissWidget ? colors.blissBlack : colors.black04,
          borderRadius: radii.xxxl,
          height: '128px',
          p: 2,
          width: '128px',
        }}
      >
        <CircularProgressbar
          maxValue={100}
          minValue={0}
          styles={{
            background: {
              background: 'transparent',
            },
            path: {
              stroke: isBlissWidget ? colors.blissCyan : colors.white,
              strokeWidth: '4px',
            },
            trail: {
              stroke: colors.black06,
              strokeWidth: '4px',
            },
          }}
          value={progressBarValue}
        />
      </FlexLayout>
      <Text
        color={colors.black06}
        sx={{ textAlign: 'center' }}
        variant={isBlissWidget ? 'bliss-text' : 'text-m-regular'}
      >
        {t('photoEditor.photoProcessingText')}
      </Text>
    </FlexLayout>
  );
};

export default ResultLoadingIndicator;
