export interface IPaginationRequest {
  page: number;
  take: number;
}

export interface IPaginationResponse<T> {
  totalItems: number;
  totalPages: number;
  result: T[];
}

export interface IPaginationResponseWithHeader<T> {
  header: { page: number; take: number; totalItems: number; totalPages: number };
  result: T[];
}

const PAGINATION_DEFAULT: IPaginationRequest = {
  page: 1,
  take: 10,
};

const PAGINATION_DEFAULT_RESPONSE: IPaginationResponse<any> = {
  totalItems: 0,
  totalPages: 1,
  result: [],
};

export const canNextPage = (request: IPaginationRequest, response: IPaginationResponse<any>) => {
  return request.page !== response?.totalPages;
};

export const goToPage = (
  pageNumber: number,
  setRequest: React.Dispatch<React.SetStateAction<IPaginationRequest>>,
  request: IPaginationRequest & any,
) => {
  setRequest({ ...request, page: pageNumber });
};

export { PAGINATION_DEFAULT, PAGINATION_DEFAULT_RESPONSE };
