import React from 'react';
import { useNavigate } from 'react-router';
import { PageLayout } from '../pages-components';
import { Routes } from '../routes/routes';
import { Button, colors, FlexLayout, Image, Link, Text } from '../ui';
import Error404 from '../ui/assets/images/404.jpeg';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <PageLayout
      headerItemLink={
        <FlexLayout alignItems='center' flexDirection='row' space={2}>
          <Text variant='text-m-regular' color={colors['black07']}>
            Don&apos;t have an account?
          </Text>
          <Link href='/sign-up' color={colors['blissCyan']} sx={{ textDecoration: 'none' }}>
            <Text variant='text-m-medium'>Sign Up</Text>
          </Link>
        </FlexLayout>
      }
    >
      <FlexLayout
        alignItems='center'
        flexDirection='column'
        space={4}
        p={['0px', '20px', '40px', '80px']}
      >
        <Image src={Error404} height='390px' width='250px' />
        <Text variant={'text-xxxl-bold'} color={colors['irisBlue']}>
          Hoop to Login!
        </Text>
        <Text variant={'text-xl-regular'} color={colors['secondaryArsenic']}>
          Whoops! This page is here to safely guide you back home.
        </Text>
        <Button
          size='standard'
          text={'Back to Login Page'}
          variant='irisBlue'
          onClick={() => navigate(Routes.Login)}
        />
      </FlexLayout>
    </PageLayout>
  );
};

export default NotFound;
