import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { findUserWidgetsByUserId, WidgetType } from '../apis';
import { AuthContext } from '../context';
import { ToastType } from '../enums';
import { Procedures } from '../common';
import {
  Box,
  colors,
  IconType,
  radii,
  FlexLayout,
  GridLayout,
  Icon,
  Text,
  showToast,
  useScreenType,
} from '../ui';
import AmplitudeCover from '../ui/assets/images/amplitude-cover.png';
import { AdminPageLayout, AdminPageTitle } from '../pages-components';

const UserDashboard = () => {
  const [widgets, setWidgets] = useState<WidgetType[]>();
  const [bracesAppointmentsCount, setBracesAppointmentsCount] = useState<number>(0);
  const [veneersAppointmentsCount, setVeneersAppointmentsCount] = useState<number>(0);
  const [whiteningAppointmentsCount, setWhiteningAppointmentsCount] = useState<number>(0);
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isDesktop } = useScreenType();

  useQuery(
    ['findUserWidgetsByUserId', user?.uuid],
    () => findUserWidgetsByUserId(user?.uuid as string),
    {
      onSuccess: ({ data }) => {
        setWidgets(data);
      },
      onError: () => {
        showToast({
          title: 'Something went wrong. Please try again later.',
          toastType: ToastType.ERROR,
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
      enabled: !!user,
      retry: false,
    },
  );

  const countAppointments = useCallback(() => {
    let bracesCount = 0;
    let veneersCount = 0;
    let whiteningCount = 0;

    widgets &&
      widgets.length > 0 &&
      widgets.forEach((widget: WidgetType) => {
        widget.appointments &&
          widget.appointments.length > 0 &&
          widget.appointments.forEach((appointment) => {
            switch (appointment.simulationType) {
              case Procedures.Braces:
                bracesCount++;
                break;
              case Procedures.Veneers:
                veneersCount++;
                break;
              case Procedures.Whitening:
                whiteningCount++;
                break;
              default:
                break;
            }
          });
      });

    setBracesAppointmentsCount(bracesCount);
    setVeneersAppointmentsCount(veneersCount);
    setWhiteningAppointmentsCount(whiteningCount);
  }, [widgets]);

  useEffect(() => {
    countAppointments();
  }, [widgets, countAppointments]);

  return (
    <AdminPageLayout>
      <FlexLayout flexDirection={'column'} alignItems={'flex-start'} sx={{ width: '100%' }}>
        <FlexLayout alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <AdminPageTitle title={'Your Dashboard'} icon={'menu'} />
        </FlexLayout>
        <>
          {isLoading ? (
            <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
              <Icon icon='loading' size='xl' />
            </FlexLayout>
          ) : (
            <FlexLayout
              alignItems={'flex-start'}
              flexDirection={'column'}
              space={1}
              sx={{ width: '100%' }}
            >
              <FlexLayout
                alignItems={'flex-start'}
                flexDirection={'column'}
                justifyContent={'flex-start'}
                py={4}
                space={3}
                sx={{ width: '100%' }}
              >
                <Text variant={'text-s-medium'} color={colors['secondaryArsenic']}>
                  Welcome to your dashboard! Check out new apppointments!
                </Text>
                {bracesAppointmentsCount === 0 &&
                whiteningAppointmentsCount === 0 &&
                veneersAppointmentsCount === 0 ? (
                  <FlexLayout
                    alignItems={'center'}
                    backgroundColor={'white'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    p={4}
                    space={4}
                    sx={{
                      padding: '16px 0px',
                      minHeight: '10vh',
                      minWidth: '100%',
                      border: `1px solid ${colors['black008']}`,
                      borderRadius: radii.xs,
                    }}
                  >
                    <Text
                      color='secondaryArsenic'
                      variant={'text-l-regular'}
                      sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
                    >
                      We don&apos;t have any appointments so far.
                    </Text>
                    <Text
                      color='secondaryArsenic'
                      sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
                      variant={'text-s-regular'}
                    >
                      Currently there are no scheduled appointments for any of the widgets connected
                      to this user.
                    </Text>
                  </FlexLayout>
                ) : (
                  <GridLayout
                    alignItems={'flex-start'}
                    gridTemplateColumns={!isDesktop() ? 'repeat(1, 1fr)' : `repeat(${3}, 1fr)`}
                    gap={4}
                    sx={{
                      width: '100%',
                    }}
                  >
                    {[
                      {
                        count: whiteningAppointmentsCount,
                        name: Procedures.Whitening,
                        icon: 'aperture',
                        color: 'irisBlue',
                      },
                      {
                        count: veneersAppointmentsCount,
                        name: Procedures.Veneers,
                        icon: 'threeDot',
                        color: 'navy',
                      },
                      {
                        count: bracesAppointmentsCount,
                        name: Procedures.Braces,
                        icon: 'textUnderline',
                        color: 'orange',
                      },
                    ].map((item, index) => (
                      <FlexLayout
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        key={index}
                        sx={{
                          backgroundColor: 'white',
                          width: '100%',
                          border: `1px solid ${colors['black008']}`,
                          borderRadius: radii.xs,
                        }}
                      >
                        <FlexLayout
                          flexDirection={'row'}
                          alignItems={'flex-start'}
                          justifyContent={'space-between'}
                          sx={{ width: '100%', p: '24px' }}
                        >
                          <FlexLayout
                            alignItems={'center'}
                            p={2}
                            backgroundColor={item.color}
                            sx={{ borderRadius: radii.s }}
                          >
                            <Icon icon={item.icon as IconType} size={'m'} color={colors['white']} />
                          </FlexLayout>
                          <FlexLayout
                            flexDirection={'column'}
                            alignItems={'flex-end'}
                            space={2}
                            sx={{ width: '100%' }}
                          >
                            <Text variant={'text-xl-regular'}>{item.count}</Text>
                            <Text variant={'text-s-regular'} color={colors['black03']}>
                              Product usage:{' '}
                              {item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}
                            </Text>
                          </FlexLayout>
                        </FlexLayout>
                      </FlexLayout>
                    ))}
                  </GridLayout>
                )}
              </FlexLayout>
              {/*  <iframe
                src='https://app.amplitude.com/analytics/share/5f728069309b4fc7b69323d831c76e2d'
                frameBorder={0}
                style={{ width: '100%', height: '80vh' }}
              ></iframe> */}
              <FlexLayout
                alignItems={'center'}
                flexDirection={'column'}
                justifyContent={'center'}
                p={4}
                space={4}
                sx={{
                  borderRadius: radii.xs,
                  backgroundImage: `url(${AmplitudeCover})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  position: 'relative',
                  padding: '16px 0px',
                  minHeight: '48vh',
                  minWidth: '100%',
                  border: `1px solid ${colors['black008']}`,
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    border: `1px solid ${colors['black008']}`,
                    borderRadius: radii.xs,
                    backgroundPosition: 'top',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.35)',
                  }}
                />
                <Text
                  color={'white'}
                  variant={'text-xxl-medium'}
                  sx={{ textAlign: 'center', whiteSpace: 'pre-wrap', zIndex: 2 }}
                >
                  Coming Soon!
                </Text>
                <Text
                  color='white'
                  sx={{ textAlign: 'center', whiteSpace: 'pre-wrap', zIndex: 2 }}
                  variant={'text-m-regular'}
                >
                  Analytics would be integrated inside Your Social Smile very soon to help you track
                  eveeything about your clinic.
                </Text>
              </FlexLayout>
            </FlexLayout>
          )}
        </>
      </FlexLayout>
    </AdminPageLayout>
  );
};

export default UserDashboard;
