import React from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { headerItems, Routes } from '../routes/routes';
import { Box, colors, FlexLayout, Icon, Image, Link, Text } from '../ui';
import Logo from '../ui/assets/images/Logo.png';
import { LanguageSelect } from './';

interface Props {
  onCloseItemClick: () => void;
  headerItemLink: React.ReactNode;
}

const MobileHeader = ({ onCloseItemClick, headerItemLink }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <FlexLayout
      flexDirection='column'
      backgroundColor={'white'}
      justifyContent='space-between'
      sx={{
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
        top: 0,
        zIndex: '100',
      }}
    >
      <FlexLayout flexDirection='column' sx={{ minHeight: '0px' }}>
        <FlexLayout justifyContent='space-between' alignItems={'center'} py={3} px={[6, 6, 8]}>
          <Box>
            <Link href={'/'}>
              <Image src={Logo} />
            </Link>
          </Box>
          <Icon
            color={colors['black07']}
            icon='close'
            size='l'
            onClick={() => onCloseItemClick()}
            sx={{ cursor: 'pointer' }}
          />
        </FlexLayout>
        <FlexLayout
          as='nav'
          flexDirection='column'
          px={[6, 8]}
          py={8}
          space={4}
          sx={{ minHeight: '0px', overflowY: 'scroll' }}
        >
          {headerItems.map(
            (item, index) =>
              item.link !== Routes.Login && (
                <Text
                  color={location.pathname === item.link ? colors['blissCyan'] : colors['black07']}
                  key={index}
                  variant={location.pathname === item.link ? 'text-m-medium' : 'text-m-regular'}
                  onClick={() => navigate(item.link)}
                  sx={{ cursor: 'pointer' }}
                >
                  {item.name}
                </Text>
              ),
          )}
          {headerItemLink}
          <LanguageSelect />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export default MobileHeader;
