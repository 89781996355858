import React from 'react';
import { BoxProps, Box } from 'theme-ui';
import { CustomerHeader, Footer } from '.';
import { FlexLayout } from '../ui';

export interface PageLayoutProps extends BoxProps {
  headerItemLink?: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children, headerItemLink }) => {
  return (
    <FlexLayout
      flexDirection={'column'}
      sx={{
        minHeight: '100vh',
        width: '100%',
        position: 'relative',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CustomerHeader headerItemLink={headerItemLink} />
        <Box sx={{ width: '100%' }}>{children}</Box>
      </Box>
      <Footer />
    </FlexLayout>
  );
};

export default PageLayout;
