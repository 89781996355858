import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../store';
import { Procedures } from '../common/procedures';
import { usePhotoEditor } from '../context/photoEditor';
import { colors, FlexLayout, radii, Text } from '../ui';
import ComingSoon from './ComingSoon';
import BracesColorButton from './BracesColorButton';
import BracesTypeButton from './BracesTypeButton';

const BRACES_TYPES = [
  'bracesProcedure.ceramic',
  'bracesProcedure.metalic',
  'bracesProcedure.clear',
];

interface BraceColorItem {
  color: string;
  name: string;
  procedureJsonName: string;
}

const CERAMIC_BRACES_COLORS: BraceColorItem[] = [
  {
    color: '#F5F5F5',
    name: 'Ceramic Off White',
    procedureJsonName: 'bracesCeramicOffWhite',
  },
  {
    color: '#FFF5E1',
    name: 'Ceramic Creamy',
    procedureJsonName: 'bracesCeramicCreamy',
  },
];

const METALIC_BRACES_COLORS: BraceColorItem[] = [
  {
    color: '#000000',
    name: 'Metal Black',
    procedureJsonName: 'bracesMetalBlack',
  },
  {
    color: '#FFC3D5',
    name: 'Bubblegum',
    procedureJsonName: 'bracesMetalBubbleGum',
  },
  {
    color: '#FF69B4',
    name: 'Pink',
    procedureJsonName: 'bracesMetalFlourescentPink',
  },
  {
    color: '#000080',
    name: 'Navy Blue',
    procedureJsonName: 'bracesMetalNavyBlue',
  },
  {
    color: '#FF0000',
    name: 'Red',
    procedureJsonName: 'bracesMetalRed',
  },
  {
    color: '#008080',
    name: 'Teal',
    procedureJsonName: 'bracesMetalTeal',
  },
  {
    color: '#808080',
    name: 'Gray',
    procedureJsonName: 'bracesMetallicGray',
  },
  {
    color: '#ADD8E6',
    name: 'Light Blue',
    procedureJsonName: 'bracesMetalLightBlue',
  },
];

const CLEAR_BRACES_COLORS: BraceColorItem[] = [
  {
    color: '#F4F5F0',
    name: 'Clear',
    procedureJsonName: 'clearAlignersA',
  },
];

const BracesProcedure = () => {
  const { procedure, setResultImage, croppedImage } = usePhotoEditor();

  const { t } = useTranslation();

  const theme = useSelector((state: RootState) => state.widget.widget.user.theme);

  const [selectedBracesType, setSelectedBracesType] = useState(BRACES_TYPES[0]);
  const [selectedBracesColor, setSelectedBracesColor] = useState(CERAMIC_BRACES_COLORS[0]);

  useEffect(() => {
    if (selectedBracesType === 'bracesProcedure.ceramic') {
      setSelectedBracesColor(CERAMIC_BRACES_COLORS[0]);
    } else if (selectedBracesType === 'bracesProcedure.metalic') {
      setSelectedBracesColor(METALIC_BRACES_COLORS[0]);
    } else {
      setSelectedBracesColor(CLEAR_BRACES_COLORS[0]);
    }
  }, [selectedBracesType]);

  const changeBracesHandler = (image: string, selectedProcedure: string) => {
    window.changeProcedure(selectedProcedure).then(() => {
      window.runBraces(image).then((res: string) => {
        const imgDataSrc = res;
        setResultImage(imgDataSrc);
      });
    });
  };

  const debounceBracesChange = useMemo(() => debounce(changeBracesHandler, 500), []);

  const onBraceTypeChange = (type: string) => {
    setSelectedBracesType(type);

    debounceBracesChange(
      croppedImage!,
      type === BRACES_TYPES[0]
        ? CERAMIC_BRACES_COLORS[0].procedureJsonName
        : type === BRACES_TYPES[1]
        ? METALIC_BRACES_COLORS[0].procedureJsonName
        : CLEAR_BRACES_COLORS[0].procedureJsonName,
    );
  };

  const onBraceColorChange = (color: BraceColorItem) => {
    setSelectedBracesColor(color);

    debounceBracesChange(croppedImage!, color.procedureJsonName);
  };

  const bracesColors =
    selectedBracesType === BRACES_TYPES[0]
      ? CERAMIC_BRACES_COLORS
      : selectedBracesType === BRACES_TYPES[1]
      ? METALIC_BRACES_COLORS
      : CLEAR_BRACES_COLORS;

  if (procedure === Procedures.Braces) {
    return (
      <FlexLayout alignItems='center' flexDirection='column' pb={4} sx={{ position: 'relative' }}>
        <FlexLayout flexDirection='column' sx={{ width: '100%' }}>
          <Text variant='text-m-regular'>{t('bracesProcedure.typeLabel')}</Text>
          <FlexLayout
            alignItems='center'
            justifyContent='space-between'
            mt={2}
            space={2}
            sx={{
              backgroundColor: theme.foregroundColor,
              borderRadius: radii.xxxxxxl,
              boxSizing: 'border-box',
              p: '6px',
              width: '100%',
            }}
          >
            {BRACES_TYPES.map((brace) => (
              <BracesTypeButton
                isSelected={selectedBracesType === brace}
                key={brace}
                onClick={() => onBraceTypeChange(brace)}
                text={t(brace)}
              />
            ))}
          </FlexLayout>
          <FlexLayout justifyContent='space-between' mt={32}>
            <Text variant='text-m-regular'>{t('bracesProcedure.colorLabel')}</Text>
            <Text color={colors.black06} variant='text-s-regular'>
              {t(selectedBracesColor.name)}
            </Text>
          </FlexLayout>
          <FlexLayout mt={16}>
            {bracesColors.map((item) => {
              if (item.procedureJsonName) {
                return (
                  <BracesColorButton
                    color={item.color}
                    isSelected={item.color === selectedBracesColor.color}
                    key={item.color}
                    onClick={() => onBraceColorChange(item)}
                  />
                );
              } else {
                return (
                  <ComingSoon key={item.color}>
                    <BracesColorButton
                      color={item.color}
                      isSelected={item.color === selectedBracesColor.color}
                      onClick={() => onBraceColorChange(item)}
                    />
                  </ComingSoon>
                );
              }
            })}
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    );
  }

  return null;
};

export default BracesProcedure;
