import { LoginFormValues } from '.';
import { api } from '../services';

export const loginRequest = (data: LoginFormValues) => {
  return api.post('/auth/login', data);
};

export const checkPassword = async (password: string) => {
  return api.post('/auth/check-password', { password });
};
