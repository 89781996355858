import { ThemeUICSSObject } from '@theme-ui/css';
import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import { FlexLayout, Icon, Text } from '..';

import { RootState } from '../../../store';
import { BoxProps, IconSize } from '../../props';
import { colors, IconType, TextVariant } from '../../theme';

export interface ButtonVariantStyle {
  bg?: string;
  color: string;
  border: string;
  disabledStyles?: {
    color?: string;
    bg?: string;
    opacity?: number;
    border?: string;
  };
}

export const buttonVariantsMap: Record<string, ButtonVariantStyle> = {
  primary: {
    bg: colors.black,
    border: 'none',
    color: colors.white,
    disabledStyles: { opacity: 0.5 },
  },
  secondary: {
    border: 'none',
    color: colors.black,
    disabledStyles: { color: colors.black03 },
  },
  transparent: {
    bg: 'transparent',
    border: 'none',
    color: colors.black,
    disabledStyles: { opacity: 0.5 },
  },
  white: {
    bg: colors.white,
    border: `1px solid ${colors['neutral']}`,
    color: colors.black,
    disabledStyles: { bg: colors.black03 },
  },
  irisBlue: {
    bg: colors.irisBlue,
    border: 'none',
    color: colors.white,
    disabledStyles: { opacity: 0.5 },
  },
  red: {
    bg: colors.white,
    border: `2px solid ${colors['red']}`,
    color: `${colors['red']}`,
    disabledStyles: { opacity: 0.5 },
  },
  blissPrimary: {
    bg: colors.blissCyan,
    border: 'none',
    color: colors.blissBlack,
    disabledStyles: { opacity: 0.5 },
  },
  blissSecondary: {
    bg: colors.blissBlack,
    border: 'none',
    color: colors.blissOffWhite,
    disabledStyles: { opacity: 0.5 },
  },
  blissWhite: {
    bg: colors.blissOffWhite,
    border: 'none',
    color: colors.blissBlack,
    disabledStyles: { opacity: 0.5 },
  },
};

export type ButtonVariant = keyof typeof buttonVariantsMap;

const useButtonVariantStyle = (variant: ButtonVariant) => {
  return buttonVariantsMap[variant];
};

const sizesMap = {
  xl: {
    borderRadius: '200px',
    height: '56px',
    iconSize: 'xl' as IconSize,
    px: 5,
    py: 0,
    textVariant: 'text-m-regular' as TextVariant,
  },
  l: {
    borderRadius: '200px',
    height: '56px',
    iconSize: 'm' as IconSize,
    px: 5,
    py: 0,
    textVariant: 'text-m-regular' as TextVariant,
  },
  standard: {
    borderRadius: '8px',
    height: '46px',
    iconSize: 'xl' as IconSize,
    px: 6,
    py: 3,
    textVariant: 'text-m-regular' as TextVariant,
  },
  m: {
    borderRadius: '200px',
    height: '40px',
    iconSize: 's' as IconSize,
    px: [2, 4],
    py: 2,
    textVariant: 'text-s-regular' as TextVariant,
  },
  s: {
    borderRadius: '200px',
    height: '36px',
    iconSize: 's' as IconSize,
    px: 4,
    py: 2,
    textVariant: 'text-s-regular' as TextVariant,
  },

  xs: {
    borderRadius: '200px',
    height: '32px',
    iconSize: 's' as IconSize,
    px: 2,
    py: 3,
    textVariant: 'text-xs-regular' as TextVariant,
  },
  equalSize: {
    px: 3,
    py: 3,
    height: '40px',
    borderRadius: '200px',
    iconSize: 's' as IconSize,
    textVariant: 'text-s-regular' as TextVariant,
  },
};

export interface ButtonProps extends BoxProps {
  isDisabled?: boolean;
  text?: string;
  variant?: string;
  iconLeft?: IconType | undefined;
  iconRight?: IconType;
  size?: keyof typeof sizesMap;
  width?: string;
  backgroundColor?: string;
  textVariant?: TextVariant;
  sx?: ThemeUICSSObject;
}

const Button = React.forwardRef<HTMLDivElement, ButtonProps>(
  (
    {
      isDisabled = false,
      text,
      variant = 'primary',
      iconLeft,
      iconRight,
      size = 's',
      width,
      backgroundColor,
      onClick,
      textVariant,
      sx,
      children,
      ...rest
    },
    ref,
  ) => {
    const { bg, border, disabledStyles, color } = useButtonVariantStyle(variant);
    const { borderRadius, px, py, height, textVariant: sizeTextVariant, iconSize } = sizesMap[size];
    const theme = useSelector((state: RootState) =>
      get(state, ['widget', 'widget', 'customer', 'theme']),
    );

    return (
      <FlexLayout
        alignItems='center'
        as='button'
        bg={backgroundColor || bg || theme?.foregroundColor}
        flexDirection='row'
        isDisabled={isDisabled}
        justifyContent='center'
        onClick={onClick}
        px={px}
        py={py}
        ref={ref}
        space={2}
        sx={{
          '&:disabled': disabledStyles,
          border,
          borderRadius,
          color,
          cursor: !isDisabled && 'pointer',
          height,
          width: width || 'fit-content',
          ...sx,
        }}
        {...rest}
      >
        {iconLeft && <Icon color={color} icon={iconLeft} size={iconSize} />}
        {!text ? children : <Text variant={textVariant || sizeTextVariant}>{text}</Text>}
        {iconRight && <Icon color={color} icon={iconRight} size={iconSize} />}
      </FlexLayout>
    );
  },
);

export default Button;
export type ButtonSize = keyof typeof sizesMap;
