import React, { useState } from 'react';
import { FlexLayout, Text } from '..';
import { colors } from '../../theme';

interface TableRowProps {
  row: any;
  index: number;
  lastItem: boolean;
  onItemClick?: (item: any) => void;
  onItemHover?: (item: any) => void;
}

export const TableRow: React.FC<TableRowProps> = ({
  row,
  index,
  lastItem,
  onItemClick,
  onItemHover,
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const { ...rowProps } = row.getRowProps();

  return (
    <FlexLayout
      {...rowProps}
      bg={index % 2 === 0 ? 'white' : colors['black008']}
      flexGrow={1}
      pr={4}
      sx={{
        borderRadius: lastItem ? '0px 0px 4px 4px' : null,
        wordBreak: 'break-all',
        width: '100%',
        overflow: 'hidden',
        minWidth: '70vw',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FlexLayout
        flexGrow={1}
        ml='2px'
        pl='13px'
        sx={{
          boxShadow: hovered ? `-3px 0px 0px 0px ${colors['irisBlue']}` : null,
          width: '100%',
        }}
      >
        {row.cells.map((cell: any, i: number) => {
          const customClickableCells = ['Actions'];
          const isCustomClick = customClickableCells.some((c) => c === cell.column.Header);
          const cellPadding = cell.column.padding;
          return (
            <FlexLayout
              {...cell.getCellProps()}
              alignItems={'center'}
              justifyContent={i === 0 ? 'flex-start' : 'center'}
              key={i}
              pr={4}
              pl={2}
              py={isCustomClick ? 0 : cellPadding || 2}
              sx={{ order: cell.column.id === 'expander' ? 1 : 'initial', width: '100%' }}
              onClick={
                !isCustomClick && onItemClick
                  ? () => {
                      onItemClick(row.original);
                    }
                  : undefined
              }
              onMouseEnter={
                onItemHover
                  ? () => {
                      onItemHover(row.original);
                    }
                  : undefined
              }
            >
              <Text
                color={'secondaryArsenic'}
                sx={{
                  textAlign: i === 0 ? 'left' : 'center',
                  wordBreak: 'break-word',
                  width: '100%',
                }}
                variant={cell.column?.isBold ? 'text-s-medium' : 'text-s-regular'}
              >
                {cell.render('Cell', { hovered })}
              </Text>
            </FlexLayout>
          );
        })}
      </FlexLayout>
    </FlexLayout>
  );
};

export default TableRow;
