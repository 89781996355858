import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from '../pages-components';
import { Button, colors, FlexLayout, Image, Link, Text } from '../ui';
import Error404 from '../ui/assets/images/404.jpeg';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('404');
  }, []);

  return (
    <PageLayout>
      <FlexLayout
        alignItems='center'
        flexDirection='column'
        space={4}
        p={['0px', '20px', '40px', '80px']}
      >
        <Image src={Error404} height='390px' width='250px' />
        <Text variant={'text-xxxl-bold'} color={colors['irisBlue']}>
          404 Not Found
        </Text>
        <Text variant={'text-xl-regular'} color={colors['secondaryArsenic']}>
          Whoops! That page doesn’t exist.
        </Text>
        <Link
          href={'https://www.yoursocialsmile.com'}
          sx={{ textDecoration: 'none', textAlign: 'center' }}
        >
          <Button size='standard' text={'Back to Home Page'} variant='irisBlue' />
        </Link>
      </FlexLayout>
    </PageLayout>
  );
};

export default NotFound;
