import '../pages-components/WidgetCodeSnippet.css';

import React, { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { findUserWidgetsByUserId, WidgetType } from '../apis';
import { AuthContext } from '../context';
import { FlexLayout, Icon, Text, showToast } from '../ui';
import { AdminPageLayout, AdminPageTitle, WidgetTable } from '../pages-components';
import { ToastType } from '../enums';

const UserAppointments = () => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userWidgets, setUserWidgets] = useState<WidgetType[]>();

  useQuery(['findUserWidgetsByUserId', user], () => findUserWidgetsByUserId(user?.uuid as string), {
    onSuccess: ({ data }) => {
      setUserWidgets(data);
      setIsLoading(false);
    },
    onError: () => {
      showToast({
        title: 'Something went wrong. Please try again later.',
        toastType: ToastType.ERROR,
      });
    },
    enabled: !!user?.uuid,
    retry: false,
  });

  return (
    <AdminPageLayout>
      <FlexLayout flexDirection={'column'} alignItems={'flex-start'} sx={{ width: '100%' }}>
        <FlexLayout alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <AdminPageTitle title={'Appointments'} icon={'chair'} />
        </FlexLayout>
        <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
          {isLoading ? (
            <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
              <Icon icon='loading' size='xl' />
            </FlexLayout>
          ) : (
            <FlexLayout
              alignItems={'center'}
              flexDirection={'column'}
              justifyContent={'center'}
              space={4}
              py={4}
              sx={{ width: '100%' }}
            >
              <Text
                variant={'text-s-medium'}
                color={'secondaryArsenic'}
                sx={{ alignSelf: 'flex-start' }}
              >
                Take a closer look into all the appointments connected to {user?.clinicName}!
              </Text>
              {userWidgets &&
                userWidgets.map((widget: WidgetType, index: number) => (
                  <WidgetTable key={index} widget={widget} />
                ))}
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </AdminPageLayout>
  );
};

export default UserAppointments;
