import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { SignUpFormValues, signupRequest } from '../apis';
import { HttpStatusCode } from '../enums';
import { Routes } from '../routes/routes';
import { AccountActivation, PageLayout, StepsDisplay, UserDetails } from '../pages-components';
import { Box, Button, colors, FlexLayout, Link, Text } from '../ui';

export interface SignUpSuccess {
  email?: string;
  uuid?: string;
}

const SignUp = () => {
  const [success, setSuccess] = useState<SignUpSuccess>({ email: '' });
  const form = useForm<SignUpFormValues>({
    mode: 'all',
  });
  const [errorSignupMessage, setErrorSignupMessage] = useState<string>('');
  const [stepsDisplay, setStepsDisplay] = useState<{ activeStep: number; totalSteps: number }>({
    activeStep: 1,
    totalSteps: 2,
  });

  const navigate = useNavigate();

  const { mutate } = useMutation(signupRequest, {
    onError: (error: AxiosError) => {
      if (error.response?.status === HttpStatusCode.INTERNAL_SERVER_ERROR)
        setErrorSignupMessage('Something went wrong. Please try again later');
    },
    onSuccess: (res) => {
      if (res.status === HttpStatusCode.BAD_REQUEST) {
        setErrorSignupMessage('Email address already in use.');
      } else setSuccess({ email: res.data.email, uuid: res.data.uuid });
    },
  });

  const onSubmit = async (values: SignUpFormValues) => {
    mutate(values);
  };

  return (
    <PageLayout
      headerItemLink={
        <FlexLayout flexDirection={'row'} alignItems={'center'} space={2}>
          <Text variant={'text-m-regular'} color={colors['black07']}>
            Already have an account?
          </Text>
          <Link href={Routes.Login} color={colors['blissCyan']} sx={{ textDecoration: 'none' }}>
            <Text variant={'text-m-medium'}>Log In</Text>
          </Link>
        </FlexLayout>
      }
    >
      {success.email ? (
        <FlexLayout
          flexDirection={'column'}
          alignItems={'center'}
          space={4}
          p={['0px', '20px', '40px', '80px']}
          sx={{ width: '100%' }}
        >
          <FlexLayout
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            space={4}
            sx={{ pb: '40px' }}
          >
            <Text variant={'text-xxl-medium'} sx={{ textAlign: 'center' }}>
              Sign Up
            </Text>
            <Text variant={'text-s-regular'} sx={{ textAlign: 'center' }}>
              Before you can login we need you verify your email address.
              <br />
              We’ve sent an email to {success.email} with the link to verify it.
              <br />
              Please check your email inbox.
            </Text>
            <Button
              size='standard'
              text={'Log In'}
              variant='irisBlue'
              onClick={() => navigate(Routes.Login)}
            />
          </FlexLayout>
        </FlexLayout>
      ) : (
        <FlexLayout
          flexDirection={'column'}
          alignItems={'center'}
          space={4}
          p={['0px', '20px', '40px', '80px']}
          sx={{ width: '100%' }}
        >
          <FlexLayout flexDirection={'column'} alignItems={'center'} space={4} sx={{ pb: '40px' }}>
            <Text variant={'text-xxl-medium'}>Sign Up</Text>
            <Text variant={'text-s-regular'}>Let’s start your journey with Your Social Smile.</Text>
          </FlexLayout>
          {stepsDisplay && (
            <Box mb={6}>
              <StepsDisplay
                activeStep={stepsDisplay.activeStep}
                totalSteps={stepsDisplay.totalSteps}
                setStepsDisplay={setStepsDisplay}
              />
            </Box>
          )}

          {stepsDisplay && stepsDisplay.activeStep == 1 && (
            <UserDetails
              form={form}
              onUserDetailsSubmit={() => {
                setStepsDisplay({ ...stepsDisplay, activeStep: 2 });
              }}
            />
          )}
          {stepsDisplay && stepsDisplay.activeStep == 2 && (
            <AccountActivation
              form={form}
              onAccountActivationSubmit={() => {
                onSubmit(form.getValues());
              }}
              errorSignupMessage={errorSignupMessage}
            />
          )}
        </FlexLayout>
      )}
    </PageLayout>
  );
};

export default SignUp;
