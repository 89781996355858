import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { Button, FlexLayout } from '../ui';

interface Props {
  text: string;
  style: ThemeUIStyleObject;
}
const ImageLabel = (props: Props) => {
  const { text, style } = props;
  return (
    <FlexLayout
      sx={{
        position: 'absolute',
        width: '70px',
        ...style,
      }}
    >
      <Button isDisabled size='xs' text={text} width='image-label-width' />
    </FlexLayout>
  );
};

export default ImageLabel;
