import React, { useState } from 'react';
import { PageLayout } from '../pages-components';
import Whitening from '../ui/assets/images/whitening-step1.jpg';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { Routes } from '../routes/routes';
import { ForgotPasswordFormValues, forgotPasswordRequest } from '../apis';
import { useMutation } from 'react-query';
import { Button, FlexLayout, GridLayout, Image, Text, colors, Input, useScreenType } from '../ui';
import { emailRegex } from '../helpers';

const ForgotPassword = () => {
  const { control, formState, handleSubmit } = useForm<ForgotPasswordFormValues>({
    mode: 'all',
  });
  const [success, setSuccess] = useState<ForgotPasswordFormValues>({ email: '' });
  const navigate = useNavigate();
  const { isDesktop, isThinMobile } = useScreenType();

  const { mutate } = useMutation(forgotPasswordRequest, {
    onSettled: (data, error, variables) => setSuccess({ email: variables.email }),
  });

  const onSubmit = async (values: ForgotPasswordFormValues) => {
    mutate(values);
  };

  return (
    <PageLayout>
      {success.email && (
        <FlexLayout
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          space={4}
          mt={['10px', '20px', '40px']}
          p={['20px', '20px', '40px', '80px']}
          sx={{ width: '100%', p: '80px' }}
        >
          <FlexLayout
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            space={4}
            sx={{ pb: '40px' }}
          >
            <Text variant={'text-xxl-medium'} sx={{ textAlign: 'center' }}>
              Forgot Password?
            </Text>
            <Text variant={'text-s-regular'} sx={{ textAlign: 'center' }}>
              No worries, if {success.email} exists in our database, we’ll send a link to reset your
              password.
              <br />
              Check your email inbox.
            </Text>
            <Button
              size='standard'
              text={'Log In'}
              variant='irisBlue'
              onClick={() => navigate(Routes.Login)}
            />
          </FlexLayout>
        </FlexLayout>
      )}
      {!success.email && (
        <FlexLayout
          flexDirection={'column'}
          alignItems={'center'}
          space={4}
          mt={['10px', '20px', '40px']}
          p={['20px', '20px', '40px', '80px']}
          sx={{ width: '100%' }}
        >
          <FlexLayout flexDirection={'column'} alignItems={'center'} space={4} sx={{ pb: '40px' }}>
            <Text variant={'text-xxl-medium'}>Forgot Password?</Text>
            <Text variant={'text-s-regular'}>No worries, we’ll send you reset instructions.</Text>
          </FlexLayout>
          <GridLayout
            alignItems={'center'}
            justifyItems={'center'}
            gridTemplateColumns={isDesktop() ? 'repeat(2,1fr)' : 'repeat(1, 1fr)'}
            gap={4}
            sx={{
              width: '100%',
              borderRadius: '8px',
              p: '80px',
              backgroundColor: `${colors['lightCyan']}`,
            }}
          >
            <FlexLayout
              alignItems={'center'}
              justifyContent={!isDesktop() ? 'center' : 'flex-end'}
              sx={{ width: '100%', height: '100%', maxWidth: '600px', maxHeight: '480px' }}
            >
              <Image src={Whitening} />
            </FlexLayout>
            <FlexLayout
              alignItems={'flex-start'}
              justifyContent={!isDesktop() ? 'center' : 'flex-start'}
              sx={{ width: '100%', height: '100%' }}
              onSubmit={handleSubmit(onSubmit)}
              as={'form'}
            >
              <FlexLayout
                flexDirection={'column'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                space={2}
                sx={{ width: '600px' }}
              >
                <Input
                  control={control as any}
                  error={
                    !!(formState.dirtyFields.email && formState.errors.email?.type === 'pattern')
                  }
                  label={'Email Address'}
                  message={formState.errors?.email?.message}
                  name='email'
                  rules={{
                    pattern: {
                      message: 'Invalid email address.',
                      value: emailRegex,
                    },
                    required: true,
                  }}
                />
                <FlexLayout
                  flexDirection='column'
                  py={2}
                  sx={{ justifySelf: 'flex-end', width: '100%' }}
                >
                  <FlexLayout
                    flexDirection={isThinMobile() ? 'column' : 'row'}
                    alignItems={'flex-start'}
                    space={4}
                    justifyContent={!isDesktop ? 'center' : 'flex-start'}
                    sx={{ width: '100%' }}
                  >
                    <Button
                      size='standard'
                      text={'Reset Password'}
                      variant='irisBlue'
                      type={'submit'}
                      width={!isDesktop() ? '100%' : 'auto'}
                    />
                    <Button
                      size={'standard'}
                      text={'Cancel'}
                      variant={'white'}
                      onClick={() => navigate(Routes.Login)}
                      width={!isDesktop() ? '100%' : 'auto'}
                    />
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </GridLayout>
        </FlexLayout>
      )}
    </PageLayout>
  );
};

export default ForgotPassword;
