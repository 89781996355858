import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsBlissWidget } from '../hooks';
import { Button, colors, FlexLayout, IconType, Text, TextIcon, useScreenType } from '../ui';

interface Props {
  selectedImage: File | string | undefined;
  onContinue: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => void;
  onRetakePhoto: () => void;
}

export const PhotoAdvice = (props: Props) => {
  const { selectedImage, onContinue, onRetakePhoto } = props;
  const { isMobile } = useScreenType();

  const isBlissWidget = useIsBlissWidget();

  const { t } = useTranslation();

  const data: { text: string; icon: IconType }[] = [
    { icon: 'light', text: t('photoEditor.photoAdvice.one') },
    {
      icon: 'face',
      text: t('photoEditor.photoAdvice.two'),
    },
    {
      icon: 'mouth',
      text: t('photoEditor.photoAdvice.three'),
    },
  ];

  return (
    <>
      {selectedImage ? (
        <FlexLayout flexDirection='column' space={4}>
          {!isMobile() && (
            <>
              <Text variant='text-xl-regular'>{t('photoEditor.positionFaceTitle')}</Text>
              <Text color={colors.black06} variant='text-m-regular'>
                {t('photoEditor.positionFaceMessage')}
              </Text>
            </>
          )}
          <FlexLayout
            alignItems='center'
            flexDirection={isMobile() ? 'column' : 'row'}
            justifyContent='space-between'
            my={isMobile() ? 3 : 7}
            space={3}
          >
            <Button
              iconLeft='check'
              onClick={onContinue}
              size='l'
              text={t('common.continue')}
              textVariant={isBlissWidget ? 'bliss-button' : undefined}
              variant={isBlissWidget ? 'blissPrimary' : 'primary'}
              width={isMobile() ? '100%' : 'fit-content'}
            />
            <Button
              onClick={onRetakePhoto}
              size='l'
              text={t('photoEditor.retakePhoto')}
              textVariant={isBlissWidget ? 'bliss-button' : undefined}
              variant={isBlissWidget ? 'blissSecondary' : 'secondary'}
              width={isMobile() ? '100%' : 'fit-content'}
            />
          </FlexLayout>
        </FlexLayout>
      ) : (
        <>
          <Text variant={isBlissWidget ? 'bliss-subheading' : 'text-xl-regular'}>
            {t('photoEditor.photoAdviceTitle')}
          </Text>
          <FlexLayout flexDirection={isMobile() ? 'row' : 'column'} justifyContent='space-between'>
            {data.map((content, index) => (
              <TextIcon
                flexDirection={isMobile() ? 'column' : 'row'}
                icon={content.icon}
                iconColor={isBlissWidget ? 'blissCyan' : 'black'}
                iconSize='l'
                key={index}
                py={3}
                space={[2, 5]}
                sx={{
                  borderBottom: !isMobile() && `1px solid ${colors.black008}`,
                  width: isBlissWidget ? '100%' : 'unset',
                }}
                text={content.text}
                textVariant={
                  isBlissWidget ? 'bliss-text' : isMobile() ? 'text-s-regular' : 'text-m-regular'
                }
              />
            ))}
          </FlexLayout>
        </>
      )}
    </>
  );
};

export default PhotoAdvice;
