import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import metadata from 'libphonenumber-js/metadata.max.json';

import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

import { Button, colors, FlexLayout, GridLayout, Input, showToast, Text } from '../ui';
import { maxLengthUtil, minLengthUtil } from '../utils';
import { AuthContext } from '../context';

import { updateUser, UpdateUserType, Theme, findThemes } from '../apis';
import { ToastType } from '../enums';
import { emailRegex } from '../helpers';
import { ThemesPicker } from '../pages-components';
import { AxiosResponse } from 'axios';

export interface PageTab {
  title: string;
}

const ProfileInfo = () => {
  const { setUser, user } = useContext(AuthContext);
  const [themes, setThemes] = useState<Theme[]>();
  const [selectedTheme, setSelectedTheme] = useState<Theme>();
  const form = useForm<UpdateUserType>({
    mode: 'all',
  });
  const { control, formState, handleSubmit, setValue } = form;

  useEffect(() => {
    setValue('logo', user?.logo);
  }, [user]);

  useEffect(() => {
    setValue('themeUuid', user?.themeUuid as string);
  }, [user, selectedTheme]);

  const { error, isLoading } = useQuery(['getThemes'], () => findThemes(), {
    onSuccess: (res: AxiosResponse) => {
      setThemes(res.data);
      setSelectedTheme(res.data.find((t: Theme) => t.uuid === user?.themeUuid));
    },
    onError: () => {
      showToast({
        title: 'Fetching themes failed. Please try again later.',
        toastType: ToastType.ERROR,
      });
    },
  });

  const { mutate } = useMutation(updateUser);

  const onSubmit = async (values: UpdateUserType) => {
    mutate(
      {
        userId: user?.uuid as string,
        updateUserDto: {
          ...values,
          email: values.email,
          themeUuid: selectedTheme?.uuid as string,
        },
      },
      {
        onSuccess: () => {
          showToast({
            title: 'Changes saved successfully.',
            toastType: ToastType.INFO,
          });
          setUser({ ...user, ...values, email: values.email, themeUuid: selectedTheme?.uuid });
        },
        onError: () => {
          showToast({
            title: 'Something went wrong. Please try again later.',
            toastType: ToastType.ERROR,
          });
        },
      },
    );
  };

  return (
    <FlexLayout
      as='form'
      alignItems={'flex-start'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      space={4}
      sx={{ width: '100%', height: '100%' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FlexLayout alignItems={'flex-start'} flexDirection={'column'} space={4}>
        <Text variant={'text-s-medium'} color={'secondaryArsenic'} sx={{ alignSelf: 'flex-start' }}>
          Check your details here!
        </Text>
        <GridLayout alignItems={'flex-start'} gap={4} gridTemplateColumns={'repeat(2, 1fr)'}>
          <Input
            control={control as any}
            error={!!(formState.dirtyFields.clinicName && formState.errors.clinicName)}
            label={'Clinic Name*'}
            defaultValue={user && (user?.clinicName as string)}
            message={formState.errors?.clinicName?.message}
            placeholder={'Enter your name'}
            name='clinicName'
            rules={{
              required: true,
              minLength: minLengthUtil(2),
              maxLength: maxLengthUtil(30),
            }}
            sx={{
              maxWidth: '100%',
            }}
          />
          <Input
            control={control as any}
            error={
              !!(
                (formState.dirtyFields.email && formState.errors.email) ||
                formState.errors.email?.type === 'pattern'
              )
            }
            label={'Email Address*'}
            defaultValue={user && (user?.email as string)}
            message={formState.errors?.email?.message}
            placeholder={'Enter your work mail'}
            name='email'
            rules={{
              pattern: {
                message: 'Invalid email address.',
                value: emailRegex,
              },
              required: true,
            }}
            sx={{
              maxWidth: '100%',
            }}
          />
        </GridLayout>
        <GridLayout alignItems={'flex-start'} gap={4} gridTemplateColumns={'repeat(2, 1fr)'}>
          <Input
            control={control as any}
            error={!!(formState.dirtyFields.shortDescription && formState.errors.shortDescription)}
            label={'Short Description*'}
            defaultValue={user && (user?.shortDescription as string)}
            message={formState.errors?.shortDescription?.message}
            placeholder={'Enter short description'}
            name='shortDescription'
            rules={{
              required: { value: true, message: 'Short description is required.' },
            }}
            sx={{ maxWidth: '100%' }}
          />
          <FlexLayout flexDirection='column' space={2} sx={{ width: '100%' }}>
            <FlexLayout
              flexDirection='column'
              space={2}
              justifyContent='space-between'
              sx={{ maxWidth: '600px' }}
            >
              <Text color={colors['black04']} variant={'text-m-regular'}>
                {'Phone Number*'}
              </Text>
              <Controller
                name='phoneNumber'
                control={control}
                defaultValue={user?.phoneNumber}
                rules={{
                  required: { value: true, message: 'Phone number is required.' },
                }}
                render={() => (
                  <PhoneInputWithCountry
                    control={control}
                    countryCallingCodeEditable={false}
                    international
                    metadata={metadata}
                    name='phoneNumber'
                    rules={{
                      required: { value: true, message: 'Phone number is required.' },
                    }}
                  />
                )}
              />
            </FlexLayout>
          </FlexLayout>
        </GridLayout>
        <ThemesPicker
          error={!!error as boolean}
          isLoading={isLoading}
          selectedTheme={selectedTheme as Theme}
          setSelectedTheme={setSelectedTheme}
          themes={themes as Theme[]}
        />
      </FlexLayout>
      <Button
        size='standard'
        text={'Save Changes'}
        variant='irisBlue'
        isDisabled={!formState.isValid}
        type={'submit'}
      />
    </FlexLayout>
  );
};

export default ProfileInfo;
