import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { colors, FlexLayout, Icon, radii, Text } from '../ui';

const AVAILABLE_LANGUAGES = ['en', 'ar'];

const LanguageSelect = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { i18n } = useTranslation();

  const onLanguageChange = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>,
    lang: string,
  ) => {
    e.stopPropagation();
    i18n.changeLanguage(lang);
    localStorage.setItem('lng', lang);
    setIsOpen(false);
  };

  return (
    <FlexLayout
      alignItems='center'
      onClick={() => setIsOpen(true)}
      space={2}
      sx={{
        background: colors.secondary05,
        borderBottomLeftRadius: isOpen ? 0 : radii.s,
        borderBottomRightRadius: isOpen ? 0 : radii.s,
        borderTopLeftRadius: radii.s,
        borderTopRightRadius: radii.s,
        p: 2,
        position: 'relative',
      }}
    >
      <Text variant='text-m-regular'>{i18n.language.toUpperCase()}</Text>
      {isOpen && (
        <FlexLayout
          flexDirection='column'
          sx={{
            background: colors.secondary05,
            borderBottomLeftRadius: radii.s,
            borderBottomRightRadius: radii.s,
            left: '0',
            position: 'absolute',
            top: '100%',
            width: '100%',
          }}
        >
          {AVAILABLE_LANGUAGES.map((lang) => (
            <FlexLayout
              alignItems='center'
              justifyContent='center'
              key={lang}
              onClick={(e) => onLanguageChange(e, lang)}
              sx={{ cursor: 'pointer', p: 2, width: '100%' }}
            >
              <Text variant='text-m-regular'>{lang.toUpperCase()}</Text>
            </FlexLayout>
          ))}
        </FlexLayout>
      )}
      <Icon color={colors.black06} icon={isOpen ? 'chevronUp' : 'chevronDown'} />
    </FlexLayout>
  );
};

export default LanguageSelect;
