export const colors = {
  black: 'rgba(0, 0, 0, 1)',
  black008: 'rgba(0, 0, 0, 0.08)',
  black03: 'rgba(0, 0, 0, 0.3)',
  black04: 'rgba(0, 0, 0, 0.4)',
  black06: 'rgba(0, 0, 0, 0.6)',
  black07: 'rgba(0, 0, 0, 0.7)',
  black075: 'rgba(0, 0, 0, 0.75)',
  blue: 'rgba(19, 80, 186, 0.3)',
  red: 'rgba(191, 1, 1, 1)',
  secondary05: 'rgba(240, 237, 238, 0.5)',
  secondary075: 'rgba(240, 237, 238, 0.75)',
  white: 'rgba(255, 255, 255, 1)',
  white07: 'rgba(255, 255, 255, 0.7)',
  whiteSmoke: '#f6f7f8',
  neutral: '#D5D9DD',
  // ! Bliss specific
  // eslint-disable-next-line sort-keys/sort-keys-fix
  blissBlack: '#231F20',
  blissCyan: '#35EFEF',
  blissOffWhite: '#FAFAFA',
  blissPureWhite: '#FFFFFF',
  irisBlue: '#0FCCC3',
  lightGray: '#f3f7f9',
  lightCyan: '#F5FFFF',
  secondaryArsenic: '#394147',
};
