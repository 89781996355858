import { useMutation } from 'react-query';
import React, { useEffect, useState } from 'react';

import { PageLayout } from '../pages-components';
import { resendEmailValidationRequest, verifyTokenRequest } from '../apis';
import { Button, FlexLayout, Icon, Link, Text } from '../ui';
import { Routes } from '../routes/routes';
import { useNavigate } from 'react-router';

const ConfirmSignup = () => {
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const [userToken, setUserToken] = useState<string>('');
  const [resendSuccess, setResendSuccess] = useState<boolean>(false);
  const { isLoading, mutate: mutateVerify } = useMutation(verifyTokenRequest, {
    onError: () => setIsTokenValid(false),
    onSuccess: () => setIsTokenValid(true),
  });

  const { mutate: mutateResend } = useMutation(resendEmailValidationRequest, {
    onSettled: () => setResendSuccess(true),
  });

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (!token) {
      setIsTokenValid(false);
    } else setUserToken(token);

    token && mutateVerify({ token });
  }, [userToken]);

  return (
    <PageLayout>
      {isLoading ? (
        <FlexLayout
          alignItems='center'
          justifyContent={'center'}
          space={4}
          flexGrow={1}
          mt={['10px', '20px', '40px']}
          p={['20px', '20px', '40px', '80px']}
          sx={{ width: '100%' }}
        >
          <Icon icon={'loading'} size={'xl'} />
        </FlexLayout>
      ) : resendSuccess ? (
        <FlexLayout
          flexDirection={'column'}
          alignItems={'center'}
          space={4}
          mt={['10px', '20px', '40px']}
          p={['20px', '20px', '40px', '80px']}
          sx={{ width: '100%', p: '80px' }}
        >
          <FlexLayout flexDirection={'column'} alignItems={'center'} space={4} sx={{ pb: '40px' }}>
            <Text variant={'text-xxl-medium'}>Email Link Expired</Text>
            <Text variant={'text-s-regular'}>
              If your email exists in our database, we’ve sent you a validation link. Check your
              inbox to validate your email.
            </Text>
            <Button
              size='standard'
              text={'Login'}
              variant='irisBlue'
              onClick={() => navigate(Routes.Login)}
            />
          </FlexLayout>
        </FlexLayout>
      ) : (
        <FlexLayout
          flexDirection={'column'}
          alignItems={'center'}
          space={4}
          mt={['10px', '20px', '40px']}
          p={['20px', '20px', '40px', '80px']}
          sx={{ width: '100%', p: '80px' }}
        >
          <FlexLayout flexDirection={'column'} alignItems={'center'} space={4} sx={{ pb: '40px' }}>
            <Text variant={'text-xxl-medium'}>
              {isTokenValid ? 'Email Verified' : 'Email Link Expired'}
            </Text>
            <Text variant={'text-s-regular'}>
              {isTokenValid
                ? 'Your email is successfully verified, feel free to log in and start exploring Your Social Smile'
                : 'The link from your email seems to be expired.'}
            </Text>
            {isTokenValid ? (
              <FlexLayout alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
                <Link href={Routes.Login}>
                  <Button size='standard' text={'Login'} variant='irisBlue' />
                </Link>
              </FlexLayout>
            ) : (
              <Button
                size='standard'
                text={'Resend Email Validation'}
                variant='irisBlue'
                onClick={() => mutateResend(userToken)}
              />
            )}
          </FlexLayout>
        </FlexLayout>
      )}
    </PageLayout>
  );
};

export default ConfirmSignup;
