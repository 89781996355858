/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import React from 'react';
import { Control, Controller, ValidationRule } from 'react-hook-form';

export interface ControllerProps {
  control?: Control<any>;
  name: string;
  rules?: ValidationRule<any>;
  defaultValue?: any;
}

export const withFormController = <T extends object>(WrappedComponent: React.FC<T>) => {
  const WithFormController = forwardRef<any, T & ControllerProps>((props, ref) => {
    return props.control ? (
      <Controller
        control={props.control}
        defaultValue={props.defaultValue}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <WrappedComponent value={value || ''} onChange={onChange} {...props} ref={ref} />
        )}
        rules={props.rules}
      />
    ) : (
      <WrappedComponent {...props} ref={ref} />
    );
  });

  return WithFormController;
};
