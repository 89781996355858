import React from 'react';
import { PropsValue } from 'react-select';
import { ControllerProps, withFormController } from '../../../hocs/wihFormController';

import { Option, SingleSelectProps, SingleSelect } from './SingleSelect';

export const SingleSelectForm = (
  props: SingleSelectProps<PropsValue<Option>> & ControllerProps,
) => {
  return <SingleSelect {...props} />;
};

export default withFormController(SingleSelect);
