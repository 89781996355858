import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router';

import { colors, FlexLayout, Icon, radii, Text } from '../ui';

export interface PageTab {
  title: string;
}

export interface AdminPageTabsProps {
  tabs: PageTab[];
  activeTab: PageTab;
  setActiveTab: Dispatch<SetStateAction<PageTab | undefined>>;
  backIcon?: boolean;
}

const AdminPageTabs: React.FC<AdminPageTabsProps> = ({
  tabs,
  activeTab,
  setActiveTab,
  backIcon = false,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    setActiveTab(tabs[0]);
  }, []);

  return (
    <FlexLayout
      alignItems={'center'}
      flexDirection={'row'}
      justifyContent={'flex-start'}
      mb={2}
      space={2}
      sx={{ width: '100%' }}
    >
      {backIcon && (
        <Icon
          icon={'arrowLeft'}
          size={'l'}
          color={'secondaryArsenic'}
          onClick={() => navigate(-1)}
        />
      )}
      {activeTab && (
        <FlexLayout
          alignItems={'center'}
          flexDirection={'row'}
          justifyContent={'flex-start'}
          space={2}
          sx={{ width: '100%' }}
        >
          {tabs.map((tab: PageTab, index: number) => (
            <FlexLayout
              alignItems='center'
              key={index}
              sx={{ cursor: 'pointer', position: 'relative' }}
            >
              <Text
                key={index}
                variant={'text-xl-bold'}
                color={activeTab === tab ? 'secondaryArsenic' : 'black03'}
                onClick={() => setActiveTab(tab)}
                sx={{ cursor: 'pointer', transition: '0.25s ease-in-out' }}
              >
                {tab.title}
              </Text>
              {activeTab === tab && (
                <motion.div
                  layoutId='bubble'
                  style={{
                    borderRadius: radii.xs,
                    zIndex: 10,
                    position: 'absolute',
                    padding: '4px',
                    backgroundColor: colors['irisBlue'],
                    top: '-10%',
                  }}
                  transition={{ duration: 0.25 }}
                />
              )}
            </FlexLayout>
          ))}
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

export default AdminPageTabs;
