import { CreateEditWidgetType, UpdateWidgetType, WidgetDetailType, WidgetType } from '.';
import { IPaginationRequest } from '../helpers';
import { api } from '../services';

export const findWidgetDetail = async (id: string) => {
  return await api.get<WidgetDetailType>(`/widget/${id}`);
};

export const deleteWidgetById = async (id: string) => {
  return api.delete<WidgetDetailType>(`/widget/${id}`);
};

export const createWidget = async (widget: CreateEditWidgetType) => {
  return api.post<WidgetType>('/widget', widget);
};

export const updateWidget = async ({
  widgetId,
  data,
}: {
  widgetId: string;
  data: UpdateWidgetType;
}) => {
  return api.patch<WidgetType>(`/widget/${widgetId}`, data);
};

export const editWidget = ({
  widgetId,
  widget,
}: {
  widgetId: string;
  widget: CreateEditWidgetType;
}) => {
  return api.patch<WidgetType>(`/widget/${widgetId}`, widget);
};

export const emailPhotos = async (data: FormData) => {
  return api.post('/widget/email-photos', data);
};

export const findAppointmentsByWidgetId = async (
  reqQueryAndHeader: IPaginationRequest,
  widgetId: string,
) => {
  return api.get(`/widget/${widgetId}/appointments`, { params: { ...reqQueryAndHeader } });
};
