import {
  CreateUserSubscriptionDto,
  ForgotPasswordFormValues,
  ResetPasswordValues,
  SignUpFormValues,
  UpdatePasswordDto,
  UpdateUserType,
  UpdateWidgetType,
  VerifyTokenValues,
} from '.';
import { api } from '../services';

export const currentUserRequest = () => {
  return api.get('/users/me');
};

export const updatePassword = (updatePasswordDto: UpdatePasswordDto) => {
  return api.patch('/users/me/update-password', { ...updatePasswordDto });
};

export const forgotPasswordRequest = (data: ForgotPasswordFormValues) => {
  return api.post('/users/password/request', data);
};

export const resetPasswordRequest = (data: ResetPasswordValues) => {
  return api.post('/users/password/confirm', data);
};

export const signupRequest = (data: SignUpFormValues) => {
  return api.post('/users/create', data);
};

export const verifyTokenRequest = (data: VerifyTokenValues) => {
  return api.post('/users/confirm', data);
};

export const resendEmailValidationRequest = (token: string) => {
  return api.post('/users/resend', { token });
};

export const findAllUsers = async () => {
  return await api.get('/users');
};

export const updateUser = async ({
  userId,
  updateUserDto,
}: {
  userId: string;
  updateUserDto: UpdateUserType;
}) => {
  return await api.patch(`/users/${userId}`, updateUserDto);
};

export const updateUserWidgetStatus = async ({
  userId,
  widgetId,
  updateWidgetDto,
}: {
  userId: string;
  widgetId: string;
  updateWidgetDto: UpdateWidgetType;
}) => {
  return await api.patch(`/users/${userId}/widget/${widgetId}`, updateWidgetDto);
};

export const findUserWidgetsByUserId = async (userId: string) => {
  return api.get(`/users/${userId}/widgets`);
};

export const deleteUser = async (userId: string) => {
  return api.delete(`/users/${userId}`);
};

export const createUserSubscription = ({
  userId,
  data,
}: {
  userId: string;
  data: CreateUserSubscriptionDto;
}) => {
  return api.post(`/users/${userId}/subscription`, data);
};

export const deleteUserSubscription = ({ userId }: { userId: string }) => {
  return api.delete(`/users/${userId}/subscription`);
};
