import { colors } from '@mui/material';
import { FlexLayout, Text } from '..';
import React from 'react';

const NoTableData: React.FC<{
  title?: string;
  subtitle?: string;
}> = ({ title = 'No data has been found', subtitle = '' }) => {
  return (
    <FlexLayout
      alignItems={'center'}
      backgroundColor={'white'}
      justifyContent={'center'}
      sx={{
        borderRadius: 'm',
        border: `1px solid ${colors['blue']}`,
        overflowY: 'scroll',
        width: '100%',
        height: '100%',
      }}
    >
      <FlexLayout
        alignItems={'center'}
        flexDirection={'column'}
        justifyContent={'center'}
        space={4}
        sx={{
          padding: '16px 0px',
          minHeight: '48.5vh',
          minWidth: '78vw',
          overflowX: 'scroll',
        }}
      >
        <Text color='secondaryArsenic' variant={'text-xl-regular'}>
          {title}
        </Text>
        <Text
          color='secondaryArsenic'
          sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
          variant={'text-m-regular'}
        >
          {subtitle}
        </Text>
      </FlexLayout>
    </FlexLayout>
  );
};

export default NoTableData;
