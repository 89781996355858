import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input, Label } from 'theme-ui';

import { RootState } from '../store';
import { PhotoSource } from '../common';
import { usePhotoEditor } from '../context';
import { useIsBlissWidget } from '../hooks';
import { Box, Button, colors, FlexLayout, radii, Text, useScreenType } from '../ui';
import { AnalyticsEventNames, trackAnalyticsEvent } from '../utils';

const ImagePicker = () => {
  const { isMobile } = useScreenType();

  const { selectedImage, onPhotoUpload, setPhotoSource } = usePhotoEditor();

  const widget = useSelector((state: RootState) => state.widget.widget);

  const { t } = useTranslation();

  const isBlissWidget = useIsBlissWidget();

  return (
    <FlexLayout
      alignItems='center'
      backgroundColor={colors.black}
      flexDirection='column'
      justifyContent='center'
      space={4}
      sx={{
        backgroundImage: selectedImage as unknown as string,
        borderRadius: radii.xxxl,
        height: isMobile() ? 'photo-frame-mobile-height' : 'photo-frame-height',
        hright: isMobile() ? 'photo-frame-mobile-height' : 'photo-frame-height',
        mb: !selectedImage && 6,
        width: isMobile() ? 'photo-frame-mobile-width' : 'photo-frame-width',
      }}
    >
      {isMobile() ? (
        <>
          <Label
            px={7}
            py={3}
            sx={{
              backgroundColor: colors.white,
              borderRadius: radii.xxxxxxl,
              width: 'fit-content',
            }}
          >
            <Input
              id='file-input'
              name='ImageStyle'
              onChange={(e) => {
                onPhotoUpload(e);
              }}
              sx={{ display: 'none' }}
              type='file'
            />
            <Text color={colors.black} sx={{ cursor: 'pointer' }} variant='text-m-regular'>
              Take a photo
            </Text>
          </Label>
        </>
      ) : (
        <>
          <Box sx={{ width: '270px' }}>
            <Button
              iconLeft='camera'
              onClick={() => {
                if (widget) {
                  trackAnalyticsEvent(AnalyticsEventNames.PhotoSourceCamera, {
                    customerID: widget.user.uuid,
                    customerName: widget.user?.clinicName,
                    customerType: widget.user.type,
                  });
                }
                setPhotoSource(PhotoSource.WebCamera);
              }}
              size='l'
              text={t('photoEditor.webcamBtnText')}
              textVariant={isBlissWidget ? 'bliss-button' : undefined}
              variant={isBlissWidget ? 'blissWhite' : 'secondary'}
              width='100%'
            />
          </Box>
          <Label
            onClick={() => {
              if (widget) {
                trackAnalyticsEvent(AnalyticsEventNames.PhotoSourceUpload, {
                  customerID: widget.user.uuid,
                  customerName: widget.user?.clinicName,
                  customerType: widget.user.type,
                });
              }
            }}
            sx={{
              width: isBlissWidget ? '270px' : 'fit-content',
            }}
          >
            <Input
              id='file-input'
              name='ImageStyle'
              onChange={(e) => onPhotoUpload(e)}
              sx={{ display: 'none' }}
              type='file'
            />
            {isBlissWidget ? (
              <Button
                as='span'
                size='l'
                text={t('photoEditor.uploadPhotoBtnText')}
                textVariant='bliss-button'
                variant='blissWhite'
                width='100%'
              />
            ) : (
              <Text color={colors.white} sx={{ cursor: 'pointer' }} variant='text-m-regular'>
                {t('photoEditor.uploadPhotoBtnText')}
              </Text>
            )}
          </Label>
        </>
      )}
      <Text
        color='white'
        sx={{ alignSelf: 'end', p: 4, textAlign: 'center' }}
        variant={isBlissWidget ? 'bliss-text' : 'text-m-regular'}
      >
        {t('photoEditor.capturePhotoHint')}
      </Text>
    </FlexLayout>
  );
};

export default ImagePicker;
