import React from 'react';

import { FlexLayout, Icon, Text } from '..';
import { TextIconProps } from '../../props';

const TextIcon = React.forwardRef<HTMLDivElement, TextIconProps>(
  (
    {
      as = 'div',
      text,
      icon,
      iconSize,
      textVariant,
      alignItems = 'center',
      flexDirection = 'row',
      sx,
      space,
      iconColor,
      ...rest
    },
    ref,
  ) => {
    return (
      <FlexLayout
        alignItems={alignItems}
        as={as}
        flexDirection={flexDirection}
        ref={ref}
        space={space}
        sx={sx}
        {...rest}
      >
        {icon && <Icon color={iconColor} icon={icon} size={iconSize} />}
        <Text variant={textVariant}>{text}</Text>
      </FlexLayout>
    );
  },
);
export default TextIcon;
