import React from 'react';
import { FlexLayout, Text } from '../ui/components';
import { AppointmentData } from '../apis';
import format from 'date-fns/format';

import { formatToUppercaseFirstLetter } from '../helpers';
import { Procedures, VisitType } from '../common';
import { TextWithLabel } from '../ui';

export interface AppointmentCardProps {
  appointment: AppointmentData;
}

const AppointmentCard: React.FC<AppointmentCardProps> = ({ appointment }) => {
  return (
    <FlexLayout
      alignItems={'flex-start'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      space={4}
      sx={{ width: '100%' }}
    >
      <FlexLayout
        alignItems={'center'}
        justifyContent={'flex-start'}
        space={8}
        sx={{ width: '100%' }}
      >
        <TextWithLabel label='Customer Name'>
          <Text>{appointment?.name}</Text>
        </TextWithLabel>
        <TextWithLabel label='Email'>
          <Text>{appointment?.email}</Text>
        </TextWithLabel>
      </FlexLayout>
      <FlexLayout alignItems={'center'} justifyContent={'flex-start'} space={8}>
        <TextWithLabel label='Created On'>
          <Text>{format(new Date(appointment?.createdAt!), 'dd MMMM yyyy')}</Text>
        </TextWithLabel>
        <TextWithLabel label='Phone'>
          <Text>{appointment?.phone}</Text>
        </TextWithLabel>
        <TextWithLabel label='Product Used'>
          <Text>{formatToUppercaseFirstLetter(appointment?.simulationType as Procedures)}</Text>
        </TextWithLabel>
        <TextWithLabel label='Appointment Place'>
          <Text>
            {formatToUppercaseFirstLetter(
              appointment?.visitType === VisitType.IN_PERSON ? 'IN PERSON' : 'VIDEO',
            )}
          </Text>
        </TextWithLabel>
      </FlexLayout>
      <FlexLayout alignItems={'center'} justifyContent={'flex-start'} space={8}>
        <TextWithLabel label='Customer Details' sx={{ maxWidth: '800px' }}>
          <Text>{appointment?.details}</Text>
        </TextWithLabel>
      </FlexLayout>
    </FlexLayout>
  );
};

export default AppointmentCard;
