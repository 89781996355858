import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { useIsBlissWidget } from '../../../hooks/bliss';
import { colors, radii } from '../../theme';
import { Box, FlexLayout, Text } from '..';
import { SliderProps } from '../../props';

export const sliderSizesMap = {
  xs: { height: '16px' },
  s: { height: '26px' },
  m: {
    height: '40px',
  },
  l: {
    height: '52px',
  },
  xl: {
    height: '64px',
  },
  xxl: {
    height: '90px',
  },
  max: {
    height: '300px',
  },
};

const Slider = React.forwardRef<HTMLInputElement, SliderProps>(
  (
    {
      scale,
      onChangeScale,
      size = 'm',
      sx = {},
      min,
      max,
      step,
      inputClassName,
      labels,
      showOrigin,
    },
    ref,
  ) => {
    const { height } = sliderSizesMap[size];
    const theme = useSelector((state: RootState) => state?.widget?.widget?.user?.theme);

    const isBlissWidget = useIsBlissWidget();

    return (
      <FlexLayout
        alignItems='center'
        justifyContent='center'
        p={2}
        sx={{
          background: isBlissWidget
            ? colors.blissOffWhite
            : theme?.foregroundColor || colors.irisBlue,
          borderRadius: radii.xxxxxxl,
          height: height,
          ...sx,
        }}
      >
        <div style={{ position: 'relative', width: '100%' }}>
          <input
            className={inputClassName}
            max={max}
            min={min}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onChangeScale(Number(event.target.value));
            }}
            ref={ref}
            step={step}
            type='range'
            value={scale}
          />

          {showOrigin && (
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '2px',
                bottom: 0,
                transform: 'translateX(-50%)',
                height: '24px',
                width: '2px',
                bg: 'black',
                zIndex: '0',
              }}
            />
          )}

          {labels?.map((label, index) => (
            <Text
              key={index}
              variant='text-m-medium'
              sx={{
                left: `${(index / (labels.length - 1)) * 100}%`,
                position: 'absolute',
                transform: 'translateX(-50%)',
                textAlign: 'center',
                whiteSpace: 'pre',
                top: '115%',
                display: 'flex',
                alignItems: 'center',
                height: '40px',
              }}
            >
              {label}
            </Text>
          ))}
        </div>
      </FlexLayout>
    );
  },
);

export default Slider;
