import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Input, Label, Textarea } from 'theme-ui';

import { AppointmentData, createAppointment } from '../../apis';
import { RootState } from '../../store';
import { ReactComponent as KiteWithLace } from '../../ui/assets/images/kite-with-lace.svg';
import {
  Button,
  Box,
  Checkbox,
  colors,
  FlexLayout,
  Icon,
  radii,
  Text,
  useScreenType,
} from '../../ui';
import { Procedures, VisitType } from '../../common';
import { usePhotoEditor } from '../../context';
import { AnalyticsEventNames, base64ToBlob, trackAnalyticsEvent } from '../../utils';

interface Props {
  onClose: () => void;
  onReturnToWeb: () => void;
  onTryAgain: () => void;
}
const AppointmentModalContent = (props: Props) => {
  const { onClose, onTryAgain, onReturnToWeb } = props;
  const { isMobile } = useScreenType();
  const theme = useSelector((state: RootState) => state.widget.widget.user.theme);
  const widget = useSelector((state: RootState) => state.widget.widget);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{
    name: boolean;
    phone: boolean;
    email: boolean;
  }>({ email: false, name: false, phone: false });
  const [appointment, setAppointment] = useState<AppointmentData>({
    details: '',
    email: '',
    isSubscribed: false,
    name: '',
    phone: '',
    shareSimulation: true,
    visitType: VisitType.VIDEO,
  });
  const { widgetId } = useParams();
  const [sendingError, setSendingError] = useState('');

  const { croppedImage, resultImage, imageMimeType, procedure } = usePhotoEditor();

  const { t } = useTranslation();

  const { name, email, phone, details, shareSimulation } = appointment;

  const fillForm = (property: {
    name?: string;
    email?: string;
    phone?: string;
    details?: string;
    visitType?: VisitType;
    shareSimulation?: boolean;
    isSubscribed?: boolean;
    simulationType?: Procedures;
  }) => {
    setAppointment({ ...appointment, ...property });
  };

  const onSend = () => {
    const formData = new FormData();
    setIsLoading(true);

    formData.append('name', appointment.name);
    formData.append('email', appointment.email);
    formData.append('phone', appointment.phone || '');
    formData.append('details', appointment.details || '');
    formData.append('type', VisitType.IN_PERSON);
    formData.append('isSubscribed', appointment.isSubscribed ? 'true' : '');
    formData.append('shareSimulation', appointment.shareSimulation ? 'true' : '');
    formData.append('widget', widgetId || '');
    formData.append('simulationType', procedure);

    if (appointment.shareSimulation) {
      const before = base64ToBlob(croppedImage!, imageMimeType);
      const after = base64ToBlob(resultImage!, imageMimeType);

      formData.append('before', before);
      formData.append('after', after);
    }

    setSendingError('');

    createAppointment(formData)
      .then((r: AxiosResponse) => {
        if (r.status === 201) {
          setIsMessageSent(true);
          setIsLoading(false);

          if (Object.keys(widget).length > 0) {
            trackAnalyticsEvent(AnalyticsEventNames.ClinicContacted, {
              customerID: widget.user.uuid,
              customerName: widget.user?.clinicName,
              customerType: widget.user.type,
              simulationName: procedure,
            });
          }
        }

        throw new Error(r.data.message);
      })
      .catch((e) => {
        setIsLoading(false);
        setSendingError(e.message);
      });
  };

  return (
    <FlexLayout
      flexDirection='column'
      p={[5, 6]}
      space={6}
      sx={{
        background: theme.backgroundColor,
        borderTopLeftRadius: isMobile() && radii.xxl,
        borderTopRightRadius: isMobile() && radii.xxl,
        height: isMobile() ? '95vh' : '100vh',
        overflow: 'scroll',
        width: isMobile() ? '100vw' : 'terms-modal-width',
      }}
    >
      <FlexLayout justifyContent='flex-end'>
        <Box onClick={onClose}>
          <Icon icon='close' size='xl' />
        </Box>
      </FlexLayout>
      {isMessageSent ? (
        <FlexLayout flexDirection='column'>
          <KiteWithLace
            style={{
              background: 'transparent',
              height: '100px',
              width: '170px',
            }}
          />
          <Text sx={{ marginTop: '40px' }} variant='text-xl-regular'>
            {t('appointmentModal.messageSentTitle')}
          </Text>
          <Text color={colors.black06} sx={{ marginTop: '16px' }} variant='text-m-regular'>
            {t('appointmentModal.messageSentMessage')}
          </Text>
          <FlexLayout flexWrap='wrap' mt={7} space={3}>
            <Button
              onClick={onReturnToWeb}
              size='l'
              text={t('appointmentModal.returnToWebBtnText')}
              variant='primary'
              width={isMobile() ? '100%' : 'fit-content'}
            />
            <Button
              onClick={onTryAgain}
              size='l'
              text={t('appointmentModal.tryMoreSmilesBtnText')}
              variant='secondary'
              width={isMobile() ? '100%' : 'fit-content'}
            />
          </FlexLayout>
        </FlexLayout>
      ) : (
        <>
          <FlexLayout flexDirection='column' mt={3} space={3}>
            <Text variant='text-xl-regular'>{t('appointmentModal.title')}</Text>
            <Text color={colors.black06} variant='text-m-regular'>
              {t('appointmentModal.message')}
            </Text>
          </FlexLayout>
          <FlexLayout flexDirection='column' space={4}>
            <FlexLayout flex={1} flexWrap='wrap' space={3}>
              <FlexLayout
                flexDirection='column'
                space={2}
                sx={{ width: isMobile() ? '100%' : '260px' }}
              >
                <Label>
                  <Text color={colors.black07} variant='text-xs-regular'>
                    {t('appointmentModal.nameLabel')}
                  </Text>
                </Label>
                <Input
                  disabled={isLoading}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    fillForm({ name: event.target.value });
                    if (event.target.value.trim() === '') {
                      setErrors({ ...errors, name: true });
                    } else {
                      setErrors({ ...errors, name: false });
                    }
                  }}
                  sx={{
                    background: theme.foregroundColor,
                    border: errors.name ? `2px solid ${colors.red}` : 'none',
                    borderRadius: radii.m,
                    height: '40px',
                  }}
                  value={name}
                />
              </FlexLayout>
              <FlexLayout
                flexDirection='column'
                space={2}
                sx={{ width: isMobile() ? '100%' : '260px' }}
              >
                <Label>
                  <Text color={colors.black07} variant='text-xs-regular'>
                    {t('appointmentModal.emailLabel')}
                  </Text>
                </Label>
                <Input
                  disabled={isLoading}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    fillForm({ email: event.target.value });
                    if (event.target.value.trim() === '') {
                      setErrors({ ...errors, email: true });
                    } else {
                      setErrors({ ...errors, email: false });
                    }
                  }}
                  sx={{
                    background: theme.foregroundColor,
                    border: errors.email ? `2px solid ${colors.red}` : 'none',
                    borderRadius: radii.m,
                    height: '40px',
                  }}
                  value={email}
                />
              </FlexLayout>
            </FlexLayout>
            <FlexLayout flex={1} flexWrap='wrap' space={3}>
              <FlexLayout
                flexDirection='column'
                space={2}
                sx={{ width: isMobile() ? '100%' : '260px' }}
              >
                <Label>
                  <Text color={colors.black07} variant='text-xs-regular'>
                    {t('appointmentModal.phoneLabel')}
                  </Text>
                </Label>
                <Input
                  disabled={isLoading}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    fillForm({ phone: event.target.value });
                    if (event.target.value.trim() === '') {
                      setErrors({ ...errors, phone: true });
                    } else {
                      setErrors({ ...errors, phone: false });
                    }
                  }}
                  sx={{
                    background: theme.foregroundColor,
                    border: 'none',
                    borderRadius: radii.m,
                    height: '40px',
                  }}
                  value={phone}
                />
              </FlexLayout>
              {/* <FlexLayout
                flexDirection='column'
                space={2}
                sx={{ width: isMobile() ? '100%' : '260px' }}
              >
                <Label>
                  <Text color={colors.black07} variant='text-xs-regular'>
                    {t('appointmentModal.visitTypeLabel')}
                  </Text>
                </Label>
                <FlexLayout space={4} sx={{ height: '40px' }}>
                  <FlexLayout alignItems='center' space={2}>
                    <Radio
                      checked={visitType === VisitType.VIDEO}
                      isDisabled={isLoading}
                      label={t('appointmentModal.virtualLabel')}
                      onCheck={() => {
                        fillForm({ visitType: VisitType.VIDEO });
                      }}
                    />

                    <Radio
                      checked={visitType === VisitType.IN_PERSON}
                      isDisabled={isLoading}
                      label={t('appointmentModal.inPersonLabel')}
                      onCheck={() => {
                        fillForm({ visitType: VisitType.IN_PERSON });
                      }}
                    />
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout> */}
            </FlexLayout>
            <FlexLayout flexDirection='column' space={2} sx={{ width: '100%' }}>
              <Label>
                <Text color={colors.black07} variant='text-xs-regular'>
                  {t('appointmentModal.detailsLabel')}
                </Text>
              </Label>
              <Textarea
                disabled={isLoading}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  fillForm({ details: event.target.value })
                }
                sx={{
                  background: theme.foregroundColor,
                  border: 'none',
                  borderRadius: radii.m,
                  font: 'caption',
                  height: '40px',
                  minHeight: '90px',
                }}
                value={details}
              />
            </FlexLayout>
            <Checkbox
              checked={!!shareSimulation}
              isDisabled={isLoading}
              label={t('appointmentModal.shareSimulationLabel')}
              onCheck={(value: boolean) => {
                fillForm({ shareSimulation: value });
              }}
            />
            {/* <Checkbox
              checked={!!isSubscribed}
              isDisabled={isLoading}
              label={t('appointmentModal.subscribeLabel')}
              onCheck={(value: boolean) => {
                fillForm({ isSubscribed: value });
              }}
            /> */}
          </FlexLayout>
          <FlexLayout flexDirection='column' mt={7} space={2}>
            <Button
              iconRight='right'
              isDisabled={
                name.trim() === '' || email.trim() === '' || phone.trim() === '' || isLoading
              }
              onClick={onSend}
              size='l'
              text={isLoading ? t('common.sending') : t('common.send')}
              variant='primary'
              width={isMobile() ? '100%' : 'fit-content'}
            />
            {sendingError && (
              <Text color='red' sx={{ textAlign: 'center' }} variant='text-m-regular'>
                {sendingError}
              </Text>
            )}
          </FlexLayout>
        </>
      )}
    </FlexLayout>
  );
};

export default AppointmentModalContent;
