import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../store';
import { useIsBlissWidget } from '../hooks';
import { colors, FlexLayout, Icon, radii, Slider, useScreenType } from '../ui';

interface Props {
  scale: number;
  onChangeScale: (scale: number) => void;
  buttons?: boolean;
}
const SliderContainer = (props: Props) => {
  const { scale, onChangeScale, buttons } = props;
  const { isMobile } = useScreenType();

  const isBlissWidget = useIsBlissWidget();

  const showScaleButtons = () => {
    if (isMobile() || !buttons) {
      return false;
    }
    return true;
  };

  const theme = useSelector((state: RootState) => state.widget.widget.user.theme);
  return (
    <FlexLayout
      alignItems='center'
      justifyContent='space-between'
      space={2}
      sx={{ width: isMobile() ? '100%' : '470px' }}
    >
      {showScaleButtons() && (
        <FlexLayout
          alignItems='center'
          justifyContent='center'
          onClick={() => {
            if (scale - 0.1 > 1) {
              onChangeScale(scale - 0.1);
            }
          }}
          sx={{
            background: isBlissWidget ? colors.blissOffWhite : theme.foregroundColor,
            borderRadius: radii.circle,
            cursor: 'pointer',
            height: 'slider-scale-size',
            width: 'slider-scale-size',
          }}
        >
          <Icon icon='minus' />
        </FlexLayout>
      )}
      <Slider
        inputClassName={isBlissWidget ? 'yss-bliss-slider' : ''}
        max={5}
        min={1}
        onChangeScale={(value) => onChangeScale(value)}
        scale={scale}
        step={0.1}
        sx={{ width: isMobile() ? '100%' : '370px' }}
      />
      {showScaleButtons() && (
        <FlexLayout
          alignItems='center'
          justifyContent='center'
          onClick={() => {
            if (scale + 0.1 <= 5) {
              onChangeScale(scale + 0.1);
            }
          }}
          sx={{
            background: isBlissWidget ? colors.blissOffWhite : theme.foregroundColor,
            borderRadius: radii.circle,
            cursor: 'pointer',
            height: 'slider-scale-size',
            width: 'slider-scale-size',
          }}
        >
          <Icon icon='plus' />
        </FlexLayout>
      )}
    </FlexLayout>
  );
};
export default SliderContainer;
