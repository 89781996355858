export const base64ToBlob = (base64: string, mimeType = 'image/jpeg') => {
  const byteString = atob(base64.split(',')[1]);

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeType });
};

export const resizeImage = async ({
  maxWidth,
  maxHeight,
  file,
}: {
  maxWidth: number;
  maxHeight: number;
  file: File;
}): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = function () {
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const aspectRatio = width / height;

          if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
          }

          if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
          }

          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = width;
          canvas.height = height;

          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject('Failed to resize image.');
                return;
              }

              resolve(blob);
              canvas.remove();
              img.remove();
            },
            file.type,
            1,
          );
        }
      };
    };

    reader.readAsDataURL(file);
  });
};
